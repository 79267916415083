define("ui/components/cluster/cis/scan/detail/table-row/component", ["exports", "ui/components/cluster/cis/scan/detail/table-row/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',
    expanded: false,
    hasExpandableContent: Ember.computed.notEmpty('model.nodes'),
    actions: {
      toggle() {
        Ember.set(this, 'expanded', !Ember.get(this, 'expanded'));
      },

      toggleSkip() {
        Ember.get(this, 'model.toggleSkip')();
      }

    },
    isInSkipList: Ember.computed('model.id', 'model.skipList.[]', function () {
      return Ember.get(this, 'model.skipList').indexOf(Ember.get(this, 'model.id')) !== -1;
    }),
    showSkipButton: Ember.computed('model.state', 'isInSkipList', function () {
      return Ember.get(this, 'model.state') !== 'Pass' && Ember.get(this, 'model.state') !== 'N/A' && !Ember.get(this, 'isInSkipList');
    }),
    showUnskipButton: Ember.computed('model.state', 'isInSkipList', function () {
      return Ember.get(this, 'model.state') !== 'Pass' && Ember.get(this, 'isInSkipList');
    }),
    badgeState: Ember.computed('model.state', function () {
      const state = Ember.get(this, 'model.state');
      return {
        stateBackground: this.getStateBackground(state),
        displayState: state
      };
    }),

    getStateBackground(state) {
      switch (state) {
        case 'Pass':
          return 'bg-success';

        case 'Skipped':
          return 'bg-warning';

        case 'N/A':
          return 'bg-warning';

        default:
          return 'bg-error';
      }
    }

  });

  _exports.default = _default;
});