define("ui/components/container/form-image/component", ["exports", "ui/components/container/form-image/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const LINUX_LAST_CONTAINER = 'ubuntu:xenial';
  const WINDOWS_LAST_CONTAINER = 'mcr.microsoft.com/dotnet/core/samples:aspnetapp'; // Remember the last value and use that for new one

  var lastContainer;

  var _default = Ember.Component.extend({
    scope: Ember.inject.service(),
    layout: _template.default,
    // Inputs
    initialValue: null,
    errors: null,
    userInput: null,
    tagName: '',
    value: null,
    allPods: null,

    init() {
      this._super(...arguments);

      Ember.set(this, 'allPods', Ember.get(this, 'store').all('pod'));
      let initial = Ember.get(this, 'initialValue') || '';

      if (!lastContainer) {
        lastContainer = Ember.get(this, 'scope.currentCluster.isWindows') ? WINDOWS_LAST_CONTAINER : LINUX_LAST_CONTAINER;
      }

      if (!initial) {
        initial = lastContainer;
      }

      Ember.run.scheduleOnce('afterRender', this, 'setupComponent', initial);
    },

    actions: {
      setInput(str) {
        Ember.set(this, 'userInput', str);
      }

    },
    userInputDidChange: Ember.observer('userInput', function () {
      var input = (Ember.get(this, 'userInput') || '').trim();
      var out;

      if (input && input.length) {
        lastContainer = input;
        out = input;
      } else {
        out = null;
      }

      Ember.set(this, 'value', out);

      if (this.changed) {
        this.changed(out);
      }

      this.validate();
    }),
    suggestions: Ember.computed('allPods.@each.containers', function () {
      let inUse = [];
      Ember.get(this, 'allPods').forEach(pod => {
        inUse.addObjects(pod.get('containers') || []);
      });
      inUse = inUse.map(obj => obj.get('image') || '').filter(str => !str.includes('sha256:') && !str.startsWith('rancher/')).uniq().sort();
      return {
        'Used by other containers': inUse
      };
    }),

    validate() {
      var errors = [];

      if (!Ember.get(this, 'value')) {
        errors.push('Image is required');
      }

      Ember.set(this, 'errors', errors);
    },

    setupComponent(initial) {
      this.send('setInput', initial);
      this.userInputDidChange();
    }

  });

  _exports.default = _default;
});