define("ui/components/container/form-custom-metrics/component", ["exports", "ui/components/container/form-custom-metrics/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const HTTPS = 'HTTPS';
  const HTTP = 'HTTP';
  const OPTIONS = [{
    label: HTTP,
    value: HTTP
  }, {
    label: HTTPS,
    value: HTTPS
  }];

  var _default = Ember.Component.extend({
    scope: Ember.inject.service(),
    layout: _template.default,
    editing: false,
    protocolOptions: OPTIONS,

    init() {
      this._super(...arguments);

      Ember.set(this, 'metrics', Ember.get(this, 'workload.workloadMetrics') || []);
    },

    actions: {
      add() {
        Ember.get(this, 'metrics').pushObject({
          path: '',
          port: '',
          schema: HTTP
        });
      },

      remove(obj) {
        Ember.get(this, 'metrics').removeObject(obj);
      }

    },
    metricsChanged: Ember.observer('metrics.@each.{port,path,schema}', function () {
      Ember.set(this, 'workload.workloadMetrics', Ember.get(this, 'metrics').filter(metric => Ember.get(metric, 'port')));
    })
  });

  _exports.default = _default;
});