define("ui/authenticated/controller", ["exports", "ui/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    settings: Ember.inject.service(),
    prefs: Ember.inject.service(),
    scope: Ember.inject.service(),
    intl: Ember.inject.service(),
    application: Ember.inject.controller(),
    error: null,
    azureAd: null,
    isPopup: Ember.computed.alias('application.isPopup'),
    pageScope: Ember.computed.alias('scope.currentPageScope'),
    hasHosts: Ember.computed.gt('model.hosts.length', 0),

    init() {
      this._super(...arguments);

      Ember.get(this, 'globalStore').find('authconfig', 'azuread', {
        forceReload: true
      }).then(config => {
        this.set('azureAd', config);
      });
    },

    azureNeedsUpdate: Ember.computed('azureAd.enabled', 'azureAd.annotations', function () {
      const azureAd = Ember.get(this, 'azureAd');

      if (!azureAd) {
        return false;
      }

      const isEnabled = Ember.get(azureAd, 'enabled');
      return isEnabled && (Ember.get(azureAd, 'annotations') || {})[_constants.default.AZURE_AD.ANNOTATION_MIGRATED] !== 'true';
    }),
    bootstrap: Ember.on('init', function () {
      Ember.run.schedule('afterRender', this, () => {
        this.get('application').setProperties({
          error: null,
          error_description: null,
          state: null
        });
        let bg = this.get(`prefs.${_constants.default.PREFS.BODY_BACKGROUND}`);

        if (bg) {
          $('BODY').css('background', bg); // eslint-disable-line
        }
      });
    })
  });

  _exports.default = _default;
});