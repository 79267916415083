define("ui/components/cru-volume/component", ["exports", "shared/mixins/view-new-edit", "ui/components/cru-volume/template", "ui/models/volume", "ui/utils/constants"], function (_exports, _viewNewEdit, _template, _volume, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_viewNewEdit.default, {
    intl: Ember.inject.service(),
    features: Ember.inject.service(),
    layout: _template.default,
    model: null,
    sourceName: null,
    titleKey: 'cruVolume.title',

    didReceiveAttrs() {
      const selectedSource = (Ember.get(this, 'sourceChoices') || []).find(source => !!Ember.get(this, `primaryResource.${Ember.get(source, 'value')}`));

      if (selectedSource) {
        Ember.set(this, 'sourceName', Ember.get(selectedSource, 'name'));
      }
    },

    actions: {
      updateParams(key, map) {
        (0, _volume.getSources)('ephemeral').forEach(source => {
          if (source.value === key) {
            Ember.set(this, `primaryResource.${key}`, map);
          } else {
            Ember.set(this, `primaryResource.${source.value}`, null);
          }
        });
      }

    },
    headerToken: Ember.computed('mode', 'scope', function () {
      let k = 'cruPersistentVolumeClaim.define.';
      k += Ember.get(this, 'mode');
      return k;
    }),
    sourceChoices: Ember.computed('intl.locale', function () {
      const intl = Ember.get(this, 'intl');
      const skip = ['host-path', 'secret'];
      const out = (0, _volume.getSources)('ephemeral').map(p => {
        const entry = Object.assign({}, p);
        const key = `volumeSource.${entry.name}.title`;

        if (skip.includes(entry.name)) {
          entry.priority = 0;
        } else if (intl.exists(key)) {
          entry.label = intl.t(key);

          if (p.persistent) {
            entry.priority = 2;
          } else {
            entry.priority = 1;
          }
        } else {
          entry.label = entry.name;
          entry.priority = 3;
        }

        return entry;
      });
      return out.filter(x => x.priority > 0).sortBy('priority', 'label');
    }),
    supportedSourceChoices: Ember.computed('sourceChoices', function () {
      const showUnsupported = Ember.get(this, 'features').isFeatureEnabled(_constants.default.FEATURES.UNSUPPORTED_STORAGE_DRIVERS);
      return Ember.get(this, 'sourceChoices').filter(choice => showUnsupported || choice.supported);
    }),
    sourceComponent: Ember.computed('sourceName', function () {
      const name = Ember.get(this, 'sourceName');
      const sources = (0, _volume.getSources)('ephemeral');
      const entry = sources.findBy('name', name);

      if (entry) {
        return {
          component: `volume-source/source-${name}`,
          field: entry.value
        };
      }

      return null;
    }),

    willSave() {
      const vol = Ember.get(this, 'primaryResource');
      const entry = (0, _volume.getSources)('ephemeral').findBy('name', Ember.get(this, 'sourceName'));

      if (!entry) {
        const errors = [];
        const intl = Ember.get(this, 'intl');
        errors.push(intl.t('validation.required', {
          key: intl.t('cruVolume.source.label')
        }));
        Ember.set(this, 'errors', errors);
        return false;
      }

      vol.clearSourcesExcept(entry.value);

      let ok = this._super(...arguments);

      if (ok) {
        const out = Ember.Object.create({
          name: Ember.get(vol, 'name')
        });
        Ember.set(out, entry.value, Ember.get(vol, entry.value));

        if (this.doSave) {
          this.doSave({
            volume: out
          });
        }

        if (this.done) {
          this.done();
        }
      }

      return false;
    }

  });

  _exports.default = _default;
});