define("ui/models/nodetemplate", ["exports", "@rancher/ember-api-store/models/resource", "@rancher/ember-api-store/utils/denormalize", "shared/utils/util", "shared/mixins/node-driver"], function (_exports, _resource, _denormalize, _util, _nodeDriver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resource.default.extend({
    intl: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    globalStore: Ember.inject.service(),
    type: 'nodeTemplate',
    canClone: true,
    dynamicComputedKeyName: null,
    creator: (0, _denormalize.reference)('creatorId', 'user', 'globalStore'),

    init() {
      this._super(...arguments);

      const {
        driver
      } = this;

      if (driver) {
        this.initDisplayLocation(driver);
        this.initDisplaySize(driver);
      }
    },

    config: Ember.computed('driver', function () {
      const driver = Ember.get(this, 'driver');
      return Ember.get(this, `${driver}Config`);
    }),
    displayProvider: Ember.computed('driver', 'intl.locale', function () {
      const intl = Ember.get(this, 'intl');
      const driver = Ember.get(this, 'driver');
      const key = `nodeDriver.displayName.${driver}`;

      if (intl.exists(key)) {
        return intl.t(key);
      } else {
        return (0, _util.ucFirst)(driver);
      }
    }),

    cloneForNew() {
      const copy = this._super();

      delete copy.annotations;
      return copy;
    },

    actions: {
      edit() {
        let driver = Ember.get(this, 'driver');
        Ember.get(this, 'modalService').toggleModal('modal-edit-node-template', {
          driver,
          config: Ember.get(this, `${driver}Config`),
          nodeTemplate: this,
          edit: true
        });
      },

      clone() {
        const {
          driver
        } = this;
        Ember.get(this, 'modalService').toggleModal('modal-edit-node-template', {
          driver,
          config: Ember.get(this, `${driver}Config`),
          nodeTemplate: this,
          clone: true
        });
      }

    },

    _displayVar(keyOrFn) {
      const intl = Ember.get(this, 'intl');

      if (keyOrFn) {
        if (typeof keyOrFn === 'function') {
          return keyOrFn.call(this);
        } else {
          return Ember.get(this, keyOrFn) || intl.t('generic.unknown');
        }
      } else {
        return intl.t('generic.unknown');
      }
    },

    clearConfigsExcept(keep) {
      const keys = this.allKeys().filter(x => x.endsWith('Config'));

      for (let key, i = 0; i < keys.length; i++) {
        key = keys[i];

        if (key !== keep && Ember.get(this, key)) {
          Ember.set(this, key, null);
        }
      }
    },

    initDisplayLocation(driver) {
      let location = (0, _nodeDriver.getDisplayLocation)(driver);
      let computedKeys = null;

      if (location && location.keyOrKeysToWatch) {
        computedKeys = Ember.isArray(location.keyOrKeysToWatch) ? location.keyOrKeysToWatch : [location.keyOrKeysToWatch];
        this.registerDynamicComputedProperty('displayLocation', computedKeys, location.getDisplayProperty);
      } else {
        Ember.set(this, 'displayLocation', Ember.get(this, 'config.region') || 'N/A');
      }
    },

    initDisplaySize(driver) {
      let size = (0, _nodeDriver.getDisplaySize)(driver);
      let computedKeys = null;

      if (size && size.keyOrKeysToWatch) {
        computedKeys = Ember.isArray(size.keyOrKeysToWatch) ? size.keyOrKeysToWatch : [size.keyOrKeysToWatch];
        this.registerDynamicComputedProperty('displaySize', computedKeys, size.getDisplayProperty);
      } else {
        Ember.set(this, 'displaySize', Ember.get(this, 'config.size') || 'N/A');
      }
    },

    registerDynamicComputedProperty(propertyName, watchedKeys, key) {
      Ember.defineProperty(this, propertyName, Ember.computed(...watchedKeys, function () {
        return this._displayVar(key);
      }));
    }

  });

  _exports.default = _default;
});