define("ui/models/project", ["exports", "@rancher/ember-api-store/utils/denormalize", "@rancher/ember-api-store/models/resource", "ui/utils/constants"], function (_exports, _denormalize, _resource, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SYSTEM_PROJECT_LABEL = 'authz.management.cattle.io/system-project';

  var _default = _resource.default.extend({
    access: Ember.inject.service(),
    prefs: Ember.inject.service(),
    scope: Ember.inject.service(),
    settings: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    router: Ember.inject.service(),
    clusterStore: Ember.inject.service(),
    projectRoleTemplateBindings: (0, _denormalize.hasMany)('id', 'projectRoleTemplateBinding', 'projectId'),
    apps: (0, _denormalize.hasMany)('id', 'apps', 'projectId'),
    namespaces: (0, _denormalize.hasMany)('id', 'namespace', 'projectId', 'clusterStore'),
    type: 'project',
    name: null,
    description: null,
    isMonitoringReady: false,
    cluster: (0, _denormalize.reference)('clusterId', 'cluster'),
    // 2.0 bug projectId is wrong in the ptrb should be <cluster-id>:<project-id> instead of just <project-id>
    roleTemplateBindings: Ember.computed.alias('projectRoleTemplateBindings'),
    icon: Ember.computed('active', function () {
      if (Ember.get(this, 'active')) {
        return 'icon icon-folder-open';
      } else {
        return 'icon icon-folder text-muted';
      }
    }),
    isDefault: Ember.computed(`prefs.${_constants.default.PREFS.PROJECT_DEFAULT}`, 'id', function () {
      return Ember.get(this, `prefs.${_constants.default.PREFS.PROJECT_DEFAULT}`) === Ember.get(this, 'id');
    }),
    isSystemProject: Ember.computed('labels', function () {
      const labels = Ember.get(this, 'labels') || {};
      return labels[SYSTEM_PROJECT_LABEL] === 'true';
    }),
    conditionsDidChange: Ember.on('init', Ember.observer('enableProjectMonitoring', 'conditions.@each.status', function () {
      if (!Ember.get(this, 'enableProjectMonitoring')) {
        return false;
      }

      const conditions = Ember.get(this, 'conditions') || [];
      const ready = conditions.findBy('type', 'MonitoringEnabled');
      const status = ready && Ember.get(ready, 'status') === 'True';

      if (status !== Ember.get(this, 'isMonitoringReady')) {
        Ember.set(this, 'isMonitoringReady', status);
      }
    })),
    active: Ember.computed('scope.currentProject.id', 'id', function () {
      return Ember.get(this, 'scope.currentProject.id') === Ember.get(this, 'id');
    }),
    canSaveMonitor: Ember.computed('actionLinks.{editMonitoring,enableMonitoring}', 'enableProjectMonitoring', 'isSystemProject', function () {
      if (Ember.get(this, 'isSystemProject')) {
        return false;
      }

      const action = Ember.get(this, 'enableProjectMonitoring') ? 'editMonitoring' : 'enableMonitoring';
      return !!this.hasAction(action);
    }),
    canDisableMonitor: Ember.computed('actionLinks.disableMonitoring', function () {
      return !!this.hasAction('disableMonitoring');
    }),
    canSetDefault: Ember.computed('combinedState', 'isDefault', function () {
      return Ember.get(this, 'combinedState') === 'active' && !Ember.get(this, 'isDefault');
    }),
    isReady: Ember.computed('relevantState', 'cluster.isReady', function () {
      return Ember.get(this, 'relevantState') === 'active' && Ember.get(this, 'cluster.isReady');
    }),
    actions: {
      edit() {
        Ember.get(this, 'router').transitionTo('authenticated.cluster.projects.edit', Ember.get(this, 'id'));
      },

      activate() {
        return this.doAction('activate');
      },

      deactivate() {
        return this.doAction('deactivate').then(() => {
          if (Ember.get(this, 'scope.currentProject') === this) {
            window.location.href = window.location.href; // eslint-disable-line no-self-assign
          }
        });
      },

      setAsDefault() {
        Ember.set(Ember.get(this, 'prefs'), _constants.default.PREFS.PROJECT_DEFAULT, Ember.get(this, 'id'));
      },

      promptStop() {
        Ember.get(this, 'modalService').toggleModal('modal-confirm-deactivate', {
          originalModel: this,
          action: 'deactivate'
        });
      }

    },

    delete()
    /* arguments*/
    {
      var promise = this._super.apply(this, arguments);

      return promise.then(() => {
        if (Ember.get(this, 'active')) {
          window.location.href = window.location.href; // eslint-disable-line no-self-assign
        }
      }).catch(err => {
        Ember.get(this, 'growl').fromError('Error deleting', err);
      });
    }

  });

  _exports.default = _default;
});