define("ui/authenticated/cluster/nodes/index/controller", ["exports", "ui/components/node-row/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    growl: Ember.inject.service(),
    prefs: Ember.inject.service(),
    scope: Ember.inject.service(),
    capabilities: Ember.inject.service(),
    queryParams: ['sortBy'],
    sortBy: 'name',
    searchText: '',
    headers: _component.headersCluster,
    extraSearchFields: ['displayUserLabelStrings', 'requireAnyLabelStrings'],
    actions: {
      scaleDownPool(id) {
        Ember.get(this, 'model.cluster').send('scaleDownPool', id);
      },

      scaleUpPool(id) {
        Ember.get(this, 'model.cluster').send('scaleUpPool', id);
      },

      editCluster() {
        Ember.get(this, 'model.cluster').send('edit');
      }

    },
    groupByKey: Ember.computed('model.cluster.nodePools.length', function () {
      if (Ember.get(this, 'model.cluster.nodePools.length')) {
        return 'nodePoolId';
      }

      return null;
    }),
    rows: Ember.computed('model.cluster.id', 'model.nodes.@each.clusterId', function () {
      return Ember.get(this, 'model.nodes').filterBy('clusterId', Ember.get(this, 'model.cluster.id'));
    })
  });

  _exports.default = _default;
});