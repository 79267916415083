define("ui/authenticated/project/security/members/new/controller", ["exports", "ui/mixins/new-or-edit"], function (_exports, _newOrEdit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ROLE_KINDS = [{
    id: 'User'
  }, {
    id: 'Group'
  } // {
  //   id: 'Service Account',
  // },
  // {
  //   id: 'Principle',
  // },
  ];

  var _default = Ember.Controller.extend(_newOrEdit.default, {
    primaryResource: null,
    kinds: ROLE_KINDS,
    actions: {
      doneSaving() {
        this.transitionToRoute('authenticated.project.security.members.index', Ember.get(this, 'model.project.id'));
      }

    }
  });

  _exports.default = _default;
});