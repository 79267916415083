define("ui/authenticated/cluster/security/members/index/route", ["exports", "ui/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),

    model() {
      return this.modelFor('authenticated.cluster');
    },

    setDefaultRoute: Ember.on('activate', function () {
      Ember.set(this, `session.${_constants.default.SESSION.CLUSTER_ROUTE}`, 'authenticated.cluster.security.members.index');
    })
  });

  _exports.default = _default;
});