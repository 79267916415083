define("ui/authenticated/project/dns/detail/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      const store = Ember.get(this, 'store');
      return Ember.RSVP.hash({
        dnsRecords: store.findAll('service'),
        workloads: store.findAll('workload'),
        record: store.find('service', params.record_id)
      });
    }

  });

  _exports.default = _default;
});