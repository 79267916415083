define("ui/models/catalog", ["exports", "@rancher/ember-api-store/models/resource", "shared/utils/util", "ui/utils/constants"], function (_exports, _resource, _util, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    HELM_VERSION_2: helmV2,
    HELM_VERSION_3: helmV3,
    HELM_VERSION_3_SHORT: helmV3Short,
    HELM_3_LIBRARY_VALUE: helm3LibraryId
  } = _constants.default.CATALOG;

  const Catalog = _resource.default.extend({
    modalService: Ember.inject.service('modal'),
    level: 'global',
    isHelm3: Ember.computed('helmVersion', function () {
      const {
        helmVersion = helmV2
      } = this;

      if (helmVersion === helmV3 || helmVersion === helmV3Short) {
        return true;
      }

      return false;
    }),
    displayKind: Ember.computed('kind', function () {
      return (0, _util.ucFirst)(Ember.get(this, 'kind'));
    }),
    combinedState: Ember.computed('id', function () {
      if (!Ember.get(this, 'id')) {
        return 'disabled';
      }

      return '';
    }),
    canClone: Ember.computed('actions.clone', 'name', function () {
      const name = Ember.get(this, 'name');
      const catalogNames = Ember.get(_constants.default, 'CATALOG');
      const builtIn = [Ember.get(catalogNames, 'ALIBABA_APP_HUB_KEY'), Ember.get(catalogNames, 'HELM_3_LIBRARY_KEY'), Ember.get(catalogNames, 'HELM_INCUBATOR_KEY'), Ember.get(catalogNames, 'HELM_STABLE_KEY'), Ember.get(catalogNames, 'LIBRARY_KEY'), Ember.get(catalogNames, 'SYSTEM_LIBRARY_KEY')];
      return !builtIn.includes(name);
    }),
    availableActions: Ember.computed('actionLinks.refresh', 'id', function () {
      let a = Ember.get(this, 'actionLinks') || {};
      return [{
        action: 'enable',
        icon: 'icon icon-plus-circle',
        enabled: !this.id,
        label: 'generic.enable'
      }, {
        enabled: !!a.refresh,
        label: 'catalogPage.index.refreshBtn',
        icon: 'icon icon-refresh',
        action: 'refresh'
      }];
    }),
    actions: {
      enable() {
        if (Ember.isEmpty(this.id) && !Ember.isEmpty(this.url) && this.url === helm3LibraryId) {
          Ember.set(this, 'helmVersion', helmV3);
        }

        this.save();
      },

      edit() {
        Ember.get(this, 'modalService').toggleModal('modal-edit-catalog', {
          model: this,
          scope: Ember.get(this, 'level')
        });
      },

      clone() {
        const clone = this.cloneForNew();
        Ember.get(this, 'modalService').toggleModal('modal-edit-catalog', {
          model: clone,
          scope: Ember.get(this, 'level')
        });
      },

      refresh() {
        this.doAction('refresh');
      }

    }
  });

  Catalog.reopenClass({
    stateMap: {
      'disabled': {
        icon: 'icon icon-alert',
        color: 'text-muted'
      }
    }
  });
  var _default = Catalog;
  _exports.default = _default;
});