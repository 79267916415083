define("ui/authenticated/cluster/storage/persistent-volumes/new/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model()
    /* params, transition*/
    {
      return this.get('clusterStore').createRecord({
        type: 'persistentVolume'
      });
    }

  });

  _exports.default = _default;
});