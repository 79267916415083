define("ui/catalog-tab/launch/route", ["exports", "shared/utils/util", "ui/utils/constants", "ui/utils/util"], function (_exports, _util, _constants, _util2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    modalService: Ember.inject.service('modal'),
    catalog: Ember.inject.service(),
    scope: Ember.inject.service(),
    clusterStore: Ember.inject.service(),
    settings: Ember.inject.service(),
    growl: Ember.inject.service(),
    intl: Ember.inject.service(),
    parentRoute: 'catalog-tab',

    model(params, transition) {
      const {
        store,
        clusterStore
      } = this;
      let dependencies = {
        namespaces: clusterStore.findAll('namespace')
      };

      if (params.appId) {
        dependencies.app = store.find('app', params.appId);
        dependencies.app.then(appData => {
          const getCurrentVersion = app => {
            const externalId = app.externalId;
            const splitId = externalId.split('version=');
            const currentVersion = splitId[1];
            return currentVersion;
          };

          const currentVersion = getCurrentVersion(appData); // If an app ID is given, the current app version will be used in the app launch route.

          dependencies.upgrade = Ember.get(this, 'catalog').fetchTemplate(`${params.template}-${params.upgrade}`, true, currentVersion);
          dependencies.tpl = Ember.get(this, 'catalog').fetchTemplate(params.template, false, currentVersion);
        }).catch(err => {
          throw new Error(err);
        });
      } else {
        // If an app ID is not given, the current app version will not be used in the app launch route.
        if (params.upgrade) {
          dependencies.upgrade = Ember.get(this, 'catalog').fetchTemplate(`${params.template}-${params.upgrade}`, true);
        }

        dependencies.tpl = Ember.get(this, 'catalog').fetchTemplate(params.template);
      }

      if (params.appName) {
        dependencies.app = store.find('app', null, {
          filter: {
            name: params.appName
          }
        }).then(apps => Ember.get(apps, 'firstObject'));
      }

      if (params.namespaceId) {
        dependencies.namespace = clusterStore.find('namespace', params.namespaceId);
      } // check to see if we navigated here naturally or page refresh


      const routeInfos = this.router._router._routerMicrolib.currentRouteInfos;

      if (routeInfos && routeInfos.findBy('name', 'apps-tab.index')) {
        // if natural get teh apps model from the already loaded route
        let appsModel = this.modelFor('apps-tab.index');
        dependencies.apps = Ember.get(appsModel, 'apps');
      } else {
        dependencies.apps = store.findAll('app');
      }

      return Ember.RSVP.hash(dependencies, 'Load dependencies').then(results => {
        var def = Ember.get(results, 'tpl.defaultVersion');
        var links = Ember.get(results, 'tpl.versionLinks');
        var app = Ember.get(results, 'app');
        var catalogTemplateUrl = null;
        const allApps = Ember.get(results, 'apps');

        if (app && params.appId && (!params.upgrade || params.istio)) {
          def = Ember.get(app, 'externalIdInfo.version');
        }

        if (!links[def]) {
          def = Ember.get(results, 'tpl.latestVersion');
        }

        catalogTemplateUrl = links[def];
        var version = Ember.get(this, 'settings.rancherVersion');

        if (version) {
          catalogTemplateUrl = _util2.default.addQueryParam(catalogTemplateUrl, 'rancherVersion', version);
        }

        return this.catalog.fetchByUrl(catalogTemplateUrl).then(catalogTemplate => {
          let {
            requiredNamespace
          } = catalogTemplate;
          let namespaceName; // if we have a required ns or we're upgrading we wont need a new namespace name

          if (requiredNamespace || params.upgrade) {
            if (requiredNamespace) {
              namespaceName = requiredNamespace;
            } else {
              namespaceName = results.namespace.name;
            }
          } else {
            namespaceName = results.tpl.get('displayName');
          }

          let existingNamespace = results.namespaces.findBy('id', namespaceName);
          let kind = 'helm';
          let neuApp = null;
          let namespace = null;
          let newAppName = null;
          let match = null;

          if (existingNamespace) {
            // find any apps that exist in the ns so we can reuse if not
            if (allApps && allApps.length > 0) {
              match = allApps.findBy('name', existingNamespace.displayName);
            }

            if (requiredNamespace || params.upgrade) {
              namespace = existingNamespace;
            } else {
              // no apps exist in the namespace that match our current ns name so we can reuse the ns
              if (match) {
                ({
                  namespace
                } = this.newNamespace(existingNamespace, namespaceName));
              } else {
                namespace = existingNamespace;
              }
            }

            if (match && match.name !== _constants.default.STORAGE.LONGHORN_CATALOG_ITEM_DEFAULT_NAME) {
              newAppName = this.dedupeName(existingNamespace.displayName);
            } else {
              newAppName = existingNamespace.displayName;
            }
          } else {
            // new namespace
            ({
              namespace,
              newAppName
            } = this.newNamespace(existingNamespace, namespaceName));
          }

          if (params.istio) {
            newAppName = '';
          }

          let verArr = Object.keys(links).filter(key => !!links[key]).map(key => ({
            version: key,
            sortVersion: key,
            link: links[key]
          }));

          if (results.app) {
            if (Ember.get(params, 'clone')) {
              neuApp = results.app.cloneForNew();
              Ember.set(neuApp, 'name', newAppName);
            } else {
              neuApp = results.app.clone();
            }
          } else {
            neuApp = this.store.createRecord({
              type: 'app',
              name: newAppName
            });
          }

          let catalogTemplateUrlKey = def;

          if (neuApp.id) {
            const currentAppVersion = Ember.get(neuApp, 'externalIdInfo.version');
            const currentVersion = verArr.filter(ver => ver.version === currentAppVersion);

            if (currentVersion.length === 0) {
              verArr.unshift({
                link: Ember.get(verArr, 'firstObject.link').substring(0, Ember.get(verArr, 'firstObject.link.length') - Ember.get(verArr, 'firstObject.version.length')) + currentAppVersion,
                sortVersion: currentAppVersion,
                version: `${currentAppVersion} (current)`
              });
            } else {
              currentVersion.forEach(ver => {
                Ember.set(ver, 'version', `${ver.version} (current)`);
              });

              if (!params.upgrade) {
                catalogTemplateUrlKey = currentAppVersion;
              }
            }
          }

          if (!params.namespaceId && params.istio) {
            namespace = null;
          }

          return Ember.Object.create({
            catalogTemplate,
            namespace,
            catalogApp: neuApp,
            catalogTemplateUrl: links[catalogTemplateUrlKey],
            // catalogTemplateUrl gets qp's added and this needs with out
            namespaces: results.namespaces,
            tpl: results.tpl,
            tplKind: kind,
            upgradeTemplate: results.upgrade,
            versionLinks: links,
            versionsArray: verArr
          });
        });
      }).catch(error => {
        if (error.status === 404) {
          this.growl.fromError(this.intl.t('newCatalog.error.appData'), error.message);
        }

        return transition.router.transitionTo('apps-tab.index');
      });
    },

    resetController(controller, isExiting
    /* , transition*/
    ) {
      if (isExiting) {
        Ember.setProperties(controller, {
          appId: null,
          appName: null,
          catalog: null,
          clone: null,
          namespaceId: null,
          template: null,
          upgrade: null,
          istio: false
        });
      }
    },

    deactivate() {
      Ember.set(this, `session.${_constants.default.SESSION.PROJECT_ROUTE}`, undefined);
    },

    actions: {
      cancel() {
        Ember.get(this, 'modalService').toggleModal();
      }

    },

    dedupeName(name) {
      const suffix = (0, _util.randomStr)(5, 5, 'novowels');
      return `${name}-${suffix}`;
    },

    newNamespace(duplicateNamespace, namespaceName) {
      let newAppName = namespaceName;

      if (duplicateNamespace) {
        newAppName = this.dedupeName(Ember.get(duplicateNamespace, 'displayName'));
      }

      const namespace = Ember.get(this, 'clusterStore').createRecord({
        type: 'namespace',
        name: newAppName,
        projectId: this.modelFor('authenticated.project').get('project.id')
      });
      return {
        namespace,
        newAppName
      };
    }

  });

  _exports.default = _default;
});