define("ui/components/form-key-to-path/component", ["exports", "ui/components/form-key-to-path/template", "jquery"], function (_exports, _template, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SECRET = 'secret';
  const CONFIG_MAP = 'configmap';

  var _default = Ember.Component.extend({
    layout: _template.default,
    // Inputs
    initialItems: null,
    secretName: null,
    configMapName: null,
    mode: SECRET,
    editing: null,
    ary: null,
    keys: null,
    allSecrets: null,
    allConfigMaps: null,

    init() {
      this._super(...arguments);

      const ary = [];
      const items = Ember.get(this, 'initialItems');

      if (Ember.get(this, 'mode') === SECRET) {
        const allSecrets = Ember.get(this, 'store').all('secret');
        const namespacedSecrets = Ember.get(this, 'store').all('namespacedSecret').filterBy('type', 'namespacedSecret');
        allSecrets.pushObjects(namespacedSecrets);
        Ember.set(this, 'allSecrets', allSecrets);
        this.updateSecretKeys();
      }

      if (Ember.get(this, 'mode') === CONFIG_MAP) {
        const allConfigMaps = Ember.get(this, 'store').all('configmap');
        Ember.set(this, 'allConfigMaps', allConfigMaps);
        this.updateConfigMapKeys();
      }

      if (items) {
        items.forEach(item => {
          ary.push(Ember.Object.create({
            key: item.key,
            path: item.path,
            mode: item.mode ? new Number(item.mode).toString(8) : null
          }));
        });
      }

      Ember.set(this, 'ary', ary);

      if (!ary.length) {
        this.send('add');
      }
    },

    actions: {
      add() {
        let ary = Ember.get(this, 'ary');
        ary.pushObject(Ember.Object.create({
          key: '',
          path: '',
          mode: ''
        }));
        Ember.run.next(() => {
          if (this.isDestroyed || this.isDestroying) {
            return;
          }

          let elem = (0, _jquery.default)('INPUT.key').last()[0];

          if (elem) {
            elem.focus();
          }
        });
      },

      remove(obj) {
        Ember.get(this, 'ary').removeObject(obj);
      }

    },
    secretDidChange: Ember.observer('secretName', function () {
      if (Ember.get(this, 'mode') === SECRET) {
        this.updateSecretKeys();
        Ember.set(this, 'ary', []);
      }
    }),
    configMapDidChange: Ember.observer('configMapName', function () {
      if (Ember.get(this, 'mode') === CONFIG_MAP) {
        this.updateConfigMapKeys();
        Ember.set(this, 'ary', []);
      }
    }),
    aryObserver: Ember.on('init', Ember.observer('ary.@each.{key,path,mode}', function () {
      Ember.run.debounce(this, 'fireChanged', 100);
    })),

    // Secret
    updateSecretKeys() {
      const allSecrets = Ember.get(this, 'allSecrets');
      const secretName = Ember.get(this, 'secretName');
      Ember.set(this, 'keys', []);

      if (secretName) {
        const secret = allSecrets.findBy('name', secretName);

        if (secret) {
          Ember.set(this, 'keys', Object.keys(secret.data || {}).map(k => ({
            label: k,
            value: k
          })));
        }
      }
    },

    // Config Map
    updateConfigMapKeys() {
      const allConfigMaps = Ember.get(this, 'allConfigMaps');
      const configMapName = Ember.get(this, 'configMapName');
      Ember.set(this, 'keys', []);

      if (configMapName) {
        const configMap = allConfigMaps.findBy('name', configMapName);

        if (configMap && configMap.data) {
          Ember.set(this, 'keys', Object.keys(configMap.data).map(k => ({
            label: k,
            value: k
          })));
        }
      }
    },

    fireChanged() {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      const arr = [];
      Ember.get(this, 'ary').forEach(row => {
        const k = (row.get('key') || '').trim();
        const p = (row.get('path') || '').trim();
        const m = (row.get('mode') || '').trim();

        if (k && p) {
          if (m) {
            arr.push({
              key: k,
              path: p,
              mode: parseInt(m, 8)
            });
          } else {
            arr.push({
              key: k,
              path: p
            });
          }
        }
      });

      if (this.changed) {
        this.changed(arr);
      }
    }

  });

  _exports.default = _default;
});