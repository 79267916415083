define("ui/components/container/form-ports/component", ["exports", "ui/components/container/form-ports/template", "jquery"], function (_exports, _template, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const KINDS = ['NodePort', 'HostPort', 'ClusterIP', 'LoadBalancer'];
  const protocolOptions = [{
    label: 'TCP',
    value: 'TCP'
  }, {
    label: 'UDP',
    value: 'UDP'
  }];

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    scope: Ember.inject.service(),
    settings: Ember.inject.service(),
    capabilities: Ember.inject.service(),
    layout: _template.default,
    protocolOptions,
    initialPorts: null,
    editing: false,
    showWarning: false,
    kindChoices: null,
    ports: null,
    nodePortFrom: null,
    nodePortTo: null,
    nodePortPlaceholder: null,

    init() {
      this._super(...arguments);

      this.initPorts();
      this.nodePortRangeDidChange();
      this.initKindChoices();
    },

    actions: {
      addPort() {
        this.get('ports').pushObject(Ember.get(this, 'store').createRecord({
          type: 'containerPort',
          kind: 'NodePort',
          protocol: 'TCP',
          containerPort: ''
        }));
        Ember.run.next(() => {
          if (this.isDestroyed || this.isDestroying) {
            return;
          }

          (0, _jquery.default)('INPUT.public').last()[0].focus();
        });
      },

      removePort(obj) {
        this.get('ports').removeObject(obj);
      }

    },
    portsChanged: Ember.observer('ports.@each.{containerPort,dnsName,hostIp,kind,name,protocol,sourcePort,_ipPort}', function () {
      const errors = [];
      const intl = Ember.get(this, 'intl');
      const ports = Ember.get(this, 'ports');
      ports.forEach(obj => {
        let containerPort = obj.containerPort;

        if (!containerPort) {
          errors.push(intl.t('formPorts.error.privateRequired'));
        }

        if (obj.kind === 'HostPort' && !Ember.get(obj, '_ipPort')) {
          errors.push(intl.t('formPorts.error.hostPort.sourcePortRequired'));
        }

        if (obj.kind === 'LoadBalancer' && !Ember.get(obj, 'sourcePort')) {
          errors.push(intl.t('formPorts.error.loadBalancer.sourcePortRequired'));
        }

        if (!obj.sourcePort) {
          delete obj['sourcePort'];
        }

        if (Ember.get(obj, 'kind') === 'HostPort') {
          let port = Ember.get(obj, '_ipPort') || '';
          let ip;
          const idx = port.lastIndexOf(':');

          if (idx >= 0) {
            ip = port.substr(0, idx);
            port = port.substr(idx + 1);
          }

          const toSet = {};

          if (ip) {
            if (Ember.get(obj, 'hostIp') !== ip) {
              toSet['hostIp'] = ip;
            }
          } else {
            delete obj['hostIp'];
          }

          port = parseInt(port, 10) || undefined;

          if (port !== Ember.get(obj, 'sourcePort')) {
            toSet['sourcePort'] = port;
          }

          Ember.setProperties(obj, toSet);
        }
      });
      this.set('errors', errors.uniq());

      if (this.changed) {
        this.changed(ports.slice());
      }
    }),
    nodePortRangeDidChange: Ember.observer('intl.locale', 'scope.currentCluster.rancherKubernetesEngineConfig.services.kubeApi.serviceNodePortRange', function () {
      const intl = Ember.get(this, 'intl');
      const nodePortRange = Ember.get(this, 'scope.currentCluster.rancherKubernetesEngineConfig.services.kubeApi.serviceNodePortRange');
      const ccPorts = Ember.get(this, 'capabilities.allowedNodePortRanges');

      if (nodePortRange) {
        const ports = nodePortRange.split('-');

        if (ports.length === 2) {
          let from = parseInt(ports[0], 10);
          let to = parseInt(ports[1], 10);

          if (ccPorts.length > 0) {
            if (from < ccPorts[0]) {
              from = ccPorts[0];
            }

            if (to > ccPorts[1]) {
              to = ccPorts[1];
            }
          }

          Ember.setProperties(this, {
            nodePortFrom: from,
            nodePortTo: to,
            nodePortPlaceholder: intl.t('formPorts.nodePort.customPortRangePlaceholder', {
              range: nodePortRange,
              port: from
            })
          });
          return;
        }
      }

      Ember.setProperties(this, {
        nodePortFrom: 1,
        nodePortTo: 65535,
        nodePortPlaceholder: intl.t('formPorts.nodePort.placeholder')
      });
    }),

    initPorts() {
      let ports = Ember.get(this, 'initialPorts') || [];
      ports.forEach(obj => {
        if (Ember.get(obj, 'kind') === 'HostPort') {
          const ip = Ember.get(obj, 'hostIp');
          const port = Ember.get(obj, 'sourcePort');
          Ember.set(obj, '_ipPort', (ip ? `${ip}:` : '') + port);
        }
      });
      Ember.set(this, 'ports', ports);
    },

    initKindChoices() {
      const loadBalancerCapabilites = Ember.get(this, 'capabilities.loadBalancerCapabilites');
      Ember.set(this, 'kindChoices', KINDS.map(k => {
        let out = {
          translationKey: null,
          value: k
        };

        if (!loadBalancerCapabilites.l4LoadBalancerEnabled && k === 'LoadBalancer') {
          Ember.setProperties(out, {
            translationKey: `formPorts.kind.LoadBalancerDisbaled`,
            disabled: true
          });
        } else {
          Ember.set(out, 'translationKey', `formPorts.kind.${k}`);
        }

        return out;
      }));
    }

  });

  _exports.default = _default;
});