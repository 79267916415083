define("ui/catalog-tab/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    resetController(controller, isExiting
    /* , transition*/
    ) {
      if (isExiting) {
        Ember.setProperties(controller, {
          search: '',
          istio: false
        });
      }
    }

  });

  _exports.default = _default;
});