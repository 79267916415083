define("ui/models/ingress", ["exports", "@rancher/ember-api-store/models/resource", "@rancher/ember-api-store/utils/denormalize"], function (_exports, _resource, _denormalize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resource.default.extend({
    clusterStore: Ember.inject.service(),
    router: Ember.inject.service(),
    type: 'ingress',
    canClone: true,
    canHaveLabels: true,
    namespace: (0, _denormalize.reference)('namespaceId', 'namespace', 'clusterStore'),
    targets: Ember.computed('defaultBackend', 'rules.@each.paths', 'store', 'tls', function () {
      const out = [];
      const store = Ember.get(this, 'store');
      let tlsHosts = [];
      (Ember.get(this, 'tls') || []).forEach(entry => {
        tlsHosts.addObjects(entry.hosts || []);
      });
      tlsHosts = tlsHosts.uniq();
      let def = Ember.get(this, 'defaultBackend');

      if (def) {
        addRow(null, null, def);
      }

      (Ember.get(this, 'rules') || []).forEach(rule => {
        let entries = Ember.get(rule, 'paths') || [];
        entries.forEach(entry => {
          addRow(rule.host, Ember.get(entry, 'path'), entry);
        });
      });

      function addRow(host, path, entry) {
        let reference;

        if (entry.serviceId) {
          reference = store.getById('service', entry.serviceId);
          out.push({
            host,
            tls: tlsHosts.includes(host),
            path,
            reference: entry.serviceId,
            service: reference
          });
        } else if (entry.workloadIds) {
          (entry.workloadIds || []).forEach(id => {
            reference = store.getById('workload', id);
            out.push({
              host,
              tls: tlsHosts.includes(host),
              path,
              reference: id,
              workload: reference
            });
          });
        }
      }

      return out;
    }),
    displayKind: Ember.computed('intl.locale', function () {
      const intl = Ember.get(this, 'intl');
      return intl.t('model.ingress.displayKind');
    }),
    actions: {
      edit() {
        Ember.get(this, 'router').transitionTo('ingresses.run', {
          queryParams: {
            ingressId: Ember.get(this, 'id'),
            upgrade: true
          }
        });
      },

      clone() {
        Ember.get(this, 'router').transitionTo('ingresses.run', {
          queryParams: {
            ingressId: Ember.get(this, 'id'),
            upgrade: false
          }
        });
      }

    }
  });

  _exports.default = _default;
});