define("ui/authenticated/cluster/backups/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    scope: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    currentCluster: Ember.computed.alias('scope.currentCluster'),

    model() {
      return this.globalStore.findAll('etcdbackup');
    },

    setupController(controller, model) {
      let {
        currentCluster
      } = this;
      let clusterId = Ember.get(currentCluster, 'id');
      Ember.set(controller, 'currentClusterId', clusterId);

      this._super(controller, model);
    }

  });

  _exports.default = _default;
});