define("ui/utils/sort", ["exports", "shared/utils/sort"], function (_exports, _sort) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "insensitiveCompare", {
    enumerable: true,
    get: function () {
      return _sort.insensitiveCompare;
    }
  });
  Object.defineProperty(_exports, "sortInsensitiveBy", {
    enumerable: true,
    get: function () {
      return _sort.sortInsensitiveBy;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _sort.default;
    }
  });
});