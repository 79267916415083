define("ui/instance-initializers/global-store", ["exports", "ui/mixins/store-tweaks"], function (_exports, _storeTweaks) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(instance) {
    var application = instance.lookup('application:main');
    var store = instance.lookup('service:globalStore');
    var cookies = instance.lookup('service:cookies');
    store.reopen(_storeTweaks.default);
    store.baseUrl = application.apiEndpoint;
    let timeout = cookies.get('timeout');

    if (timeout) {
      store.defaultTimeout = timeout;
    }
  }

  var _default = {
    name: 'global-store',
    initialize
  };
  _exports.default = _default;
});