define("ui/components/cru-certificate/component", ["exports", "shared/mixins/view-new-edit", "shared/mixins/optionally-namespaced", "ui/components/cru-certificate/template", "shared/utils/util"], function (_exports, _viewNewEdit, _optionallyNamespaced, _template, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_viewNewEdit.default, _optionallyNamespaced.default, {
    intl: Ember.inject.service(),
    layout: _template.default,
    model: null,
    titleKey: 'newCertificate.title',
    scope: 'project',
    namespace: null,
    projectType: 'certificate',
    namespacedType: 'namespacedCertificate',
    isEncrypted: Ember.computed('model.key', function () {
      var key = Ember.get(this, 'model.key') || '';
      return key.match(/^Proc-Type: 4,ENCRYPTED$/m) || key.match(/^-----BEGIN ENCRYPTED.* KEY-----$/m);
    }),

    validate() {
      this._super();

      var errors = Ember.get(this, 'errors') || [];

      if (Ember.get(this, 'scope') !== 'project') {
        errors.pushObjects(Ember.get(this, 'namespaceErrors') || []);
      }

      var intl = Ember.get(this, 'intl');

      if (Ember.get(this, 'isEncrypted')) {
        errors.push(intl.t('newCertificate.errors.encrypted'));
      }

      const key = Ember.get(this, 'model.key');

      if (key) {
        if (!(0, _util.validateKeyWeakly)(key)) {
          errors.push(intl.t('newCertificate.errors.key.invalidFormat'));
        }
      } else {
        errors.push(intl.t('newCertificate.errors.key.required'));
      }

      const certs = Ember.get(this, 'model.certs');

      if (certs) {
        if (!(0, _util.validateCertWeakly)(certs)) {
          errors.push(intl.t('newCertificate.errors.cert.invalidFormat'));
        }
      } else {
        errors.push(intl.t('newCertificate.errors.cert.required'));
      }

      Ember.set(this, 'errors', errors);
      return Ember.get(this, 'errors.length') === 0;
    }

  });

  _exports.default = _default;
});