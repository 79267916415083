define("ui/verify-reset-password/route", ["exports", "@rancher/ember-api-store/utils/fetch"], function (_exports, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      if (params.verify_token) {
        this.set('params', params);
        return (0, _fetch.default)('/verify-token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            token: params.verify_token
          })
        }).then(resp => {
          if (resp.status >= 200 && resp.status < 300) {
            return resp.body;
          }
        }).catch(err => Ember.RSVP.reject(err.body));
      } else {
        this.transitionTo('/');
      }
    },

    setupController(controller, model) {
      this._super(controller, model);

      controller.set('token', this.get('params.verify_token'));
    },

    activate() {
      $('BODY').addClass('container-farm'); // eslint-disable-line
    },

    deactivate() {
      $('BODY').removeClass('container-farm'); // eslint-disable-line
    }

  });

  _exports.default = _default;
});