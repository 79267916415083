define("ui/models/clusterscan", ["exports", "@rancher/ember-api-store/models/resource", "moment", "shared/utils/download-files", "objects-to-csv", "ui/utils/util", "shared/utils/util"], function (_exports, _resource, _moment, _downloadFiles, _objectsToCsv, _util, _util2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const ClusterScan = _resource.default.extend({
    intl: Ember.inject.service(),
    type: 'clusterScan',
    report: 'null',
    reportPromise: null,
    total: Ember.computed.alias('summary.total'),
    passed: Ember.computed.alias('summary.passed'),
    skipped: Ember.computed.alias('summary.skipped'),
    failed: Ember.computed.alias('summary.failed'),
    notApplicable: Ember.computed.alias('summary.notApplicable'),
    isRunning: Ember.computed.equal('state', 'running'),
    loader: Ember.observer('store', 'state', function () {
      this.loadReport();
    }),
    file: Ember.computed('name', 'report', 'resultsForCsv', function () {
      return {
        name: `${Ember.get(this, 'name')}.csv`,
        file: Ember.get(this, 'resultsForCsv')
      };
    }),
    csvFile: Ember.computed('file', async function () {
      const file = Ember.get(this, 'file');
      return { ...file,
        file: await new _objectsToCsv.default(file.file).toString()
      };
    }),
    availableActions: Ember.computed(() => {
      return [{
        sort: 97,
        label: 'action.download',
        icon: 'icon icon-download',
        action: 'download',
        bulkable: true,
        enabled: true
      }];
    }),
    referencedResults: Ember.computed('report.results', function () {
      return (Ember.get(this, 'report.results') || []).map(result => result.checks).reduce((agg, check) => [...agg, ...(check || [])], []);
    }),
    resultsForCsv: Ember.computed('profile', 'referencedResults', 'report', function () {
      return Ember.get(this, 'referencedResults').map(result => {
        const intl = Ember.get(this, 'intl');
        const nodesAndStateForTest = this.getNodesAndStateForTestResult(result);
        const profile = intl.t('cis.scan.report.headers.profile', {
          profile: Ember.get(this, 'profile')
        });
        return {
          [profile]: '',
          ...result,
          [intl.t('cis.scan.report.headers.nodes')]: nodesAndStateForTest.nodes.join(','),
          [intl.t('cis.scan.report.headers.passed_nodes')]: nodesAndStateForTest.passedNodes.join(','),
          [intl.t('cis.scan.report.headers.failed_nodes')]: nodesAndStateForTest.failedNodes.join(','),
          [intl.t('cis.scan.report.headers.node_type')]: result.node_type.join(',')
        };
      });
    }),
    summary: Ember.computed('state', 'report', function () {
      const state = Ember.get(this, 'state');
      const report = Ember.get(this, 'report');

      if (state === 'activating' || !report) {
        return {};
      }

      return {
        total: report.total,
        passed: report.pass,
        skipped: report.skip,
        failed: report.fail,
        notApplicable: report.notApplicable
      };
    }),
    profile: Ember.computed('report.version', 'scanConfig.cisScanConfig.profile', function () {
      const version = (Ember.get(this, 'report.version') || '').toUpperCase();
      const profile = Ember.get(this, 'scanConfig.cisScanConfig.profile') || '';
      return version && profile ? (0, _util2.toTitle)(`${version} ${profile}`) : '';
    }),
    createdDate: Ember.computed('status.conditions.@each.[status,type]', function () {
      if (!Ember.get(this, 'status.conditions')) {
        return '';
      }

      const createdCondition = Ember.get(this, 'status.conditions').find(condition => condition.type === 'Created');

      if (!createdCondition) {
        return '';
      }

      return (0, _moment.default)(createdCondition.lastUpdateTime).format('dddd MMM D HH:mm:ss');
    }),
    actions: {
      async download() {
        const file = await Ember.get(this, 'csvFile');
        (0, _downloadFiles.downloadFile)(file.name, file.file, 'text/plain');
      }

    },

    getNodes(nodes, nodeTypes) {
      return nodeTypes.reduce((agg, nodeType) => [...agg, ...nodes[nodeType]], []);
    },

    getNodeNamesFromNodeType(nodeType) {
      const nodeNames = nodeType.reduce((agg, nodeType) => [...agg, ...Ember.get(this, `report.nodes.${nodeType}`)], []);
      return (0, _util.extractUniqueStrings)(nodeNames);
    },

    getNodesAndStateForTestResult(testResult) {
      const nodeNames = this.getNodeNamesFromNodeType(testResult.node_type);

      if (testResult.state === 'skip') {
        return {
          nodes: nodeNames,
          passedNodes: [],
          failedNodes: []
        };
      }

      if (testResult.state === 'pass') {
        return {
          nodes: nodeNames,
          passedNodes: nodeNames,
          failedNodes: []
        };
      }

      if (testResult.state === 'fail') {
        return {
          nodes: nodeNames,
          passedNodes: [],
          failedNodes: nodeNames
        };
      }

      if (testResult.state === 'notApplicable') {
        return {
          nodes: nodeNames,
          passedNodes: [],
          failedNodes: []
        };
      } // if mixed


      return {
        nodes: nodeNames,
        passedNodes: nodeNames.filter(node => !testResult.nodes.includes(node)),
        failedNodes: testResult.nodes
      };
    },

    async _loadReport() {
      try {
        const report = await this.followLink('report');
        Ember.set(this, 'report', report);
        return report;
      } catch (ex) {
        Ember.set(this, 'report', '');
        return '';
      }
    },

    async loadReport() {
      const reportPromise = Ember.get(this, 'reportPromise');

      if (reportPromise) {
        return reportPromise;
      }

      const newReportPromise = this._loadReport();

      Ember.set(this, 'reportPromise', newReportPromise);
      return newReportPromise;
    }

  });

  ClusterScan.reopenClass({
    stateMap: {
      'running': {
        color: 'text-info'
      }
    }
  });
  var _default = ClusterScan;
  _exports.default = _default;
});