define("ui/models/horizontalpodautoscaler", ["exports", "@rancher/ember-api-store/models/resource", "@rancher/ember-api-store/utils/denormalize"], function (_exports, _resource, _denormalize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const VALUE = 'Value';
  const AVERAGE_VALUE = 'AverageValue';
  const AVERAGE_UTILIZATION = 'Utilization';

  var _default = _resource.default.extend({
    clusterStore: Ember.inject.service(),
    router: Ember.inject.service(),
    canHaveLabels: true,
    workload: (0, _denormalize.reference)('workloadId'),
    namespace: (0, _denormalize.reference)('namespaceId', 'namespace', 'clusterStore'),
    currentMetrics: Ember.computed('metrics.@each.current', function () {
      return (Ember.get(this, 'metrics') || []).map(metric => Ember.get(metric, 'current'));
    }),
    displayMetrics: Ember.computed('currentMetrics.@each.{averageValue,utilization,value}', 'metrics', function () {
      return (Ember.get(this, 'metrics') || []).map(metric => {
        const arr = [];
        const averageValue = Ember.get(metric, 'current.averageValue');
        const utilization = Ember.get(metric, 'current.utilization');
        const value = Ember.get(metric, 'current.value');
        const targetType = Ember.get(metric, 'target.type');

        if (value) {
          arr.push(value);
        }

        if (averageValue) {
          arr.push(averageValue);
        }

        if (utilization || utilization === 0) {
          arr.push(`${utilization}%`);
        }

        switch (targetType) {
          case VALUE:
            arr.push(Ember.get(metric, 'target.value'));
            break;

          case AVERAGE_VALUE:
            arr.push(Ember.get(metric, 'target.averageValue'));
            break;

          case AVERAGE_UTILIZATION:
            arr.push(`${Ember.get(metric, 'target.utilization')}%`);
            break;
        }

        return arr.join(' / ');
      });
    }),
    displayMetricsString: Ember.computed('displayMetrics', function () {
      return (Ember.get(this, 'displayMetrics') || []).join(', ');
    }),
    hpaName: Ember.computed('id', function () {
      const items = Ember.get(this, 'id').split(':');

      if (Ember.get(items, 'length') > 1) {
        return items[1];
      }

      return null;
    }),
    actions: {
      edit() {
        Ember.get(this, 'router').transitionTo('authenticated.project.hpa.detail.edit', this.get('id'));
      },

      clone() {
        Ember.get(this, 'router').transitionTo('authenticated.project.hpa.new', this.get('projectId'), {
          queryParams: {
            id: this.get('id')
          }
        });
      }

    }
  });

  _exports.default = _default;
});