define("ui/authenticated/cluster/nodes/index/route", ["exports", "ui/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    scope: Ember.inject.service(),

    model() {
      const cluster = this.modelFor('authenticated.cluster');
      return this.get('globalStore').findAll('node').then(nodes => ({
        cluster,
        nodes
      }));
    },

    setDefaultRoute: Ember.on('activate', function () {
      Ember.set(this, `session.${_constants.default.SESSION.CLUSTER_ROUTE}`, 'authenticated.cluster.nodes');
    })
  });

  _exports.default = _default;
});