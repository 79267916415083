define("ui/models/pipeline", ["exports", "@rancher/ember-api-store/models/resource", "shared/utils/pipeline-constants"], function (_exports, _resource, _pipelineConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  let Pipeline = _resource.default.extend({
    router: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    type: 'pipeline',
    canEdit: false,
    canViewYaml: false,
    canDownloadYaml: false,
    lastRun: Ember.computed('nextRun', function () {
      return parseInt(Ember.get(this, 'nextRun'), 10) - 1;
    }),
    relevantState: Ember.computed('lastRunState', 'state', function () {
      if (Ember.get(this, 'state') === 'removing') {
        return 'removing';
      }

      return Ember.get(this, 'lastRunState') || 'untriggered';
    }),
    displayRepositoryUrl: Ember.computed('repositoryUrl', function () {
      let url = Ember.get(this, 'repositoryUrl');

      if (url.endsWith('.git')) {
        url = url.substr(0, url.length - 4);
      }

      return url;
    }),
    projectName: Ember.computed('displayName', function () {
      const displayName = Ember.get(this, 'displayName');
      let tokens = displayName.split('/');
      return tokens[0].startsWith('~') ? tokens[0].substr(1, tokens[0].length) : tokens[0];
    }),
    repoName: Ember.computed('displayName', function () {
      const displayName = Ember.get(this, 'displayName');
      let tokens = displayName.split('/');
      return tokens[1];
    }),
    displayName: Ember.computed('repositoryUrl', function () {
      let tokens = Ember.get(this, 'repositoryUrl').split('/');
      tokens = tokens.slice(tokens.length - 2);
      const last = tokens[tokens.length - 1];

      if (last.endsWith('.git')) {
        tokens[tokens.length - 1] = last.slice(0, last.length - 4);
      }

      return tokens.join('/');
    }),
    availableActions: Ember.computed('actions', 'links.yaml', 'repositoryUrl', function () {
      let l = Ember.get(this, 'links') || {};
      let a = Ember.get(this, 'actions') || {};

      const isExample = _pipelineConstants.default.DEMO_REPOSITORIES.findBy('url', Ember.get(this, 'repositoryUrl'));

      return [{
        divider: true
      }, {
        label: 'action.run',
        icon: 'icon icon-play',
        action: 'run',
        enabled: !!a.run,
        bulkable: false
      }, {
        divider: true
      }, {
        label: 'action.editConfig',
        icon: 'icon icon-edit',
        action: 'editConfig',
        enabled: !!l.update,
        bulkable: false
      }, {
        label: 'action.editYaml',
        action: 'editYaml',
        icon: 'icon icon-files',
        enabled: !!l.yaml,
        bulkable: false
      }, {
        divider: true
      }, {
        label: 'action.setting',
        icon: 'icon icon-process',
        action: 'setting',
        enabled: !!l.update && !isExample,
        bulkable: false
      }];
    }),
    actions: {
      run() {
        Ember.get(this, 'modalService').toggleModal('modal-pipeline-run', {
          originalModel: this,
          escToClose: true
        });
      },

      setting() {
        Ember.get(this, 'modalService').toggleModal('modal-pipeline-setting', {
          originalModel: this,
          escToClose: true
        });
      },

      editConfig() {
        Ember.get(this, 'router').transitionTo('authenticated.project.pipeline.pipelines.edit', Ember.get(this, 'id'));
      },

      editYaml() {
        Ember.get(this, 'modalService').toggleModal('modal-pipeline-yaml', {
          originalModel: this,
          escToClose: true
        });
      }

    }
  });

  Pipeline.reopenClass({
    mangleIn(data) {
      if (data && data.sourceCodeCredential) {
        data.sourceCodeCredential._id = data.sourceCodeCredential.id;
        delete data.sourceCodeCredential.id;
      }

      return data;
    }

  });
  var _default = Pipeline;
  _exports.default = _default;
});