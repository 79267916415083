define("ui/helpers/pretty-cron", ["exports", "shared/helpers/pretty-cron"], function (_exports, _prettyCron) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _prettyCron.default;
    }
  });
});