define("ui/mixins/notifier", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    getNotifierById(id) {
      if (!id) {
        return null;
      }

      const notifiers = Ember.get(this, 'notifiers');
      return notifiers.filterBy('id', id).get('firstObject');
    },

    recipientsTip: Ember.computed('notifiers.@each.{id,displayName}', 'alertGroup.recipients.@each.{length,notifierType,recipient,notifierId}', function () {
      const recipients = Ember.get(this, 'alertGroup.recipients') || [];
      const out = recipients.map(recipient => {
        const notifierId = Ember.get(recipient, 'notifierId');
        const notifier = this.getNotifierById(notifierId);

        if (notifier) {
          const name = notifier.get('displayNameAndType');
          return `<div class="p-5 pt-0">${name}</div>`;
        }

        return null;
      }).filter(str => !!str).join('');
      return Ember.String.htmlSafe(out);
    })
  });

  _exports.default = _default;
});