define("ui/components/form-service-ports/component", ["exports", "ui/components/form-service-ports/template", "jquery"], function (_exports, _template, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PROTOCOL_OPTIONS = [{
    label: 'TCP',
    value: 'TCP'
  }, {
    label: 'UDP',
    value: 'UDP'
  }];

  var _default = Ember.Component.extend({
    layout: _template.default,
    protocolOptions: PROTOCOL_OPTIONS,
    editing: true,
    ports: Ember.computed.alias('model.ports'),

    init() {
      this._super(...arguments);

      if (!Ember.get(this, 'ports')) {
        Ember.set(this, 'model.ports', []);
      }
    },

    actions: {
      addPort() {
        Ember.get(this, 'ports').pushObject(Ember.get(this, 'store').createRecord({
          type: 'servicePort',
          protocol: 'TCP'
        }));
        Ember.run.next(() => {
          if (this.isDestroyed || this.isDestroying) {
            return;
          }

          (0, _jquery.default)('INPUT.public').last()[0].focus();
        });
      },

      removePort(obj) {
        Ember.get(this, 'ports').removeObject(obj);
      }

    }
  });

  _exports.default = _default;
});