define("ui/verify/controller", ["exports", "@rancher/ember-api-store/utils/fetch"], function (_exports, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    access: Ember.inject.service(),
    accountCreated: false,
    loading: false,
    canSend: false,
    actions: {
      createAcct() {
        var body = this.get('model');
        body.token = this.get('token');
        this.set('loading', true);
        (0, _fetch.default)('/create-user', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        }).then(() => {
          let code = `${body.email}:${body.pw}`;
          this.get('access').login(code).then(() => {
            this.transitionToRoute('authenticated');
            this.set('loading', false);
          }).catch(err => {
            this.set('saving', false);
            this.set('errors', [err.body.detail]);
          });
        }).catch(err => {
          this.set('saving', false);
          this.set('errors', [err.body.detail]);
        });
      }

    }
  });

  _exports.default = _default;
});