define("ui/components/input-edit-password/component", ["exports", "ui/components/input-edit-password/template", "shared/utils/util"], function (_exports, _template, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CHANGE = 'change';
  const SET = 'set';

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    access: Ember.inject.service(),
    layout: _template.default,
    showCurrent: true,
    generate: false,
    setOrChange: CHANGE,
    editLabel: 'modalEditPassword.actionButton',
    currentPassword: null,
    user: null,
    showDeleteTokens: false,
    forceSaveDisabled: false,
    confirmBlurred: false,
    serverErrors: null,
    password: null,
    confirm: null,
    deleteTokens: false,

    didReceiveAttrs() {
      if (Ember.get(this, 'generate')) {
        this.send('regenerate');
      }

      Ember.run.next(this, 'focusStart');
    },

    actions: {
      regenerate() {
        this.generateChanged();
      },

      blurredConfirm() {
        Ember.set(this, 'confirmBlurred', true);
      },

      save(cb) {
        const user = Ember.get(this, 'user');
        const old = Ember.get(this, 'currentPassword') || '';
        const neu = Ember.get(this, 'password') || '';
        Ember.set(this, 'serverErrors', []);
        const setOrChange = Ember.get(this, 'setOrChange');
        let promise;

        if (setOrChange === CHANGE) {
          // @TODO-2.0 better way to call collection actions
          promise = Ember.get(this, 'globalStore').request({
            url: '/v3/users?action=changepassword',
            method: 'POST',
            data: {
              currentPassword: old.trim(),
              newPassword: neu.trim()
            }
          });
        } else if (setOrChange === SET) {
          promise = user.doAction('setpassword', {
            newPassword: neu.trim()
          });
        }

        return promise.then(() => Ember.get(this, 'access').loadMe().then(() => {
          if (Ember.get(this, 'deleteTokens')) {
            return Ember.get(this, 'globalStore').findAll('token').then(tokens => {
              const promises = [];
              tokens.forEach(token => {
                if (!token.current) {
                  promises.push(token.delete());
                }
              });
              return Ember.RSVP.all(promises).catch(() => Ember.RSVP.resolve());
            });
          } else {
            return Ember.RSVP.resolve();
          }
        }).then(() => {
          Ember.get(this, 'complete')(true);
          Ember.run.later(this, () => {
            if (this.isDestroyed || this.isDestroying) {
              return;
            }

            cb(true);
          }, 1000);
        })).catch(err => {
          Ember.set(this, 'serverErrors', [err.message]);
          Ember.get(this, 'complete')(false);
          cb(false);
        });
      }

    },
    generateChanged: Ember.observer('generate', function () {
      if (Ember.get(this, 'generate')) {
        Ember.set(this, 'password', (0, _util.randomStr)(16, 16, 'password'));
      } else {
        Ember.set(this, 'password', '');
        Ember.set(this, 'confirm', '');
        Ember.run.next(this, 'focusStart');
      }
    }),
    saveDisabled: Ember.computed('generate', 'passwordsMatch', 'forceSaveDisabled', 'showCurrent', 'currentPassword', function () {
      if (Ember.get(this, 'forceSaveDisabled')) {
        return true;
      }

      if (Ember.get(this, 'showCurrent') && !Ember.get(this, 'currentPassword')) {
        return true;
      }

      if (Ember.get(this, 'generate')) {
        return false;
      }

      return !Ember.get(this, 'passwordsMatch');
    }),
    passwordsMatch: Ember.computed('password', 'confirm', function () {
      const pass = (Ember.get(this, 'password') || '').trim();
      const confirm = (Ember.get(this, 'confirm') || '').trim();
      return pass && confirm && pass === confirm;
    }),
    errors: Ember.computed('passwordsMatch', 'confirm', 'confirmBlurred', 'serverErrors.[]', function () {
      let out = Ember.get(this, 'serverErrors') || [];

      if (Ember.get(this, 'confirmBlurred') && Ember.get(this, 'confirm') && !Ember.get(this, 'passwordsMatch')) {
        out.push(Ember.get(this, 'intl').t('modalEditPassword.mismatch'));
      }

      return out;
    }),

    focusStart() {
      const elem = $('.start')[0]; // eslint-disable-line

      if (elem) {
        elem.focus();
      }
    }

  });

  _exports.default = _default;
});