define("ui/components/notifier/notifier-row/component", ["exports", "ui/components/notifier/notifier-row/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    layout: _template.default,
    model: null,
    tagName: 'TR',
    classNames: 'main-row',
    bulkActions: true,
    showNotifierValue: Ember.computed('model.notifierType', function () {
      const t = Ember.get(this, 'model.notifierType');
      return t === 'slack' || t === 'email';
    })
  });

  _exports.default = _default;
});