define("ui/mixins/subscribe", ["exports", "shared/mixins/subscribe"], function (_exports, _subscribe) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _subscribe.default;
    }
  });
});