define("ui/authenticated/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nyo/ZQi2",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,8],[[35,7]],null,[[\"default\"],[{\"statements\":[[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"bg-warning\"],[14,1,\"azuread-warning-banner\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[\"authPage.azuread.updateEndpoint.banner.message\"],null]],[1,\" \"],[6,[37,2],null,[[\"route\"],[\"global-admin.security.authentication.azuread\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,1],[\"authPage.azuread.updateEndpoint.banner.linkText\"],null]]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[1,[30,[36,6],null,[[\"pageScope\",\"goToPrevious\"],[[35,5],\"goToPrevious\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"main\"],[14,0,\"clearfix\"],[12],[2,\"\\n  \"],[1,[30,[36,10],[[30,[36,9],null,null]],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,8],[[35,7]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,0],null,[[\"showAbout\"],[\"showAbout\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"page-footer\",\"t\",\"link-to\",\"azureNeedsUpdate\",\"if\",\"pageScope\",\"page-header\",\"isPopup\",\"unless\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "ui/authenticated/template.hbs"
    }
  });

  _exports.default = _default;
});