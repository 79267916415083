define("ui/components/form-node-requirement/component", ["exports", "ui/components/form-node-requirement/template", "shared/utils/constants"], function (_exports, _template, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    term: null,
    ruleArray: null,
    editing: true,

    init() {
      this._super(...arguments);

      this.initRuleArray();
    },

    didInsertElement() {
      if (Ember.get(this, 'ruleArray.length') === 0) {
        this.send('addRule');
      }
    },

    actions: {
      addRule() {
        Ember.get(this, 'ruleArray').pushObject({
          operator: 'In'
        });
      },

      removeRule(rule) {
        Ember.get(this, 'ruleArray').removeObject(rule);
      }

    },
    ruleChanged: Ember.observer('ruleArray.@each.{key,operator,values}', function () {
      Ember.set(this, 'term.matchExpressions', (Ember.get(this, 'ruleArray') || []).filter(rule => {
        if (rule.operator === 'In' || rule.operator === 'NotIn') {
          return rule.values;
        }

        return rule.key;
      }).map(rule => {
        const out = {
          key: rule.key,
          operator: rule.operator
        };

        if (rule.operator === 'In' || rule.operator === 'NotIn') {
          out.values = rule.values.split(',');
        }

        return out;
      }));
    }),
    operatorChoices: _constants.default.VOLUME_NODE_SELECTOR_OPERATOR,

    initRuleArray() {
      const ruleArray = [];
      (Ember.get(this, 'term.matchExpressions') || []).forEach(requirement => {
        ruleArray.push({
          key: requirement.key,
          operator: requirement.operator,
          values: (requirement.values || []).join(',')
        });
      });
      Ember.set(this, 'ruleArray', ruleArray);
    }

  });

  _exports.default = _default;
});