define("ui/components/modal-edit-namespace/component", ["exports", "shared/mixins/new-or-edit", "shared/mixins/modal-base", "ui/components/modal-edit-namespace/template"], function (_exports, _newOrEdit, _modalBase, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ISTIO_INJECTION = 'istio-injection';
  const ENABLED = 'enabled';

  var _default = Ember.Component.extend(_modalBase.default, _newOrEdit.default, {
    scope: Ember.inject.service(),
    layout: _template.default,
    classNames: ['large-modal'],
    editing: true,
    model: null,
    allNamespaces: null,
    allProjects: null,
    tags: null,
    beforeSaveModel: null,
    initAutoInjectionStatus: null,
    originalModel: Ember.computed.alias('modalService.modalOpts'),

    init() {
      this._super(...arguments);

      const orig = Ember.get(this, 'originalModel');
      const clone = orig.clone();
      delete clone.services;
      Ember.setProperties(this, {
        model: clone,
        tags: (Ember.get(this, 'primaryResource.tags') || []).join(','),
        allNamespaces: Ember.get(this, 'clusterStore').all('namespace'),
        allProjects: Ember.get(this, 'globalStore').all('project').filterBy('clusterId', Ember.get(this, 'scope.currentCluster.id'))
      });
      const labels = Ember.get(this, 'primaryResource.labels');
      const enabled = labels && labels[ISTIO_INJECTION] === ENABLED;
      Ember.setProperties(this, {
        istioInjection: enabled,
        initAutoInjectionStatus: enabled
      });
    },

    actions: {
      addTag(tag) {
        const tags = Ember.get(this, 'primaryResource.tags') || [];
        tags.addObject(tag);
        Ember.set(this, 'tags', tags.join(','));
      },

      updateNsQuota(quota) {
        if (quota) {
          Ember.set(this, 'primaryResource.resourceQuota', {
            limit: quota
          });
        } else {
          Ember.set(this, 'primaryResource.resourceQuota', null);
        }
      },

      updateContainerDefault(limit) {
        Ember.set(this, 'primaryResource.containerDefaultResourceLimit', limit);
      },

      setLabels(labels) {
        let out = {};
        labels.forEach(row => {
          out[row.key] = row.value;
        });
        Ember.set(this, 'primaryResource.labels', out);
      },

      toggleAutoInject() {
        Ember.set(this, 'istioInjection', !Ember.get(this, 'istioInjection'));
      }

    },
    projectDidChange: Ember.observer('primaryResource.project.id', function () {
      Ember.set(this, 'switchingProject', true);
      Ember.run.next(() => {
        Ember.set(this, 'switchingProject', false);
      });

      if (!Ember.get(this, 'primaryResource.project.resourceQuota')) {
        Ember.set(this, 'primaryResource.resourceQuota', null);
      }
    }),
    tagsDidChanged: Ember.observer('tags', function () {
      Ember.set(this, 'primaryResource.tags', Ember.get(this, 'tags').split(',') || []);
    }),
    canMoveNamespace: Ember.computed('primaryResource.actionLinks.move', function () {
      return !!Ember.get(this, 'primaryResource.actionLinks.move');
    }),
    projectLimit: Ember.computed('allProjects', 'primaryResource.projectId', 'primaryResource.resourceQuota.limit', function () {
      const projectId = Ember.get(this, 'primaryResource.projectId');
      const project = Ember.get(this, 'allProjects').findBy('id', projectId);
      return Ember.get(project, 'resourceQuota.limit');
    }),
    projectUsedLimit: Ember.computed('allProjects', 'primaryResource.projectId', 'primaryResource.resourceQuota.limit', function () {
      const projectId = Ember.get(this, 'primaryResource.projectId');
      const project = Ember.get(this, 'allProjects').findBy('id', projectId);
      return Ember.get(project, 'resourceQuota.usedLimit');
    }),
    nsDefaultQuota: Ember.computed('allProjects', 'primaryResource.projectId', 'primaryResource.resourceQuota.limit', function () {
      const projectId = Ember.get(this, 'primaryResource.projectId');
      const project = Ember.get(this, 'allProjects').findBy('id', projectId);
      return Ember.get(project, 'namespaceDefaultResourceQuota.limit');
    }),

    validate() {
      this._super();

      const errors = Ember.get(this, 'errors') || [];
      const quotaErrors = Ember.get(this, 'primaryResource').validateResourceQuota(Ember.get(this, 'originalModel.resourceQuota.limit'));

      if (quotaErrors.length > 0) {
        errors.pushObjects(quotaErrors);
      }

      Ember.set(this, 'errors', errors);
      return Ember.get(this, 'errors.length') === 0;
    },

    willSave() {
      const labels = { ...Ember.get(this, 'primaryResource.labels')
      };

      if (Ember.get(this, 'scope.currentCluster.istioEnabled')) {
        if (Ember.get(this, 'istioInjection')) {
          labels[ISTIO_INJECTION] = ENABLED;
        } else {
          delete labels[ISTIO_INJECTION];
        }
      }

      Ember.setProperties(this, {
        'beforeSaveModel': Ember.get(this, 'originalModel').clone(),
        'primaryResource.labels': labels
      });
      return this._super(...arguments);
    },

    didSave(pr) {
      const {
        projectId
      } = pr;

      if (projectId !== Ember.get(this, 'beforeSaveModel.projectId')) {
        return pr.doAction('move', {
          projectId
        }).then(pr => pr);
      }
    },

    doneSaving() {
      this.send('cancel');
    }

  });

  _exports.default = _default;
});