define("ui/components/persistent-volume-table/component", ["exports", "ui/components/persistent-volume-table/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const headers = [{
    name: 'state',
    sort: ['sortState', 'displayName'],
    searchField: 'displayState',
    translationKey: 'generic.state',
    width: 120
  }, {
    name: 'name',
    sort: ['sortName', 'id'],
    searchField: 'displayName',
    translationKey: 'generic.name'
  }, {
    name: 'displayPvc',
    sort: ['displayPvc', 'sortName', 'id'],
    searchField: 'displayPvc',
    translationKey: 'cruPersistentVolume.pvc'
  }, {
    name: 'source',
    sort: ['displaySource', 'name', 'id'],
    searchField: ['displaySource', 'configName'],
    translationKey: 'persistentVolumePage.source.label'
  }];

  var _default = Ember.Component.extend({
    scope: Ember.inject.service(),
    layout: _template.default,
    headers,
    tagName: '',
    sortBy: 'name',
    searchText: '',
    subRows: true,
    suffix: true,
    paging: true
  });

  _exports.default = _default;
});