define("ui/components/cluster/cis/scan/table-row/component", ["exports", "ui/components/cluster/cis/scan/table-row/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',
    errorMessage: Ember.computed.reads('error.message'),
    error: Ember.computed('model.status.conditions.[]', function () {
      return this.model.status.conditions.find(condition => condition.type === 'Failed');
    })
  });

  _exports.default = _default;
});