define("ui/models/clusteralertrule", ["exports", "@rancher/ember-api-store/models/resource", "ui/mixins/model-alert", "ui/utils/constants"], function (_exports, _resource, _modelAlert, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const clusterAlertRule = _resource.default.extend(_modelAlert.default, {
    intl: Ember.inject.service(),
    type: 'clusterAlertRule',
    _targetType: 'systemService',
    canClone: true,
    canEdit: true,
    targetType: Ember.computed('clusterScanRule.scanRunType', 'eventRule.resourceKind', 'metricRule.expression', 'nodeRule.{nodeId,selector}', 'systemServiceRule.condition', function () {
      if (Ember.get(this, 'systemServiceRule.condition')) {
        return 'systemService';
      }

      if (Ember.get(this, 'nodeRule.nodeId')) {
        return 'node';
      }

      if (Ember.get(this, 'nodeRule.selector')) {
        return 'nodeSelector';
      }

      if (Ember.get(this, 'eventRule.resourceKind')) {
        return 'event';
      }

      if (Ember.get(this, 'metricRule.expression')) {
        return 'metric';
      }

      if (Ember.get(this, 'clusterScanRule.scanRunType')) {
        return 'cisScan';
      }

      return;
    }),
    displayTargetType: Ember.computed('targetType', function () {
      return Ember.get(this, 'intl').t(`alertPage.targetTypes.${Ember.get(this, 'targetType')}`);
    }),
    displayCondition: Ember.computed('clusterScanRule', 'metricRule.{comparison,expression,thresholdValue}', 'nodeRule.{condition,cpuThreshold,memThreshold}', 'targetType', function () {
      const t = Ember.get(this, 'targetType');
      const intl = Ember.get(this, 'intl');
      let out = intl.t('alertPage.na');
      const c = Ember.get(this, 'nodeRule.condition');
      const cpuThreshold = Ember.get(this, 'nodeRule.cpuThreshold');
      const memThreshold = Ember.get(this, 'nodeRule.memThreshold');
      const metricRule = Ember.get(this, 'metricRule');
      const clusterScanRule = Ember.get(this, 'clusterScanRule');

      switch (t) {
        case 'systemService':
          out = intl.t('alertPage.index.table.displayCondition.unhealthy');
          break;

        case 'event':
          out = intl.t('alertPage.index.table.displayCondition.happens');
          break;

        case 'node':
        case 'nodeSelector':
          switch (c) {
            case 'notready':
              out = intl.t('alertPage.index.table.displayCondition.notReady');
              break;

            case 'cpu':
              out = intl.t('alertPage.index.table.displayCondition.cpuUsage', {
                percent: cpuThreshold
              });
              break;

            case 'mem':
              out = intl.t('alertPage.index.table.displayCondition.memUsage', {
                percent: memThreshold
              });
              break;
          }

          break;

        case 'metric':
          out = metricRule.comparison === _constants.default.ALERTING_COMPARISON.HAS_VALUE ? intl.t(`alertPage.comparison.${metricRule.comparison}`) : `${intl.t(`alertPage.comparison.${metricRule.comparison}`)} ${metricRule.thresholdValue}`;
          break;

        case 'cisScan':
          out = clusterScanRule.failuresOnly ? intl.t('alertPage.index.table.displayCondition.failure') : intl.t('alertPage.index.table.displayCondition.happens');
          break;
      }

      return out;
    }),
    threshold: Ember.computed('targetType', 'nodeRule.{memThreshold,cpuThreshold,condition}', function () {
      const t = Ember.get(this, 'targetType');
      const c = Ember.get(this, 'nodeRule.condition');

      if (t === 'node' || t === 'nodeSelector') {
        if (c === 'cpu') {
          return Ember.get(this, 'nodeRule.cpuThreshold');
        }

        if (c === 'mem') {
          return Ember.get(this, 'nodeRule.memThreshold');
        }
      }

      return 0;
    }),
    actions: {
      clone() {
        Ember.get(this, 'router').transitionTo('authenticated.cluster.alert.new-rule', Ember.get(this, 'groupId'), {
          queryParams: {
            id: Ember.get(this, 'id')
          }
        });
      },

      edit() {
        Ember.get(this, 'router').transitionTo('authenticated.cluster.alert.edit-rule', Ember.get(this, 'groupId'), Ember.get(this, 'id'));
      }

    }
  });

  var _default = clusterAlertRule;
  _exports.default = _default;
});