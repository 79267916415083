define("ui/authenticated/cluster/storage/classes/new/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      back() {
        this.transitionToRoute('authenticated.cluster.storage.classes');
      }

    }
  });

  _exports.default = _default;
});