define("ui/authenticated/cluster/projects/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    scope: Ember.inject.service(),
    queryParams: {
      group: 'group'
    },
    group: 'project',
    actions: {
      changeView() {}

    },
    rows: Ember.computed('model.namespaces.@each.displayName', 'model.projects.@each.clusterId', 'scope.currentCluster.id', function () {
      return Ember.get(this, 'model.namespaces').filterBy('displayName');
    }),
    projects: Ember.computed('model.namespaces.@each.displayName', 'model.projects.@each.clusterId', 'scope.currentCluster.id', function () {
      return Ember.get(this, 'model.projects').filterBy('clusterId', Ember.get(this, 'scope.currentCluster.id'));
    }),
    projectsWithoutNamespaces: Ember.computed('projects.@each.{id,state,clusterId}', 'rows.@each.projectId', function () {
      return Ember.get(this, 'projects').filter(p => {
        const namespaces = Ember.get(this, 'rows').filterBy('projectId', Ember.get(p, 'id')) || [];
        return Ember.get(namespaces, 'length') <= 0;
      }).sortBy('displayName');
    })
  });

  _exports.default = _default;
});