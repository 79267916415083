define("ui/authenticated/project/certificates/detail/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      const all = this.modelFor('authenticated.project.certificates');
      let cert = all.projectCerts.findBy('id', params.certificate_id);

      if (cert) {
        return cert;
      }

      cert = all.namespacedCerts.findBy('id', params.certificate_id);

      if (cert) {
        return cert;
      }

      return Ember.get(this, 'store').find('certificate', params.certificate_id);
    }

  });

  _exports.default = _default;
});