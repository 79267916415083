define("ui/instance-initializers/cluster-store", ["exports", "ui/mixins/store-tweaks"], function (_exports, _storeTweaks) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(instance) {
    var application = instance.lookup('application:main');
    var clusterStore = instance.lookup('service:clusterStore');
    var cookies = instance.lookup('service:cookies');
    clusterStore.reopen(_storeTweaks.default);
    clusterStore.baseUrl = application.clusterEndpoint;
    let timeout = cookies.get('timeout');

    if (timeout) {
      clusterStore.defaultTimeout = timeout;
    }
  }

  var _default = {
    name: 'cluster-store',
    initialize
  };
  _exports.default = _default;
});