define("ui/not-found/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    language: Ember.inject.service('user-language'),

    beforeModel() {
      return this.get('language').initLanguage();
    },

    redirect() {
      let url = this._router.location.formatURL('/not-found');

      if (window.location.pathname !== url) {
        this.transitionTo('not-found');
      }
    }

  });

  _exports.default = _default;
});