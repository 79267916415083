define("ui/components/modal-whats-new/component", ["exports", "ui/utils/constants", "shared/mixins/modal-base", "ui/components/modal-whats-new/template", "shared/utils/parse-version"], function (_exports, _constants, _modalBase, _template, _parseVersion) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modalBase.default, {
    settings: Ember.inject.service(),
    prefs: Ember.inject.service(),
    layout: _template.default,
    classNames: ['modal-container', 'span-8', 'offset-2', 'modal-whats-new', 'alert'],
    actions: {
      cancel() {
        const version = Ember.get(this, 'settings.rancherVersion');

        if (!(0, _parseVersion.isDevBuild)(version)) {
          Ember.set(this, `prefs.${_constants.default.PREFS.SEEN_WHATS_NEW}`, version);
        }

        Ember.get(this, 'modalService').toggleModal();
      }

    }
  });

  _exports.default = _default;
});