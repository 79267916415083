define("ui/authenticated/cluster/storage/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    redirect() {
      this.transitionTo('authenticated.cluster.storage.persistent-volumes.index');
    }

  });

  _exports.default = _default;
});