define("ui/components/namespace-resource-quota/component", ["exports", "shared/utils/util", "shared/utils/parse-unit", "ui/components/namespace-resource-quota/template"], function (_exports, _util, _parseUnit, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const defaultRadix = 10;
  const defaultIncrement = 1024;
  const defaultDivisor = 1048576;
  const defaultMultiplier = 3;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    layout: _template.default,
    limit: null,
    usedLimit: null,
    projectlimit: null,
    projectQuota: null,
    nsDefaultQuota: null,
    editing: null,
    isNew: null,
    quotaArray: null,

    init() {
      this._super(...arguments);

      this.initQuotaArray();
      Ember.run.next(() => {
        this.quotaDidChange();
      });
    },

    quotaDidChange: Ember.observer('quotaArray.@each.{key,value}', function () {
      const out = {};
      (Ember.get(this, 'quotaArray') || []).forEach(quota => {
        if (quota.key) {
          let value = parseInt(Ember.get(quota, 'value'), defaultRadix);
          let max = Ember.get(quota, 'max');
          let currentUse = Ember.get(quota, 'currentProjectUse.firstObject.value');

          if (value === undefined || value === null) {
            out[quota.key] = '';
            return;
          }

          if (value > max || currentUse + value > max) {
            value = Ember.set(quota, 'value', max - currentUse);
          }

          out[quota.key] = this.quotaWithUnits(quota, value);
        }
      });

      if (this.changed) {
        this.changed(Object.keys(out).length ? out : null);
      }

      this.updateLimits();
    }),

    quotaWithUnits(quota, value, readable = false) {
      let cpuNotation = readable ? 'milli CPUs' : 'm';
      let memNotation = readable ? 'MiB' : 'Mi';
      let storageNotation = readable ? 'GB' : 'Gi';

      if (quota.key === 'limitsCpu' || quota.key === 'requestsCpu') {
        return `${value}${cpuNotation}`;
      } else if (quota.key === 'limitsMemory' || quota.key === 'requestsMemory') {
        return `${value}${memNotation}`;
      } else if (quota.key === 'requestsStorage') {
        return `${value}${storageNotation}`;
      } else {
        return value;
      }
    },

    updateLimits() {
      (Ember.get(this, 'quotaArray') || []).forEach(quota => {
        if (quota.key) {
          const intl = Ember.get(this, 'intl');
          const value = parseInt(Ember.get(quota, 'value'), defaultRadix) || 0;
          const usedValue = Ember.get(quota, 'currentProjectUse.firstObject.value');
          const newUse = Ember.get(quota, 'currentProjectUse.lastObject');
          const myNewUse = usedValue + value;
          const remaining = Ember.get(quota, 'max') - myNewUse > 0 ? Ember.get(quota, 'max') - myNewUse : 0;
          const newTotals = [{
            label: intl.t('formResourceQuota.table.resources.reserved'),
            value: this.quotaWithUnits(quota, usedValue, true)
          }, {
            label: intl.t('formResourceQuota.table.resources.namespace'),
            value: this.quotaWithUnits(quota, value, true)
          }, {
            label: intl.t('formResourceQuota.table.resources.available'),
            value: this.quotaWithUnits(quota, remaining, true)
          }, {
            label: intl.t('formResourceQuota.table.resources.max'),
            value: this.quotaWithUnits(quota, Ember.get(quota, 'max'), true)
          }]; // if (remaining === 0) {
          //   set(newUse, 'color', 'bg-error');
          // } else {
          //   if (get(newUse, 'color') === 'bg-error') {
          //     set(newUse, 'color', 'bg-warning');
          //   }
          // }

          Ember.set(newUse, 'value', value);
          Ember.set(quota, 'totalLimits', newTotals);
        }
      });
    },

    initQuotaArray() {
      const {
        limit,
        nsDefaultQuota,
        intl
      } = this;
      const used = Ember.get(this, 'usedLimit');
      const currentProjectLimit = Ember.get(this, 'projectLimit');
      const array = [];
      Object.keys(nsDefaultQuota).forEach(key => {
        if (key !== 'type' && typeof nsDefaultQuota[key] === 'string') {
          let value, currentProjectUse, totalLimits, remaining;
          let usedValue = '';
          let max = '';
          let newUse = null;
          let projectUse = Ember.get(used, key) || '0';

          if (limit && !limit[key]) {
            array.push({
              key,
              value: '',
              currentProjectUse: []
            });
            return;
          }

          value = limit && limit[key] ? limit[key] : nsDefaultQuota[key];

          switch (key) {
            case 'limitsCpu':
            case 'requestsCpu':
              value = (0, _util.convertToMillis)(value);
              usedValue = (0, _util.convertToMillis)(projectUse);
              max = (0, _util.convertToMillis)(Ember.get(currentProjectLimit, key));
              break;

            case 'limitsMemory':
            case 'requestsMemory':
              value = (0, _parseUnit.parseSi)(value, defaultIncrement) / defaultDivisor;
              usedValue = (0, _parseUnit.parseSi)(projectUse, defaultIncrement) / defaultDivisor;
              max = (0, _parseUnit.parseSi)(Ember.get(currentProjectLimit, key), defaultIncrement) / defaultDivisor;
              break;

            case 'requestsStorage':
              value = (0, _parseUnit.parseSi)(value) / defaultIncrement ** defaultMultiplier;
              usedValue = (0, _parseUnit.parseSi)(projectUse) / defaultIncrement ** defaultMultiplier;
              max = (0, _parseUnit.parseSi)(Ember.get(currentProjectLimit, key)) / defaultIncrement ** defaultMultiplier;
              break;

            default:
              value = parseInt(value, defaultRadix);
              usedValue = parseInt(projectUse, defaultRadix);
              max = parseInt(Ember.get(currentProjectLimit, key), defaultRadix);
              break;
          }

          if (!Ember.get(this, 'isNew')) {
            usedValue = usedValue - value;
          }

          newUse = usedValue + value;
          remaining = max - newUse > 0 ? max - newUse : 0;
          currentProjectUse = [{
            // current use
            color: 'bg-primary',
            label: key,
            value: usedValue
          }, {
            // only need the new value here because progress-multi-bar adds this to the previous
            color: 'bg-info',
            label: key,
            value
          }];
          totalLimits = [{
            label: intl.t('formResourceQuota.table.resources.reserved'),
            value: this.quotaWithUnits(nsDefaultQuota[key], usedValue, true)
          }, {
            label: intl.t('formResourceQuota.table.resources.namespace'),
            value: this.quotaWithUnits(nsDefaultQuota[key], value, true)
          }, {
            label: intl.t('formResourceQuota.table.resources.available'),
            value: this.quotaWithUnits(nsDefaultQuota[key], remaining, true)
          }, {
            label: intl.t('formResourceQuota.table.resources.max'),
            value: this.quotaWithUnits(nsDefaultQuota[key], max, true)
          }];
          array.push({
            currentProjectUse,
            key,
            max,
            totalLimits,
            value
          });
        }
      });
      Ember.set(this, 'quotaArray', array);
    }

  });

  _exports.default = _default;
});