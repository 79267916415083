define("ui/mixins/model-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    router: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    scope: Ember.inject.service(),
    intl: Ember.inject.service(),
    pageScope: Ember.computed.reads('scope.currentPageScope'),
    canClone: false,
    relevantState: Ember.computed('combinedState', 'alertState', 'state', function () {
      if (Ember.get(this, 'state') === 'removing') {
        return 'removing';
      }

      return this.get('combinedState') || this.get('alertState') || 'unknown';
    }),
    isAlertRule: Ember.computed('type', function () {
      return (Ember.get(this, 'type') || '').endsWith('Rule');
    }),

    init() {
      const stateMap = {
        'muted': {
          icon: 'icon icon-tag',
          color: 'text-default'
        },
        'alerting': {
          icon: 'icon icon-tag',
          color: 'text-error'
        },
        'inactive': {
          icon: 'icon icon-tag',
          color: 'text-warning'
        }
      };
      Ember.set(this.constructor, 'stateMap', stateMap);

      this._super(...arguments);
    },

    displayTargetType: Ember.computed('targetType', function () {
      const t = Ember.get(this, 'targetType');
      const intl = Ember.get(this, 'intl');
      return intl.t(`alertPage.targetTypes.${t}`);
    }),
    resourceKind: Ember.computed('eventRule.resourceKind', function () {
      const rk = Ember.get(this, 'eventRule.resourceKind');
      return Ember.get(this, 'intl').t(`alertPage.resourceKinds.${rk}`);
    }),
    firstRecipient: Ember.computed('recipients.length', function () {
      const recipient = (Ember.get(this, 'recipients') || []).get('firstObject');

      if (recipient && Ember.get(recipient, 'notifierId')) {
        const notifierId = Ember.get(recipient, 'notifierId');

        if (!notifierId) {
          return null;
        }

        const notifier = Ember.get(this, 'globalStore').all('notifier').filterBy('id', notifierId).get('firstObject');

        if (!notifier) {
          return null;
        }

        return `${notifier.get('displayNameAndType')}`;
      }

      return null;
    }),
    displayRecipient: Ember.computed('firstRecipient', 'model.recipients.length', 'recipients.length', function () {
      const len = Ember.get(this, 'recipients.length');
      const firstRecipient = Ember.get(this, 'firstRecipient');
      const intl = Ember.get(this, 'intl');
      let out = intl.t('alertPage.na');

      if (len === 0) {// out = na;
      } else if (len === 1) {
        out = firstRecipient;
      } else {
        out = `${len} ${intl.t('alertPage.items')}`;
      }

      return out;
    }),
    nodeName: Ember.computed('targetNode.nodeId', function () {
      const id = Ember.get(this, 'targetNode.nodeId');

      if (!id) {
        return null;
      }

      const node = Ember.get(this, 'globalStore').all('node').filterBy('id', id).get('firstObject');

      if (!node) {
        return null;
      }

      return node.get('displayName');
    }),
    actions: {
      edit() {
        const ps = Ember.get(this, 'pageScope');
        const id = Ember.get(this, 'id');

        if (ps === 'cluster') {
          Ember.get(this, 'router').transitionTo('authenticated.cluster.alert.edit', id);
        } else if (ps === 'project') {
          Ember.get(this, 'router').transitionTo('authenticated.project.alert.edit', id);
        }
      },

      mute() {
        return this.doAction('mute');
      },

      unmute() {
        return this.doAction('unmute');
      },

      activate() {
        return this.doAction('activate');
      },

      deactivate() {
        return this.doAction('deactivate');
      }

    },
    availableActions: Ember.computed('actionLinks.{activate,deactivate,mute,unmute}', 'alertState', 'isAlertRule', function () {
      const state = this.get('alertState');
      const isAlertRule = Ember.get(this, 'isAlertRule');
      let out = [];

      if (isAlertRule) {
        out = [{
          label: 'action.mute',
          action: 'mute',
          enabled: state === 'alerting',
          icon: 'icon icon-mute',
          bulkable: true
        }, {
          label: 'action.unmute',
          action: 'unmute',
          icon: 'icon icon-unmute',
          enabled: state === 'muted',
          bulkable: true
        }, {
          label: 'action.deactivate',
          action: 'deactivate',
          icon: 'icon icon-pause',
          enabled: state === 'active',
          bulkable: true
        }, {
          label: 'action.activate',
          icon: 'icon icon-play',
          action: 'activate',
          enabled: state === 'inactive',
          bulkable: true
        }];
      }

      return out;
    })
  });

  _exports.default = _default;
});