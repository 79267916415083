define("ui/utils/platform", ["exports", "shared/utils/platform"], function (_exports, _platform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "platform", {
    enumerable: true,
    get: function () {
      return _platform.platform;
    }
  });
  Object.defineProperty(_exports, "isLinuxy", {
    enumerable: true,
    get: function () {
      return _platform.isLinuxy;
    }
  });
  Object.defineProperty(_exports, "isMac", {
    enumerable: true,
    get: function () {
      return _platform.isMac;
    }
  });
  Object.defineProperty(_exports, "isWin", {
    enumerable: true,
    get: function () {
      return _platform.isWin;
    }
  });
  Object.defineProperty(_exports, "alternateKey", {
    enumerable: true,
    get: function () {
      return _platform.alternateKey;
    }
  });
  Object.defineProperty(_exports, "alternateLabel", {
    enumerable: true,
    get: function () {
      return _platform.alternateLabel;
    }
  });
  Object.defineProperty(_exports, "moreKey", {
    enumerable: true,
    get: function () {
      return _platform.moreKey;
    }
  });
  Object.defineProperty(_exports, "moreLabel", {
    enumerable: true,
    get: function () {
      return _platform.moreLabel;
    }
  });
  Object.defineProperty(_exports, "rangeKey", {
    enumerable: true,
    get: function () {
      return _platform.rangeKey;
    }
  });
  Object.defineProperty(_exports, "rangeLabel", {
    enumerable: true,
    get: function () {
      return _platform.rangeLabel;
    }
  });
  Object.defineProperty(_exports, "isAlternate", {
    enumerable: true,
    get: function () {
      return _platform.isAlternate;
    }
  });
  Object.defineProperty(_exports, "isMore", {
    enumerable: true,
    get: function () {
      return _platform.isMore;
    }
  });
  Object.defineProperty(_exports, "isRange", {
    enumerable: true,
    get: function () {
      return _platform.isRange;
    }
  });
  Object.defineProperty(_exports, "version", {
    enumerable: true,
    get: function () {
      return _platform.version;
    }
  });
  Object.defineProperty(_exports, "userAgent", {
    enumerable: true,
    get: function () {
      return _platform.userAgent;
    }
  });
  Object.defineProperty(_exports, "isGecko", {
    enumerable: true,
    get: function () {
      return _platform.isGecko;
    }
  });
  Object.defineProperty(_exports, "isBlink", {
    enumerable: true,
    get: function () {
      return _platform.isBlink;
    }
  });
  Object.defineProperty(_exports, "isWebKit", {
    enumerable: true,
    get: function () {
      return _platform.isWebKit;
    }
  });
  Object.defineProperty(_exports, "isSafari", {
    enumerable: true,
    get: function () {
      return _platform.isSafari;
    }
  });
  Object.defineProperty(_exports, "isMobile", {
    enumerable: true,
    get: function () {
      return _platform.isMobile;
    }
  });
  Object.defineProperty(_exports, "xhrConcur", {
    enumerable: true,
    get: function () {
      return _platform.xhrConcur;
    }
  });
});