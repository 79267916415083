define("ui/models/cronjob", ["exports", "ui/models/workload"], function (_exports, _workload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const CronJob = _workload.default.extend({
    combinedState: Ember.computed('state', 'cronJobConfig.suspend', function () {
      var service = Ember.get(this, 'state');

      if (service === 'active' && Ember.get(this, 'cronJobConfig.suspend')) {
        return 'suspended';
      }

      return service;
    }),
    availableActions: Ember.computed('actionLinks.{activate,deactivate,garbagecollect,pause,restart,rollback}', 'canEdit', 'cronJobConfig.suspend', 'isPaused', 'links.{remove,update}', 'podForShell', function () {
      const actions = this._super();

      const canEdit = Ember.get(this, 'canEdit');
      const suspend = Ember.get(this, 'cronJobConfig.suspend');
      actions.pushObjects([{
        label: 'action.suspend',
        icon: 'icon icon-pause',
        action: 'suspend',
        enabled: canEdit && !suspend,
        bulkable: false
      }, {
        label: 'action.resumeCronjob',
        icon: 'icon icon-play',
        action: 'resume',
        enabled: canEdit && suspend,
        bulkable: false
      }]);
      return actions;
    }),
    actions: {
      suspend() {
        Ember.set(this, 'cronJobConfig.suspend', true);
        this.save();
      },

      resume() {
        Ember.set(this, 'cronJobConfig.suspend', false);
        this.save();
      }

    }
  });

  var _default = CronJob;
  _exports.default = _default;
});