define("ui/initializers/polyfill-intl", ["exports", "ui/utils/load-script"], function (_exports, _loadScript) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    if (typeof Intl === 'undefined') {
      application.needIntlPolyfill = true;
      application.deferReadiness();
      (0, _loadScript.loadScript)(`${application.baseAssets}assets/intl/intl.min.js`).then(() => (0, _loadScript.loadScript)(`${application.baseAssets}assets/intl/locales/en-us.js`)).finally(() => {
        application.advanceReadiness();
      });
    }
  }

  var _default = {
    name: 'polyfill-intl',
    initialize,
    before: 'app'
  };
  _exports.default = _default;
});