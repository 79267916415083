define("ui/models/clustertemplaterevision", ["exports", "@rancher/ember-api-store/models/resource", "@rancher/ember-api-store/utils/denormalize"], function (_exports, _resource, _denormalize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resource.default.extend({
    globalStore: Ember.inject.service(),
    router: Ember.inject.service(),
    growl: Ember.inject.service(),
    intl: Ember.inject.service(),
    type: 'clustertemplaterevision',
    clusterTemplate: (0, _denormalize.reference)('clusterTemplateId', 'clusterTemplate', 'globalStore'),
    canRemove: Ember.computed.alias('canMakeDefault'),
    combinedState: Ember.computed('enabled', function () {
      if (Ember.get(this, 'enabled')) {
        return 'active';
      }

      return 'disabled';
    }),
    canMakeDefault: Ember.computed('clusterTemplate.defaultRevisionId', 'id', function () {
      let defaultRevisionId = Ember.get(this, 'clusterTemplate.defaultRevisionId') || null;

      if (defaultRevisionId) {
        return this.id !== defaultRevisionId;
      }

      return false;
    }),
    availableActions: Ember.computed('actionLinks.[]', 'canMakeDefault', 'clusterTemplate.defaultRevisionId', 'enabled', function () {
      const a = Ember.get(this, 'actionLinks') || {};
      return [{
        label: 'generic.enable',
        icon: 'icon icon-play',
        action: 'enable',
        enabled: !!a.enable,
        bulkable: true
      }, {
        label: 'generic.disable',
        icon: 'icon icon-stop',
        action: 'disable',
        enabled: !!a.disable,
        bulkable: true
      }, {
        label: 'action.makeDefault',
        icon: 'icon icon-success',
        action: 'setDefault',
        enabled: this.canMakeDefault
      }, {
        label: 'action.cloneRevision',
        icon: 'icon icon-copy',
        action: 'newRevision',
        enabled: true
      }];
    }),
    actions: {
      newRevision() {
        this.router.transitionTo('global-admin.cluster-templates.new-revision', this.clusterTemplateId, {
          queryParams: {
            revision: this.id
          }
        });
      },

      setDefault() {
        const {
          clusterTemplate
        } = this;
        const successTitle = this.intl.t('action.setDefaultRevision.success.title');
        const successMessage = this.intl.t('action.setDefaultRevision.success.message', {
          name: this.displayName,
          ctName: this.clusterTemplate.displayName
        });
        Ember.set(clusterTemplate, 'defaultRevisionId', this.id);
        clusterTemplate.save().then(() => this.growl.success(successTitle, successMessage)).catch(err => this.growl.fromError(err));
      },

      disable() {
        this.doAction('disable').catch(err => {
          Ember.set(this, 'enabled', true);
          this.growl.fromError(err);
          return err;
        });
      },

      enable() {
        this.doAction('enable').catch(err => {
          Ember.set(this, 'enabled', false);
          this.growl.fromError(err);
          return err;
        });
      }

    },

    validationErrors() {
      let errors = [];

      if (!Ember.get(this, 'name')) {
        errors.push('Revision name is required');
      }

      if (errors.length > 0) {
        return errors;
      }

      return errors;
    }

  });

  _exports.default = _default;
});