define("ui/components/modal-shortcuts/component", ["exports", "shared/mixins/modal-base", "ui/utils/constants", "ui/components/modal-shortcuts/template"], function (_exports, _modalBase, _constants, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let DEFAULT_TIME = 400;

  var _default = Ember.Component.extend(_modalBase.default, {
    prefs: Ember.inject.service(),
    settings: Ember.inject.service(),
    access: Ember.inject.service(),
    layout: _template.default,
    classNames: ['generic', 'medium-modal', 'p-0'],
    time: DEFAULT_TIME,
    timer: null,
    isAdmin: Ember.computed.alias('access.admin'),

    init() {
      this._super(...arguments);

      this.set('pods', this.get('store').all('pod'));
      this.set('timer', setInterval(() => {
        this.updateTime();
      }, 1000));
    },

    willDestroyElement() {
      clearInterval(this.get('timer'));
    },

    containerCount: Ember.computed('pods.length', function () {
      let count = this.get('pods.length');

      if (count > 9) {
        return count;
      } else {
        return `0${count}`;
      }
    }),
    currentTheme: Ember.computed(`prefs.${_constants.default.PREFS.THEME}`, function () {
      return this.get(`prefs.${_constants.default.PREFS.THEME}`);
    }),

    updateTime() {
      let time = this.get('time');

      if (time > 0) {
        time--;
      } else {
        time = DEFAULT_TIME;
      }

      this.set('time', time);
    }

  });

  _exports.default = _default;
});