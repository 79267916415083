define("ui/components/storage-class/provisioner-local-storage/component", ["exports", "shared/components/storage-class/provisioner-local-storage/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _component.default;
    }
  });
});