define("ui/utils/convert-yaml", ["exports", "shared/utils/convert-yaml"], function (_exports, _convertYaml) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "convertDotAnswersToYaml", {
    enumerable: true,
    get: function () {
      return _convertYaml.convertDotAnswersToYaml;
    }
  });
});