define("ui/components/form-node-affinity/component", ["exports", "ui/components/form-node-affinity/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    layout: _template.default,
    model: null,
    rules: null,
    editing: null,

    init() {
      this._super(...arguments);

      this.initRules();
    },

    actions: {
      addRule() {
        const rule = Ember.Object.create({});
        Ember.get(this, 'rules').pushObject(rule);
      },

      removeRule(rule) {
        Ember.get(this, 'rules').removeObject(rule);
      }

    },
    rulesChanged: Ember.observer('rules.@each.matchExpressions', function () {
      const out = (Ember.get(this, 'rules') || []).filter(rule => {
        return rule.matchExpressions && rule.matchExpressions.length > 0;
      });

      if (this.changed) {
        this.changed({
          nodeSelectorTerms: out
        });
      }
    }),

    initRules() {
      let rules = [];
      (Ember.get(this, 'model.nodeSelectorTerms') || []).forEach(term => {
        rules.push({
          matchExpressions: term.matchExpressions
        });
      });
      Ember.set(this, 'rules', rules);
    }

  });

  _exports.default = _default;
});