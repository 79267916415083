define("ui/workload/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel() {
      const promises = {};

      if (!window.Prettycron) {
        Ember.set(promises, 'Prettycron', emberAutoImportDynamic("prettycron"));
      }

      if (!window.ShellQuote) {
        Ember.set(promises, 'ShellQuote', emberAutoImportDynamic("shell-quote"));
      }

      return Ember.RSVP.hash(promises).then(resolved => {
        if (resolved.Prettycron) {
          window.Prettycron = resolved.Prettycron;
        }

        if (resolved.ShellQuote) {
          window.ShellQuote = resolved.ShellQuote;
        }

        return resolved;
      });
    },

    model(params) {
      return Ember.RSVP.hash({
        workload: this.get('store').find('workload', params.workload_id)
      }).then(hash => Ember.Object.create(hash));
    },

    setupController(controller, model) {
      this._super(...arguments);

      let lc = model.get('workload.containers.firstObject');
      controller.setProperties({
        launchConfig: lc
      });
    }

  });

  _exports.default = _default;
});