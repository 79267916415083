define("ui/utils/download-files", ["exports", "shared/utils/download-files"], function (_exports, _downloadFiles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _downloadFiles.default;
    }
  });
});