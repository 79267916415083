define("ui/components/container-logs/component", ["exports", "ui/utils/util", "ui/utils/platform", "ui/components/container-logs/template", "ui/utils/constants", "shared/utils/download-files", "jquery"], function (_exports, _util, _platform, _template, _constants, _downloadFiles, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const LINES = 500;
  var AnsiUp = null;

  var _default = Ember.Component.extend({
    scope: Ember.inject.service(),
    prefs: Ember.inject.service(),
    layout: _template.default,
    instance: null,
    alternateLabel: _platform.alternateLabel,
    showProtip: true,
    classNames: 'container-log',
    status: 'connecting',
    containerName: null,
    socket: null,
    wrapLines: null,
    isFollow: true,
    followTimer: null,
    isPrevious: false,

    init() {
      this._super(...arguments);

      if (AnsiUp) {
        this._bootstrap();
      } else {
        emberAutoImportDynamic("ansi_up").then(module => {
          AnsiUp = module.default;

          this._bootstrap();
        });
      }
    },

    didInsertElement() {
      this._super();

      Ember.run.next(this, () => {
        const body = (0, _jquery.default)('.log-body');
        let lastScrollTop = 0;
        body.scroll(() => {
          const scrollTop = body[0].scrollTop;

          if (lastScrollTop > scrollTop) {
            Ember.set(this, 'isFollow', false);
          }

          lastScrollTop = scrollTop;
        });
        var btn = (0, _jquery.default)('.scroll-bottom')[0]; // eslint-disable-line

        if (btn) {
          btn.focus();
        }
      });
    },

    willDestroyElement() {
      clearInterval(Ember.get(this, 'followTimer'));
      this.disconnect();

      this._super();
    },

    actions: {
      download() {
        const ignore = function (el, sel) {
          return el.clone().find(sel || '>*').remove().end();
        };

        const log = (0, _jquery.default)('.log-body').children('.log-msg');
        let stripped = '';
        log.each((i, e) => {
          stripped += `${ignore((0, _jquery.default)(e), 'span').text()} \n`;
        });
        (0, _downloadFiles.downloadFile)('container.log', stripped);
      },

      cancel() {
        this.disconnect();

        if (this.dismiss) {
          this.dismiss();
        }
      },

      clear() {
        var body = (0, _jquery.default)('.log-body')[0];

        if (body) {
          body.innerHTML = '';
          body.scrollTop = 0;
        }
      },

      scrollToTop() {
        (0, _jquery.default)('.log-body').animate({
          scrollTop: '0px'
        });
      },

      followLog() {
        Ember.set(this, 'isFollow', true);
        this.send('scrollToBottom');
      },

      scrollToBottom() {
        var body = (0, _jquery.default)('.log-body');
        body.stop().animate({
          scrollTop: `${body[0].scrollHeight + 1000}px`
        });
      }

    },
    wrapLinesDidChange: Ember.observer('wrapLines', function () {
      Ember.set(this, `prefs.${_constants.default.PREFS.WRAP_LINES}`, Ember.get(this, 'wrapLines'));
    }),
    watchReconnect: Ember.on('init', Ember.observer('containerName', 'isPrevious', function () {
      this.disconnect();
      this.send('clear');

      if (this.containerName) {
        this.exec();
      }
    })),

    _bootstrap() {
      Ember.setProperties(this, {
        wrapLines: !!Ember.get(this, `prefs.${_constants.default.PREFS.WRAP_LINES}`),
        containerName: Ember.get(this, 'containerName') || Ember.get(this, 'instance.containers.firstObject.name')
      });

      this._initTimer();
    },

    _initTimer() {
      const followTimer = setInterval(() => {
        if (Ember.get(this, 'isFollow')) {
          this.send('scrollToBottom');
        }
      }, 1000);
      Ember.set(this, 'followTimer', followTimer);
    },

    exec() {
      var instance = Ember.get(this, 'instance');
      const clusterId = Ember.get(this, 'scope.currentCluster.id');
      const namespaceId = Ember.get(instance, 'namespaceId');
      const podName = Ember.get(instance, 'name');
      const containerName = Ember.get(this, 'containerName');
      const scheme = window.location.protocol === 'https:' ? 'wss://' : 'ws://';
      let url = `${scheme}${window.location.host}/k8s/clusters/${clusterId}/api/v1/namespaces/${namespaceId}/pods/${podName}/log`;
      url += `?container=${encodeURIComponent(containerName)}&tailLines=${LINES}&follow=true&timestamps=true&previous=${Ember.get(this, 'isPrevious')}`;
      this.connect(url);
    },

    connect(url) {
      var socket = new WebSocket(url, 'base64.binary.k8s.io');
      Ember.set(this, 'socket', socket);
      var body = null;
      Ember.set(this, 'status', 'initializing');

      socket.onopen = () => {
        Ember.set(this, 'status', 'connected');
      };

      socket.onmessage = message => {
        body = (0, _jquery.default)('.log-body')[0];
        let ansiup = new AnsiUp();
        Ember.set(this, 'status', 'connected');
        const data = AWS.util.base64.decode(message.data).toString();
        let html = '';
        data.trim().split(/\n/).filter(line => line).forEach(line => {
          var match = line.match(/^\[?([^ \]]+)\]?\s?/);
          var dateStr = '';
          var msg = '';

          if (match && this.isDate(new Date(match[1]))) {
            var date = new Date(match[1]);
            msg = line.substr(match[0].length);
            dateStr = `<span class="log-date">${_util.default.escapeHtml(date.toLocaleDateString())} ${_util.default.escapeHtml(date.toLocaleTimeString())} </span>`;
          } else {
            msg = line;
          } // @@TODO@@ - 10-13-17 - needed to remove the escaping here because it was being double escaped but double verify that its acutally being escaped


          html += `<div class="log-msg log-combined">${dateStr}${ansiup.ansi_to_html(msg)}</div>`;
        });
        body.insertAdjacentHTML('beforeend', html);
      };

      socket.onclose = () => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }

        Ember.set(this, 'status', 'disconnected');
      };
    },

    disconnect() {
      Ember.set(this, 'status', 'closed');
      var socket = Ember.get(this, 'socket');

      if (socket) {
        socket.close();
        Ember.set(this, 'socket', null);
      }
    },

    isDate(date) {
      return new Date(date) !== 'Invalid Date' && !isNaN(new Date(date));
    }

  });

  _exports.default = _default;
});