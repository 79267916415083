define("ui/models/dockercredential", ["exports", "@rancher/ember-api-store/models/resource"], function (_exports, _resource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.PRESETS_BY_NAME = _exports.PRESETS = void 0;
  const PRESETS = {
    'index.docker.io': 'dockerhub',
    'quay.io': 'quay',
    [window.location.host]: window.location.host
  };
  _exports.PRESETS = PRESETS;
  const PRESETS_BY_NAME = {};
  _exports.PRESETS_BY_NAME = PRESETS_BY_NAME;
  Object.keys(PRESETS).forEach(key => {
    PRESETS_BY_NAME[PRESETS[key]] = key;
  });

  var DockerCredential = _resource.default.extend({
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    state: 'active',
    canClone: true,
    firstRegistry: Ember.computed.alias('asArray.firstObject'),
    registryCount: Ember.computed.alias('asArray.length'),
    asArray: Ember.computed('registries', function () {
      const all = Ember.get(this, 'registries') || {};
      let reg, address, preset;
      return Object.keys(all).map(key => {
        address = key.toLowerCase();
        reg = all[key];
        preset = PRESETS[address] || 'custom';
        return {
          address,
          auth: reg.auth,
          username: reg.username,
          password: reg.password,
          preset
        };
      });
    }),
    searchAddresses: Ember.computed('asArray.@each.address', function () {
      return Ember.get(this, 'asArray').map(x => Ember.get(x, 'address')).sort().uniq();
    }),
    searchUsernames: Ember.computed('asArray.@each.username', function () {
      return Ember.get(this, 'asArray').map(x => Ember.get(x, 'username')).sort().uniq();
    }),
    displayAddress: Ember.computed('intl.locale', 'registryCount', 'firstRegistry.address', function () {
      const address = Ember.get(this, 'firstRegistry.address');

      if (Ember.get(this, 'registryCount') > 1) {
        return 'cruRegistry.multiple';
      } else if (address === window.location.host) {
        return address;
      } else if (PRESETS[address]) {
        return Ember.get(this, 'intl').t(`cruRegistry.address.${PRESETS[address]}`);
      } else {
        return address;
      }
    }),
    displayUsername: Ember.computed('registryCount', 'firstRegistry.username', function () {
      const intl = Ember.get(this, 'intl');
      const username = Ember.get(this, 'firstRegistry.username');

      if (Ember.get(this, 'registryCount') > 1) {
        return intl.t('cruRegistry.multiple');
      } else {
        return username;
      }
    }),
    actions: {
      clone() {
        Ember.get(this, 'router').transitionTo('authenticated.project.registries.new', {
          queryParams: {
            id: Ember.get(this, 'id'),
            type: Ember.get(this, 'type')
          }
        });
      }

    }
  });

  var _default = DockerCredential;
  _exports.default = _default;
});