define("ui/components/container/form-upgrade/component", ["exports", "ui/components/container/form-upgrade/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    workload: null,
    scaleMode: null,
    editing: null,
    isUpgrade: null,
    classNames: ['accordion-wrapper'],

    didReceiveAttrs() {
      if (!this.get('expandFn')) {
        this.set('expandFn', item => {
          item.toggleProperty('expanded');
        });
      }
    },

    workloadConfig: Ember.computed('scaleMode', function () {
      const scaleMode = Ember.get(this, 'scaleMode');
      const config = Ember.get(this, `workload.${scaleMode}Config`);
      return config;
    }),
    componentName: Ember.computed('scaleMode', function () {
      return `container/form-upgrade-${Ember.get(this, 'scaleMode').dasherize()}`;
    })
  });

  _exports.default = _default;
});