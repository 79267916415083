define("ui/mixins/endpoint-ports", ["exports", "shared/mixins/endpoint-ports"], function (_exports, _endpointPorts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _endpointPorts.default;
    }
  });
});