define("ui/authenticated/project/hpa/detail/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      const store = Ember.get(this, 'store');
      return Ember.RSVP.hash({
        deployments: store.findAll('deployment'),
        hpa: store.find('horizontalpodautoscaler', params.hpa_id)
      });
    }

  });

  _exports.default = _default;
});