define("ui/authenticated/cluster/route", ["exports", "ui/mixins/preload", "ui/utils/constants"], function (_exports, _preload, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const VALID_ROUTES = ['authenticated.cluster.nodes', 'authenticated.cluster.storage.classes', 'authenticated.cluster.storage.persistent-volumes', 'authenticated.cluster.notifier', 'authenticated.cluster.alert', 'authenticated.cluster.logging', 'authenticated.cluster.monitoring', 'authenticated.cluster.security.members.index', 'authenticated.cluster.projects', 'authenticated.cluster.quotas', 'authenticated.cluster.istio'];

  var _default = Ember.Route.extend(_preload.default, {
    scope: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    clusterStore: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    settings: Ember.inject.service(),

    model(params, transition) {
      return Ember.get(this, 'globalStore').find('cluster', params.cluster_id).then(cluster => {
        const hideLocalCluster = Ember.get(this.settings, 'shouldHideLocalCluster');

        if (hideLocalCluster && Ember.get(cluster, 'id') === 'local') {
          return this.replaceWith('authenticated');
        }

        return Ember.get(this, 'scope').startSwitchToCluster(cluster).then(() => {
          if (Ember.get(cluster, 'isReady')) {
            const preloads = [this.preload('clusterScan', 'globalStore'), this.preload('namespace', 'clusterStore'), this.preload('persistentVolume', 'clusterStore'), this.preload('storageClass', 'clusterStore')];

            if (Ember.get(cluster, 'isRKE')) {
              preloads.push(this.preload('etcdBackup', 'globalStore'));
            }

            return this.loadSchemas('clusterStore').then(() => Ember.RSVP.all(preloads).then(() => cluster));
          } else {
            return cluster;
          }
        }).catch(err => {
          // @TODO-2.0 right now the API can't return schemas for a not-active cluster
          if (err.status === 404) {
            return cluster;
          } else {
            return Ember.RSVP.reject(err);
          }
        });
      }).catch(err => this.loadingError(err, transition));
    },

    afterModel(model) {
      return Ember.get(this, 'scope').finishSwitchToCluster(model);
    },

    redirect(router, transition) {
      let route = this.get(`session.${_constants.default.SESSION.CLUSTER_ROUTE}`);

      if (transition.targetName === 'authenticated.cluster.index' && VALID_ROUTES.includes(route)) {
        this.replaceWith(route);
      }
    },

    actions: {
      becameReady() {
        Ember.get(this, 'clusterStore').reset();
        this.refresh();
      },

      importYaml() {
        this.modalService.toggleModal('modal-import', {
          escToClose: true,
          clusterOnly: true
        });
      }

    }
  });

  _exports.default = _default;
});