define("ui/authenticated/cluster/cis/scan/controller", ["exports", "shared/utils/download-files"], function (_exports, _downloadFiles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    scope: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    securityScanConfig: Ember.inject.service(),
    router: Ember.inject.service(),
    tableHeaders: [{
      name: 'state',
      sort: ['state', 'number', 'id'],
      translationKey: 'cis.scan.table.state',
      width: 100
    }, {
      name: 'name',
      sort: ['id'],
      translationKey: 'cis.scan.table.name'
    }, {
      name: 'profile',
      sort: ['profile', 'id'],
      translationKey: 'cis.scan.table.profile',
      width: 200
    }, {
      name: 'passed',
      sort: ['passed', 'id'],
      translationKey: 'cis.scan.table.passed',
      width: 80
    }, {
      name: 'skipped',
      sort: ['skipped', 'id'],
      translationKey: 'cis.scan.table.skipped',
      width: 90
    }, {
      name: 'failed',
      sort: ['failed', 'id'],
      translationKey: 'cis.scan.table.failed',
      width: 80
    }, {
      name: 'notapplicable',
      sort: ['notApplicable', 'id'],
      translationKey: 'cis.scan.table.notApplicable',
      width: 150
    }, {
      name: 'date',
      sort: ['createdTS', 'id'],
      searchField: false,
      translationKey: 'cis.scan.table.date',
      width: 220
    }],
    sortBy: 'date',
    descending: true,
    runningClusterScans: Ember.computed.filterBy('clusterScans', 'isRunning', true),
    isRKE: Ember.computed.alias('scope.currentCluster.isRKE'),
    actions: {
      runScan() {
        Ember.get(this, 'scope.currentCluster').send('runCISScan');
      },

      setSchedule() {
        Ember.get(this, 'scope.currentCluster').send('edit', {
          scrollTo: 'security-scan'
        });
      },

      setAlert() {
        Ember.get(this, 'router').transitionTo('authenticated.cluster.alert.new', {
          queryParams: {
            for: 'security-scan'
          }
        });
      }

    },
    bulkActionHandler: Ember.computed(function () {
      return {
        download: async scans => {
          const asyncFiles = scans.map(scan => Ember.get(scan, 'csvFile'));
          const files = await Promise.all(asyncFiles);
          const zip = await (0, _downloadFiles.generateZip)(files);
          await (0, _downloadFiles.downloadFile)(`cis-scans.zip`, zip, Ember.get(zip, 'type'));
        },
        promptDelete: async scans => {
          Ember.get(this, 'modalService').toggleModal('confirm-delete', {
            escToClose: true,
            resources: scans
          });
        }
      };
    }),
    clusterScans: Ember.computed('model.clusterScans.[]', 'scope.currentCluster.id', function () {
      return Ember.get(this, 'model.clusterScans').filterBy('clusterId', Ember.get(this, 'scope.currentCluster.id'));
    })
  });

  _exports.default = _default;
});