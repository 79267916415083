define("ui/signup/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return {
        type: 'account',
        kind: 'user',
        name: '',
        email: ''
      };
    },

    activate() {
      $('BODY').addClass('container-farm'); // eslint-disable-line
    },

    deactivate() {
      $('BODY').removeClass('container-farm'); // eslint-disable-line
    }

  });

  _exports.default = _default;
});