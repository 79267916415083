define("ui/initializers/session", ["exports", "@rancher/ember-api-store/mixins/serializable"], function (_exports, _serializable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  // Don't serialize the injected session
  _serializable.default.reopen({
    reservedKeys: ['session']
  });

  function initialize(application) {
    application.inject('controller', 'session', 'service:session');
    application.inject('route', 'session', 'service:session');
    application.inject('model', 'session', 'service:session');
    application.inject('component', 'session', 'service:session');
    application.inject('controller', 'tab-session', 'service:tab-session');
    application.inject('route', 'tab-session', 'service:tab-session');
    application.inject('model', 'tab-session', 'service:tab-session');
    application.inject('component', 'tab-session', 'service:tab-session');
  }

  var _default = {
    name: 'session',
    initialize
  };
  _exports.default = _default;
});