define("ui/containers/run/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    growl: Ember.inject.service(),
    scope: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    queryParams: ['namespaceId', 'workloadId', 'podId', 'addSidekick', 'upgrade', 'launchConfigIndex'],
    namespaceId: null,
    serviceId: null,
    containerId: null,
    addSidekick: null,
    upgrade: false,
    deleting: false,
    dataMap: Ember.computed.alias('model.dataMap'),
    actions: {
      transitionOut() {
        this.transitionToRoute('containers.index', Ember.get(this, 'scope.currentProject.id'));
      },

      done() {
        this.send('transitionOut');
      },

      cancel() {
        this.send('transitionOut');
      },

      promptRemove(idx) {
        let slc = Ember.get(this, 'dataMap.workload.secondaryLaunchConfigs').objectAt(idx);
        let resources = [{
          cb: () => {
            this.removeSidekick(idx);
          },
          displayName: Ember.get(slc, 'name')
        }];
        Ember.get(this, 'modalService').toggleModal('confirm-delete', {
          resources,
          showProtip: false
        });
      }

    },

    removeSidekick(idx) {
      let workload = Ember.get(this, 'dataMap.workload').clone();
      Ember.set(workload, 'completeLaunchConfigs', true);
      Ember.set(workload, 'completeUpdate', true);
      let slcs = Ember.get(workload, 'secondaryLaunchConfigs');
      slcs.removeAt(idx);
      const containers = [Ember.get(workload, 'containers.firstObject')];
      containers.pushObjects(slcs);
      Ember.set(workload, 'containers', containers);
      Ember.set(this, 'deleting', true);
      workload.save().then(() => {
        this.send('done');
        Ember.set(this, 'deleting', false);
      }).catch(err => {
        Ember.get(this, 'growl').fromError(err);
        Ember.set(this, 'deleting', false);
      });
    }

  });

  _exports.default = _default;
});