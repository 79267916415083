define("ui/models/launchconfig", ["exports", "@rancher/ember-api-store/models/resource"], function (_exports, _resource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var LaunchConfig = _resource.default.extend({
    displayEnvironmentVars: Ember.computed('launchConfig.environment', function () {
      var envs = [];
      var environment = this.get('launchConfig.environment') || {};
      Object.keys(environment).forEach(key => {
        envs.pushObject({
          key,
          value: environment[key]
        });
      });
      return envs;
    })
  });

  var _default = LaunchConfig;
  _exports.default = _default;
});