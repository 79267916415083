define("ui/components/modal-edit-host/component", ["exports", "shared/mixins/new-or-edit", "shared/mixins/modal-base", "ui/components/modal-edit-host/template"], function (_exports, _newOrEdit, _modalBase, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modalBase.default, _newOrEdit.default, {
    scope: Ember.inject.service(),
    layout: _template.default,
    classNames: ['large-modal'],
    model: null,
    editing: true,
    requireAny: null,
    customName: null,
    originalModel: Ember.computed.alias('modalService.modalOpts'),
    taintCapabilites: Ember.computed.alias('scope.currentCluster.capabilities.taintSupport'),

    init() {
      this._super(...arguments);

      Ember.set(this, 'model', Ember.get(this, 'originalModel').clone());

      if (Ember.get(this, 'model.name')) {
        Ember.set(this, 'customName', Ember.get(this, 'model.name'));
      }
    },

    customNameObserver: Ember.on('init', Ember.observer('customName', function () {
      let cn = Ember.get(this, 'customName');

      if (cn && cn.length > 0) {
        Ember.set(this, 'primaryResource.name', cn);
      } else {
        Ember.set(this, 'primaryResource.name', null);
      }
    })),

    doneSaving() {
      this.send('cancel');
    }

  });

  _exports.default = _default;
});