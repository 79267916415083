define("ui/authenticated/project/dns/detail/edit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      const store = Ember.get(this, 'store');
      const original = this.modelFor('authenticated.project.dns.detail').record;
      return Ember.RSVP.hash({
        dnsRecords: store.findAll('service'),
        workloads: store.findAll('workload'),
        record: original.clone()
      });
    },

    setupController(controller
    /* , model*/
    ) {
      this._super(...arguments);

      const original = this.modelFor('authenticated.project.dns.detail');
      Ember.set(controller, 'originalModel', original);
    }

  });

  _exports.default = _default;
});