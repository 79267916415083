define("ui/components/cru-volume-claim-template/component", ["exports", "shared/utils/parse-unit", "shared/mixins/view-new-edit", "shared/mixins/child-hook", "ui/components/cru-volume-claim-template/template", "ui/utils/errors"], function (_exports, _parseUnit, _viewNewEdit, _childHook, _template, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_viewNewEdit.default, _childHook.default, {
    intl: Ember.inject.service(),
    scope: Ember.inject.service(),
    layout: _template.default,
    model: null,
    namespace: null,
    persistentVolumes: null,
    storageClasses: null,
    selectNamespace: true,
    actuallySave: true,
    useStorageClass: true,
    capacity: null,
    titleKey: 'cruVolumeClaimTemplate.title',
    canUseStorageClass: Ember.computed.gt('storageClasses.length', 0),

    didReceiveAttrs() {
      if (!Ember.get(this, 'persistentVolumes')) {
        Ember.set(this, 'persistentVolumes', Ember.get(this, 'clusterStore').all('persistentVolume'));
      }

      if (!Ember.get(this, 'storageClasses')) {
        Ember.set(this, 'storageClasses', Ember.get(this, 'clusterStore').all('storageClass'));
      }

      if (!Ember.get(this, 'selectNamespace')) {
        Ember.set(this, 'primaryResource.namespaceId', Ember.get(this, 'namespace.id') || Ember.get(this, 'namespace.name'));
      }

      if (Ember.get(this, 'isNew')) {
        const capacity = Ember.get(this, 'primaryResource.resources.requests.storage');

        if (capacity) {
          const bytes = (0, _parseUnit.parseSi)(capacity);
          const gib = bytes / 1024 ** 3;
          Ember.set(this, 'capacity', gib);
        }

        if (!Ember.get(this, 'canUseStorageClass')) {
          Ember.set(this, 'useStorageClass', false);
        }
      } else {
        Ember.set(this, 'capacity', 10);
      }
    },

    actions: {
      cancel() {
        if (this.cancel) {
          this.cancel();
        }
      }

    },
    persistentVolumeChoices: Ember.computed('persistentVolumes.@each.{name,state}', function () {
      return Ember.get(this, 'persistentVolumes').map(v => {
        let label = Ember.get(v, 'displayName');
        const state = Ember.get(v, 'state');
        const disabled = state !== 'available';

        if (disabled) {
          label += ` (${state})`;
        }

        return {
          label,
          disabled,
          value: Ember.get(v, 'id')
        };
      }).sortBy('label');
    }),

    willSave() {
      const pr = Ember.get(this, 'primaryResource');
      const intl = Ember.get(this, 'intl');

      if (Ember.get(this, 'useStorageClass')) {
        Ember.set(pr, 'volumeId', null);
        const capacity = Ember.get(this, 'capacity');

        if (capacity) {
          Ember.set(pr, 'resources', {
            requests: {
              storage: `${capacity}Gi`
            }
          });
        } else {
          const errors = [];
          errors.push(intl.t('validation.required', {
            key: intl.t('cruPersistentVolumeClaim.capacity.label')
          }));
          Ember.set(this, 'errors', errors);
          return false;
        }
      } else {
        Ember.set(pr, 'storageClassId', Ember.get(pr, 'persistentVolume.storageClassId') || null);
        Ember.set(pr, 'resources', {
          requests: Object.assign({}, Ember.get(pr, 'persistentVolume.capacity'))
        });
      }

      if (!Ember.get(this, 'actuallySave')) {
        let ok = this._super(...arguments);

        if (ok) {
          if (this.doSave) {
            this.doSave({
              pvc: pr
            });
          }

          this.doneSaving();
        }

        return false;
      }

      const self = this;
      const sup = this._super;

      if (Ember.get(this, 'selectNamespace')) {
        const errors = [];
        errors.pushObjects(Ember.get(this, 'namespaceErrors') || []);
        Ember.set(this, 'errors', errors);

        if (Ember.get(errors, 'length') !== 0) {
          return false;
        }

        return this.applyHooks('_beforeSaveHooks').then(() => {
          Ember.set(pr, 'namespaceId', Ember.get(this, 'namespace.id'));
          return sup.apply(self, ...arguments);
        }).catch(err => {
          Ember.set(this, 'errors', [_errors.default.stringify(err)]);
        });
      } else {
        if (!Ember.get(pr, 'namespaceId')) {
          Ember.set(pr, 'namespaceId', '__REPLACE_ME__');
        }

        return sup.apply(self, ...arguments);
      }
    },

    doneSaving() {
      if (this.done) {
        this.done();
      }
    }

  });

  _exports.default = _default;
});