define("ui/models/etcdbackup", ["exports", "@rancher/ember-api-store/models/resource"], function (_exports, _resource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resource.default.extend({
    scope: Ember.inject.service(),
    availableActions: Ember.computed(() => {
      return [{
        label: 'action.restoreFromEtcdBackup',
        icon: 'icon icon-history',
        action: 'restoreFromEtcdBackup',
        enabled: true
      }];
    }),
    actions: {
      restoreFromEtcdBackup() {
        Ember.get(this, 'scope.currentCluster').send('restoreFromEtcdBackup', {
          selection: this
        });
      }

    }
  });

  _exports.default = _default;
});