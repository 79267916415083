define("ui/components/volume-source/source-csi-volume-longhorn/component", ["exports", "ui/components/volume-source/source-csi-volume-longhorn/template", "shared/mixins/volume-source", "ui/utils/constants"], function (_exports, _template, _volumeSource, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_volumeSource.default, {
    layout: _template.default,
    field: 'csi',

    init() {
      this._super(...arguments);

      const {
        config: {
          driver,
          volumeAttributes
        }
      } = this;

      if (!driver) {
        Ember.set(this, 'config.driver', _constants.default.STORAGE.LONGHORN_PROVISIONER_KEY);
      }

      if (!volumeAttributes) {
        Ember.set(this, 'config.volumeAttributes', {
          size: '2Gi',
          numberOfReplicas: '3',
          staleReplicaTimeout: '20',
          fromBackup: ''
        });
      }
    }

  });

  _exports.default = _default;
});