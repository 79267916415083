define("ui/components/cru-config-map/component", ["exports", "shared/mixins/view-new-edit", "shared/mixins/optionally-namespaced", "ui/components/cru-config-map/template"], function (_exports, _viewNewEdit, _optionallyNamespaced, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_viewNewEdit.default, _optionallyNamespaced.default, {
    layout: _template.default,
    model: null,
    namespace: null,
    titleKey: 'newConfigMap.title',
    scope: 'namespace',
    namespacedType: 'configMap',
    keyValueLabel: 'newConfigMap.values.label',
    isView: Ember.computed.equal('mode', _viewNewEdit.VIEW),
    isNew: Ember.computed.equal('mode', _viewNewEdit.NEW),
    isEdit: Ember.computed.equal('mode', _viewNewEdit.EDIT),
    notView: Ember.computed.or('isNew', 'isEdit'),

    init() {
      this._super(...arguments);

      const {
        primaryResource: {
          namespace,
          binaryData
        }
      } = this;

      if (namespace) {
        Ember.set(this, 'namespace', namespace);
      }

      if (binaryData) {
        Ember.set(this, 'keyValueLabel', 'newConfigMap.values.binaryLabel');
      }
    },

    actions: {
      updateData(map) {
        Ember.set(this, 'primaryResource.data', map);
      }

    },

    willSave() {
      let pr = Ember.get(this, 'primaryResource'); // Namespace is required, but doesn't exist yet... so lie to the validator

      let nsId = Ember.get(pr, 'namespaceId');
      Ember.set(pr, 'namespaceId', '__TEMP__');
      let ok = this.validate();
      Ember.set(pr, 'namespaceId', nsId);
      return ok;
    },

    validate() {
      this._super();

      const errors = Ember.get(this, 'errors') || [];
      errors.pushObjects(Ember.get(this, 'namespaceErrors') || []);
      Ember.set(this, 'errors', errors);
      return errors.length === 0;
    },

    doSave() {
      let self = this;
      let sup = self._super;
      return this.namespacePromise().then(() => sup.apply(self, arguments));
    },

    doneSaving() {
      if (this.done) {
        this.done();
      }
    }

  });

  _exports.default = _default;
});