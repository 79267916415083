define("ui/components/form-related-workloads/component", ["exports", "ui/components/form-related-workloads/template", "ui/containers/index/controller"], function (_exports, _template, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['accordion-wrapper'],
    headers: _controller.headers,
    expandedInstances: null,
    expandOnInit: false,

    init() {
      this._super(...arguments);

      Ember.set(this, 'expandedInstances', []);
    },

    actions: {
      toggleExpand(instId) {
        let list = this.get('expandedInstances');

        if (list.includes(instId)) {
          list.removeObject(instId);
        } else {
          list.addObject(instId);
        }
      }

    }
  });

  _exports.default = _default;
});