define("ui/authenticated/cluster/cluster-catalogs/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    catalog: Ember.inject.service(),

    model() {
      return Ember.RSVP.hash({
        clusterCatalogs: Ember.get(this, 'catalog').fetchCatalogs('clusterCatalog'),
        globalCatalogs: Ember.get(this, 'catalog').fetchCatalogs()
      });
    }

  });

  _exports.default = _default;
});