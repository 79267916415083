define("ui/components/notifier/modal-new-edit/component", ["exports", "ui/mixins/modal-base", "ui/components/notifier/modal-new-edit/template", "ui/mixins/new-or-edit", "ui/utils/constants"], function (_exports, _modalBase, _template, _newOrEdit, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TYPES = [{
    type: 'slack',
    label: 'notifierPage.notifierTypes.slack',
    css: 'slack',
    disabled: false
  }, {
    type: 'email',
    label: 'notifierPage.notifierTypes.email',
    css: 'email',
    disabled: false
  }, {
    type: 'pagerduty',
    label: 'notifierPage.notifierTypes.pagerduty',
    css: 'pagerduty',
    disabled: false
  }, {
    type: 'webhook',
    label: 'notifierPage.notifierTypes.webhook',
    css: 'webhook',
    disabled: false
  }, {
    type: 'wechat',
    label: 'notifierPage.notifierTypes.wechat',
    css: 'wechat',
    disabled: false
  }, {
    type: 'dingtalk',
    label: 'notifierPage.notifierTypes.dingtalk',
    css: 'dingtalk',
    disabled: false
  }, {
    type: 'msteams',
    label: 'notifierPage.notifierTypes.msteams',
    css: 'msteams',
    disabled: false
  }];
  const RECIPIENT_TYPES = [{
    label: 'notifierPage.wechat.recipientType.party',
    value: 'party'
  }, {
    label: 'notifierPage.wechat.recipientType.tag',
    value: 'tag'
  }, {
    label: 'notifierPage.wechat.recipientType.user',
    value: 'user'
  }];

  var _default = Ember.Component.extend(_modalBase.default, _newOrEdit.default, {
    scope: Ember.inject.service('scope'),
    globalStore: Ember.inject.service(),
    intl: Ember.inject.service(),
    layout: _template.default,
    classNames: ['generic', 'large-modal'],
    modelMap: null,
    errors: null,
    testing: false,
    testOk: true,
    recipientTypes: RECIPIENT_TYPES,
    cluster: Ember.computed.alias('scope.currentCluster'),
    currentType: Ember.computed.alias('modalService.modalOpts.currentType'),
    model: Ember.computed.alias('modalService.modalOpts.model'),
    mode: Ember.computed.reads('modalService.modalOpts.mode'),

    init(...args) {
      this._super(...args);

      const mode = Ember.get(this, 'mode');

      if (mode === 'edit' || mode === 'clone') {
        const t = Ember.get(this, 'currentType');
        this.set('types', TYPES.filterBy('type', t));
      } else if (mode === 'add') {
        Ember.set(this, 'modelMap', {});
        this.setModel(Ember.get(this, 'currentType'));
        this.set('types', TYPES);
      }
    },

    actions: {
      switchType(type) {
        this.set('currentType', type);
        this.setModel(type);
      },

      test() {
        if (Ember.get(this, 'testing') || Ember.get(this, 'tested')) {
          return Ember.RSVP.resolve();
        }

        const ok = this.validate();

        if (!ok) {
          return Ember.RSVP.resolve();
        }

        const data = Ember.get(this, 'model').serialize();
        const gs = Ember.get(this, 'globalStore');
        Ember.set(this, 'testing', true); // TODO: better way to do collection actions

        return gs.rawRequest({
          url: 'notifiers?action=send',
          method: 'POST',
          data
        }).then(() => {
          this.setProperties({
            testOk: true,
            tested: true,
            testing: false,
            errors: null
          });
          setTimeout(() => {
            this.setProperties({
              tested: false
            });
          }, 3000);
        }).catch(xhr => {
          this.setProperties({
            testOk: false,
            tested: true,
            testing: false,
            errors: [Ember.get(xhr, 'body.message') || Ember.get(xhr, 'body.code')]
          });
          setTimeout(() => {
            this.setProperties({
              tested: false
            });
          }, 3000);
        });
      }

    },
    currentTypeChanged: Ember.observer('currentType', function () {
      Ember.set(this, 'errors', null);
    }),
    addBtnLabel: Ember.computed('mode', function () {
      const mode = Ember.get(this, 'mode');

      if (mode === 'edit') {
        return 'generic.save';
      } else if (mode === 'clone') {
        return 'notifierPage.clone';
      } else {
        return 'generic.add';
      }
    }),
    isSelectType: Ember.computed('currentType', function () {
      const types = TYPES.map(t => t.type);
      return types.includes(Ember.get(this, 'currentType'));
    }),

    setModel(type) {
      const cachedModel = Ember.get(this, `modelMap.${type}`);
      const clusterId = Ember.get(this, 'cluster.id');
      const gs = Ember.get(this, 'globalStore');

      if (cachedModel) {
        Ember.set(this, 'model', cachedModel);
        return;
      }

      if (type === 'email') {
        type = 'smtp';
      }

      const configType = `${type}Config`;
      const opt = {
        type: 'notifier',
        name: null,
        description: null,
        clusterId,
        [configType]: gs.createRecord({
          type: configType
        })
      };
      const model = Ember.get(this, 'globalStore').createRecord(opt);
      Ember.set(this, 'model', model);
      Ember.set(this, `modelMap.${type}`, model);
    },

    doneSaving() {
      Ember.get(this, 'modalService').toggleModal();
    },

    validate() {
      this._super(...arguments);

      const errors = Ember.get(this, 'errors') || [];
      const intl = Ember.get(this, 'intl');
      const preError = '"Default Recipient" is required';
      const notifierType = Ember.get(this, 'model.notifierType');

      if (errors.includes(preError)) {
        let afterError = '';

        if (notifierType === 'email') {
          afterError = _constants.default.NOTIFIER_TABLE_LABEL.SMTP;
          errors.splice(errors.findIndex(e => e === preError), 1, intl.t('validation.required', {
            key: afterError
          }));
        }
      }

      Ember.set(this, 'errors', errors);
      return Ember.get(this, 'errors.length') === 0;
    }

  });

  _exports.default = _default;
});