define("ui/models/feature", ["exports", "@rancher/ember-api-store/models/resource"], function (_exports, _resource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Feature = _resource.default.extend({
    intl: Ember.inject.service(),
    type: 'feature',
    canRemove: false,
    canBulkRemove: false,
    displayState: Ember.computed('value', function () {
      return this.value ? this.intl.t('generic.active') : this.intl.t('generic.disabled');
    }),
    stateBackground: Ember.computed('value', function () {
      return this.value ? 'bg-success' : 'bg-muted';
    }),
    availableActions: Ember.computed('value', function () {
      return [{
        label: 'action.activate',
        icon: 'icon icon-play',
        action: 'activate',
        enabled: !this.value,
        bulkable: true
      }, {
        label: 'action.deactivate',
        icon: 'icon icon-stop',
        action: 'deactivate',
        enabled: this.value,
        bulkable: true
      }];
    }),
    actions: {
      activate() {
        Ember.set(this, 'value', true);
        return this.save().catch(err => {
          Ember.set(this, 'value', false);
          return err;
        });
      },

      deactivate() {
        Ember.set(this, 'value', false);
        return this.save().catch(err => {
          Ember.set(this, 'value', true);
          return err;
        });
      }

    }
  });

  var _default = Feature;
  _exports.default = _default;
});