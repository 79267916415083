define("ui/authenticated/project/hpa/new/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    clusterStore: Ember.inject.service(),

    model(params) {
      const store = Ember.get(this, 'store');
      const clusterStore = Ember.get(this, 'clusterStore');
      const deps = {
        deployments: store.findAll('workload').then(workloads => workloads.filter(w => w.type === 'statefulSet' || w.type === 'deployment')),
        apiServices: clusterStore.findAll('apiService')
      };

      if (Ember.get(params, 'id')) {
        deps['existing'] = store.find('horizontalpodautoscaler', params.id);
      }

      return Ember.RSVP.hash(deps, 'Load dependencies').then(hash => {
        let hpa;
        let namespaceId = params.namespaceId;
        let namespace;

        if (namespaceId) {
          namespace = store.getById('namespace', namespaceId);
        } // If the namespace doesn't exist or isn't set, pick default


        if (!namespace) {
          namespace = store.all('namespace').findBy('isDefault', true);

          if (namespace) {
            namespaceId = Ember.get(namespace, 'id');
          }
        }

        if (hash.existing) {
          hpa = hash.existing.cloneForNew();
          delete hash.existing;
        } else {
          hpa = store.createRecord({
            type: 'horizontalpodautoscaler',
            namespaceId,
            minReplicas: 1,
            maxReplicas: 10,
            metrics: []
          });
        }

        hash.hpa = hpa;
        return hash;
      });
    },

    resetController(controller, isExisting
    /* , transition*/
    ) {
      if (isExisting) {
        Ember.set(controller, 'namespaceId', null);
        Ember.set(controller, 'id', null);
      }
    }

  });

  _exports.default = _default;
});