define("ui/models/nodepool", ["exports", "@rancher/ember-api-store/models/resource", "@rancher/ember-api-store/utils/denormalize", "shared/utils/util"], function (_exports, _resource, _denormalize, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const NodePool = _resource.default.extend({
    type: 'nodePool',
    quantityTimer: null,
    nodeTemplate: (0, _denormalize.reference)('nodeTemplateId'),
    displayProvider: Ember.computed('driver', 'nodeTemplate.driver', 'intl.locale', function () {
      const intl = Ember.get(this, 'intl');
      const driver = Ember.get(this, 'driver');
      const key = `nodeDriver.displayName.${driver}`;

      if (intl.exists(key)) {
        return intl.t(key);
      } else {
        return (0, _util.ucFirst)(driver);
      }
    }),

    incrementQuantity(by) {
      let quantity = Ember.get(this, 'quantity');
      quantity += by;
      quantity = Math.max(0, quantity);
      Ember.set(this, 'quantity', quantity);

      if (Ember.get(this, 'quantityTimer')) {
        Ember.run.cancel(Ember.get(this, 'quantityTimer'));
      }

      var timer = Ember.run.later(this, function () {
        this.save().catch(err => {
          Ember.get(this, 'growl').fromError('Error updating node pool scale', err);
        });
      }, 500);
      Ember.set(this, 'quantityTimer', timer);
    }

  });

  NodePool.reopenClass({
    mangleOut(data) {
      if (data && data.hostnamePrefix) {
        data.hostnamePrefix = data.hostnamePrefix.toLowerCase();
      }

      return data;
    }

  });
  var _default = NodePool;
  _exports.default = _default;
});