define("ui/models/roletemplate", ["exports", "@rancher/ember-api-store/models/resource", "ui/utils/constants"], function (_exports, _resource, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resource.default.extend({
    router: Ember.inject.service(),
    roleTemplateService: Ember.inject.service('roleTemplate'),
    growl: Ember.inject.service(),
    type: 'roleTemplate',
    canClone: true,
    state: Ember.computed('locked', function () {
      return Ember.get(this, 'locked') ? 'locked' : 'active';
    }),
    isCustom: Ember.computed('id', 'roleTemplateId', function () {
      return !_constants.default.BASIC_ROLE_TEMPLATE_ROLES.includes(Ember.get(this, 'id'));
    }),
    displayName: Ember.computed('name', 'id', function () {
      let name = Ember.get(this, 'name');

      if (name) {
        return name;
      }

      return `(${Ember.get(this, 'id')})`;
    }),
    canRemove: Ember.computed('links.remove', 'builtin', function () {
      return !!Ember.get(this, 'links.remove') && !Ember.get(this, 'builtin');
    }),
    actions: {
      edit() {
        Ember.get(this, 'router').transitionTo('global-admin.security.roles.edit', Ember.get(this, 'id'));
      },

      clone() {
        Ember.get(this, 'router').transitionTo('global-admin.security.roles.new', {
          queryParams: {
            id: Ember.get(this, 'id'),
            context: Ember.get(this, 'context')
          }
        });
      }

    },

    delete() {
      const self = this;
      const sup = self._super;
      const roleTemplateService = Ember.get(this, 'roleTemplateService');
      let canDelete = true;
      const roleNames = [];
      return roleTemplateService.fetchFilteredRoleTemplates().then(res => {
        const roleTemplates = res.filter(r => r.canRemove);
        roleTemplates.map(r => {
          const {
            roleTemplateIds = []
          } = r;
          (roleTemplateIds || []).map(id => {
            if (id === this.id) {
              canDelete = false;
              roleNames.pushObject(r.name);
            }
          });
        });

        if (canDelete) {
          return sup.apply(self, arguments);
        } else {
          return Ember.get(this, 'growl').error(Ember.get(this, 'intl').t('rolesPage.index.errors.inherited', {
            displayName: Ember.get(this, 'displayName'),
            roleNames: roleNames.join(',')
          }));
        }
      });
    }

  });

  _exports.default = _default;
});