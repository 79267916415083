define("ui/components/workload-row/component", ["exports", "ui/components/workload-row/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    scope: Ember.inject.service(),
    session: Ember.inject.service(),
    layout: _template.default,
    model: null,
    tagName: '',
    subMatches: null,
    expanded: null,
    canExpand: true,
    showInstanceCount: true,
    showImage: true,
    showLabelRow: Ember.computed.readOnly('model.displayUserLabelStrings.length'),
    actions: {
      toggle() {
        if (this.toggle) {
          this.toggle(this.model.id);
        }
      }

    },
    podCount: Ember.computed('model.pods.[]', function () {
      const {
        pods = []
      } = this.model;
      return pods.length;
    })
  });

  _exports.default = _default;
});