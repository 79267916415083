define("ui/ingresses/index/route", ["exports", "ui/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      const store = this.get('store');
      return Ember.RSVP.hash({
        ingresses: store.findAll('ingress'),
        services: store.findAll('service')
      });
    },

    setDefaultRoute: Ember.on('activate', function () {
      Ember.set(this, `session.${_constants.default.SESSION.CONTAINER_ROUTE}`, 'ingresses');
      Ember.set(this, `session.${_constants.default.SESSION.PROJECT_ROUTE}`, undefined);
    })
  });

  _exports.default = _default;
});