define("ui/models/catalogtemplate", ["exports", "@rancher/ember-api-store/models/resource", "ui/utils/parse-externalid", "ui/utils/constants"], function (_exports, _resource, _parseExternalid, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resource.default.extend({
    catalog: Ember.inject.service(),
    type: 'catalogTemplate',
    externalId: Ember.computed('templateVersionId', 'templateId', function () {
      let id = this.get('templateVersionId') || this.get('templateId');

      if (id) {
        return _constants.default.EXTERNAL_ID.KIND_CATALOG + _constants.default.EXTERNAL_ID.KIND_SEPARATOR + id;
      }

      return '';
    }),
    externalIdInfo: Ember.computed('externalId', function () {
      return (0, _parseExternalid.parseExternalId)(this.get('externalId'));
    }),
    // These only works if the templates have already been loaded elsewhere...
    catalogTemplate: Ember.computed('externalIdInfo.templateId', function () {
      return this.get('catalog').getTemplateFromCache(this.get('externalIdInfo.templateId'));
    }),
    icon: Ember.computed('catalogTemplate', function () {
      let tpl = this.get('catalogTemplate');

      if (tpl) {
        return tpl.linkFor('icon');
      }

      return '';
    }),
    categories: Ember.computed('catalogTemplate.categories', function () {
      let tpl = this.get('catalogTemplate');

      if (tpl) {
        return tpl.get('categories') || [];
      }

      return [];
    })
  });

  _exports.default = _default;
});