define("ui/authenticated/project/security/members/index/route", ["exports", "ui/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),

    model() {
      const pid = this.paramsFor('authenticated.project');
      const pm = this.modelFor('authenticated.project');
      this.controllerFor('authenticated.project.security.members.index').set('projectId', pid.project_id); // TODO 2.0 bug here with projectId on a PRTB where API mungs it up be removing the cluster id on the projectid

      return Ember.get(pm, 'project');
    },

    setDefaultRoute: Ember.on('activate', function () {
      Ember.set(this, `session.${_constants.default.SESSION.PROJECT_ROUTE}`, 'authenticated.project.security.members.index');
    })
  });

  _exports.default = _default;
});