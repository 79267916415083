define("ui/components/modal-rotate-certificates/component", ["exports", "shared/mixins/modal-base", "ui/components/modal-rotate-certificates/template", "moment"], function (_exports, _modalBase, _template, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modalBase.default, {
    growl: Ember.inject.service(),
    intl: Ember.inject.service(),
    layout: _template.default,
    classNames: ['large-modal'],
    rotateCaCerts: false,
    services: null,
    selectedServices: null,
    mode: 'single',
    model: Ember.computed.alias('modalService.modalOpts.model'),

    init() {
      this._super(...arguments);

      Ember.setProperties(this, {
        services: [],
        errors: []
      });
    },

    didReceiveAttrs() {
      if (this.model.certsExpiring) {
        const {
          expiringCerts
        } = this.model;
        let etcdNodes = (expiringCerts || []).filter(cert => cert.expiringCertName.includes('etcd'));
        let certLabel = null;
        Ember.set(this, 'services', this.modalOpts.serviceDefaults.map(cert => {
          let expiringCert = null;
          let expiresIn = null;

          if (cert === 'kubelet') {
            expiringCert = expiringCerts.findBy('expiringCertName', 'kube-node');
          } else if (cert === 'etcd' && etcdNodes.length > 0) {
            // there can be multiple etcd nodes with different cert expires, we can grab and alert the soonest expiring cert date since 'rofateCertificates' action will rotates all etcd node certs at the same time.
            expiringCert = etcdNodes.sortBy('milliUntil').get('firstObject');
          } else {
            expiringCert = expiringCerts.findBy('expiringCertName', cert);
          }

          if (expiringCert && !Ember.isEmpty(expiringCert.exactDateTime)) {
            expiresIn = expiringCert.exactDateTime;

            if (expiringCert.milliUntil > 0) {
              certLabel = this.intl.t('modalRotateCertificates.expiring.until', {
                cert,
                expiresIn: (0, _moment.default)(expiresIn).fromNow()
              });
            } else {
              certLabel = this.intl.t('modalRotateCertificates.expiring.from', {
                cert,
                expiresIn: (0, _moment.default)(expiresIn).fromNow()
              });
            }
          } else {
            certLabel = `${cert}`;
          }

          return {
            label: certLabel,
            value: cert
          };
        }));
      } else {
        Ember.set(this, 'services', this.modalOpts.serviceDefaults.map(serv => ({
          label: serv,
          value: serv
        })));
      }
    },

    actions: {
      rotateCaCerts(cb) {
        const resource = this.modalOpts.model;
        const params = this.getRotateCertsParams();
        resource.doAction('rotateCertificates', params).then(() => {
          this.send('cancel');
        }).catch(err => {
          this.growl.fromError(err);

          if (cb) {
            cb(false);
          }
        });
      },

      mutServices(select) {
        Ember.set(this, 'selectedServices', select);
      }

    },

    getRotateCertsParams() {
      switch (this.mode) {
        case 'caAndService':
          return {
            services: '',
            caCertificates: true
          };

        case 'single':
          return {
            services: Ember.get(this, 'selectedServices'),
            caCertificates: false
          };

        case 'service':
          return {
            services: null,
            caCertificates: false
          };

        default:
          return;
      }
    }

  });

  _exports.default = _default;
});