define("ui/signup/controller", ["exports", "@rancher/ember-api-store/utils/fetch"], function (_exports, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    settings: Ember.inject.service(),
    emailSent: false,
    saving: false,
    saveDisabled: true,
    actions: {
      register() {
        this.set('saving', true);
        (0, _fetch.default)('/register-new', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(this.get('model'))
        }).then(() => {
          this.set('saving', false);
          this.set('emailSent', true);
        }).catch(err => {
          if (err.status === 409) {
            this.set('showReset', true);
          }

          this.set('saving', false);
          this.set('errors', [err.body.detail]);
        });
      },

      cancel() {
        if (this.get('errors')) {
          this.set('errors', []);
        }

        this.transitionToRoute('login');
      }

    },
    validate: Ember.observer('model.name', 'model.email', function () {
      if (this.get('model.name') && this.get('model.email')) {
        if (this.get('errors')) {
          this.set('errors', []);
        }

        this.set('saveDisabled', false);
      } else {
        this.set('saveDisabled', true);
      }
    })
  });

  _exports.default = _default;
});