define("ui/update-critical-settings/route", ["exports", "ui/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    access: Ember.inject.service(),
    settings: Ember.inject.service(),
    globalStore: Ember.inject.service(),

    model() {
      let globalStore = Ember.get(this, 'globalStore');
      return globalStore.find('setting', _constants.default.SETTING.SERVER_URL).then(serverUrl => ({
        serverUrl: Ember.get(serverUrl, 'value') || window.location.host,
        serverUrlSetting: serverUrl
      }));
    },

    activate() {
      $('BODY').addClass('container-farm'); // eslint-disable-line
    },

    deactivate() {
      $('BODY').removeClass('container-farm'); // eslint-disable-line
    }

  });

  _exports.default = _default;
});