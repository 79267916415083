define("ui/components/modal-rotate-encryption-key/component", ["exports", "shared/mixins/modal-base", "ui/components/modal-rotate-encryption-key/template", "moment"], function (_exports, _modalBase, _template, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modalBase.default, {
    growl: Ember.inject.service(),
    layout: _template.default,
    classNames: ['large-modal'],
    etcdBackups: Ember.computed.alias('modalOpts.model.etcdbackups'),
    cluster: Ember.computed.alias('modalOpts.model'),
    actions: {
      async rotate(cb) {
        const {
          cluster
        } = this;

        try {
          await cluster.doAction('rotateEncryptionKey');
          cb(true);
          this.send('cancel');
        } catch (error) {
          cb(false);
        }
      }

    },
    lastBackup: Ember.computed('etcdBackups.@each.created', function () {
      const {
        etcdBackups
      } = this;
      const latest = Ember.get(etcdBackups, 'firstObject');

      if (!latest) {
        return null;
      }

      const backupName = Ember.get(latest, 'displayName');
      const created = (0, _moment.default)(Ember.get(latest, 'created'));
      const backupTime = created ? created.format('MMMM Do YYYY, HH:mm:ss') : 'N/A';
      return {
        backupName,
        backupTime
      };
    })
  });

  _exports.default = _default;
});