define("ui/components/project-resource-quota/component", ["exports", "shared/utils/util", "shared/utils/parse-unit", "ui/components/project-resource-quota/template"], function (_exports, _util, _parseUnit, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    limit: null,
    nsDefaultLimit: null,
    editing: null,
    quotaArray: null,

    init() {
      this._super(...arguments);

      this.initQuotaArray();
    },

    actions: {
      addQuota() {
        Ember.get(this, 'quotaArray').pushObject({
          key: '',
          projectLimit: '',
          namespaceLimit: ''
        });
      },

      removeQuota(quota) {
        Ember.get(this, 'quotaArray').removeObject(quota);
      }

    },
    quotaDidChange: Ember.observer('quotaArray.@each.{key,projectLimit,namespaceLimit}', function () {
      const limit = {};
      const nsDefaultLimit = {};
      (Ember.get(this, 'quotaArray') || []).forEach(quota => {
        if (quota.key && (quota.projectLimit || quota.namespaceLimit)) {
          limit[quota.key] = this.convertToString(quota.key, quota.projectLimit);
          nsDefaultLimit[quota.key] = this.convertToString(quota.key, quota.namespaceLimit);
        }
      });
      let out = null;

      if (Object.keys(limit).length) {
        out = {
          resourceQuota: {
            limit
          },
          namespaceDefaultResourceQuota: {
            limit: nsDefaultLimit
          }
        };
      }

      if (this.changed) {
        this.changed(out);
      }
    }),

    convertToString(key, value) {
      if (!value) {
        return '';
      }

      switch (key) {
        case 'limitsCpu':
        case 'requestsCpu':
          return `${value}m`;

        case 'limitsMemory':
        case 'requestsMemory':
          return `${value}Mi`;

        case 'requestsStorage':
          return `${value}Gi`;

        default:
          return value;
      }
    },

    convertToLimit(key, value) {
      if (!value) {
        return '';
      }

      switch (key) {
        case 'limitsCpu':
        case 'requestsCpu':
          return (0, _util.convertToMillis)(value);

        case 'limitsMemory':
        case 'requestsMemory':
          return (0, _parseUnit.parseSi)(value, 1024) / 1048576;

        case 'requestsStorage':
          return (0, _parseUnit.parseSi)(value) / 1024 ** 3;

        default:
          return value;
      }
    },

    initQuotaArray() {
      const limit = Ember.get(this, 'limit') || {};
      const nsDefaultLimit = Ember.get(this, 'nsDefaultLimit') || {};
      const array = [];
      Object.keys(limit).forEach(key => {
        if (key !== 'type' && typeof limit[key] === 'string') {
          const projectLimit = this.convertToLimit(key, limit[key]);
          const namespaceLimit = this.convertToLimit(key, nsDefaultLimit[key]);
          array.push({
            key,
            projectLimit,
            namespaceLimit
          });
        }
      });
      Ember.set(this, 'quotaArray', array);
    }

  });

  _exports.default = _default;
});