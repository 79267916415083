define("ui/utils/azure-choices", ["exports", "shared/utils/azure-choices"], function (_exports, _azureChoices) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "regions", {
    enumerable: true,
    get: function () {
      return _azureChoices.regions;
    }
  });
  Object.defineProperty(_exports, "aksRegions", {
    enumerable: true,
    get: function () {
      return _azureChoices.aksRegions;
    }
  });
  Object.defineProperty(_exports, "sizes", {
    enumerable: true,
    get: function () {
      return _azureChoices.sizes;
    }
  });
  Object.defineProperty(_exports, "storageTypes", {
    enumerable: true,
    get: function () {
      return _azureChoices.storageTypes;
    }
  });
  Object.defineProperty(_exports, "environments", {
    enumerable: true,
    get: function () {
      return _azureChoices.environments;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _azureChoices.default;
    }
  });
});