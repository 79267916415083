define("ui/apps-tab/detail/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    catalog: Ember.inject.service(),
    store: Ember.inject.service(),

    model(params) {
      const store = Ember.get(this, 'store');
      return Ember.RSVP.hash({
        app: store.find('app', Ember.get(params, 'app_id'))
      });
    }

  });

  _exports.default = _default;
});