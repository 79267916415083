define("ui/components/settings/billing-info/component", ["exports", "ui/components/settings/billing-info/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    modalService: Ember.inject.service('modal'),
    layout: _template.default,
    classNames: ['billing-info', 'box'],
    cards: null,
    account: null,
    actions: {
      addNew() {
        this.get('modalService').toggleModal('modal-add-payment', this.get('account'));
      },

      remove(card) {
        this.get('modalService').toggleModal('modal-confirm-remove-payment', {
          card,
          account: this.get('account')
        });
      }

    }
  });

  _exports.default = _default;
});