define("ui/components/settings/table-rows/component", ["exports", "ui/utils/constants", "ui/components/settings/table-rows/template"], function (_exports, _constants, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TABLE_COUNTS = [{
    value: '10'
  }, {
    value: '25'
  }, {
    value: '50'
  }, {
    value: '100'
  }, {
    value: '250'
  }, {
    value: '500'
  }, {
    value: '1000'
  }];

  var _default = Ember.Component.extend({
    prefs: Ember.inject.service(),
    layout: _template.default,
    tableCounts: TABLE_COUNTS,
    selectedCount: null,
    perPage: Ember.computed.alias('prefs.tablePerPage'),

    init() {
      this._super(...arguments);

      this.set('selectedCount', `${this.get('perPage')}`);
    },

    countChanged: Ember.observer('selectedCount', function () {
      this.set(`prefs.${_constants.default.PREFS.TABLE_COUNT}`, parseInt(this.get('selectedCount'), 10));
    })
  });

  _exports.default = _default;
});