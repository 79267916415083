define("ui/models/globaldns", ["exports", "@rancher/ember-api-store/models/resource", "@rancher/ember-api-store/utils/denormalize"], function (_exports, _resource, _denormalize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resource.default.extend({
    router: Ember.inject.service(),
    scope: Ember.inject.service(),
    multiClusterApp: (0, _denormalize.reference)('multiClusterAppId', 'multiClusterApp'),
    target: Ember.computed('multiClusterAppId', 'projectIds.[]', function () {
      // returns either a single multiClusterAppId or an array of project target ids
      const out = {
        type: null,
        data: null
      };
      const multiClusterAppId = Ember.get(this, 'multiClusterAppId');
      const projectIds = Ember.get(this, 'projectIds');

      if (multiClusterAppId && !projectIds) {
        Ember.setProperties(out, {
          type: 'single',
          data: multiClusterAppId
        });
      } else if (projectIds && projectIds.length && !multiClusterAppId) {
        Ember.setProperties(out, {
          type: 'multi',
          data: projectIds
        });
      }

      return out;
    }),
    linkedProjects: Ember.computed('projectIds.[]', 'scope.allProjects.@each.id', function () {
      const allProjects = Ember.get(this, 'scope.allProjects') || [];
      const projectIds = Ember.get(this, 'projectIds') || [];
      const myProjects = [];
      projectIds.forEach(projectId => {
        let match = allProjects.findBy('id', projectId);

        if (match) {
          Ember.run.next(() => {
            Ember.set(match, 'accessible', true);
            myProjects.pushObject(match);
          });
        } else {
          Ember.run.next(() => {
            myProjects.pushObject({
              id: projectId,
              accessible: false
            });
          });
        }
      });
      return myProjects;
    }),
    canEdit: Ember.computed('links.update', function () {
      return !!Ember.get(this, 'links.update');
    }),
    canRemove: Ember.computed('links.remove', function () {
      return !!Ember.get(this, 'links.remove');
    }),
    actions: {
      edit() {
        this.router.transitionTo('global-admin.global-dns.entries.new', {
          queryParams: {
            id: this.id
          }
        });
      }

    }
  });

  _exports.default = _default;
});