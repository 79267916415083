define("ui/utils/util", ["exports", "shared/utils/util"], function (_exports, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _util.default;
    }
  });
  Object.defineProperty(_exports, "arrayDiff", {
    enumerable: true,
    get: function () {
      return _util.arrayDiff;
    }
  });
  Object.defineProperty(_exports, "arrayIntersect", {
    enumerable: true,
    get: function () {
      return _util.arrayIntersect;
    }
  });
  Object.defineProperty(_exports, "filterByValues", {
    enumerable: true,
    get: function () {
      return _util.filterByValues;
    }
  });
  Object.defineProperty(_exports, "deepCopy", {
    enumerable: true,
    get: function () {
      return _util.deepCopy;
    }
  });
  Object.defineProperty(_exports, "download", {
    enumerable: true,
    get: function () {
      return _util.download;
    }
  });
  Object.defineProperty(_exports, "popupWindowOptions", {
    enumerable: true,
    get: function () {
      return _util.popupWindowOptions;
    }
  });
  Object.defineProperty(_exports, "escapeHtml", {
    enumerable: true,
    get: function () {
      return _util.escapeHtml;
    }
  });
  Object.defineProperty(_exports, "escapeRegex", {
    enumerable: true,
    get: function () {
      return _util.escapeRegex;
    }
  });
  Object.defineProperty(_exports, "addQueryParams", {
    enumerable: true,
    get: function () {
      return _util.addQueryParams;
    }
  });
  Object.defineProperty(_exports, "parseUrl", {
    enumerable: true,
    get: function () {
      return _util.parseUrl;
    }
  });
  Object.defineProperty(_exports, "absoluteUrl", {
    enumerable: true,
    get: function () {
      return _util.absoluteUrl;
    }
  });
  Object.defineProperty(_exports, "addAuthorization", {
    enumerable: true,
    get: function () {
      return _util.addAuthorization;
    }
  });
  Object.defineProperty(_exports, "ucFirst", {
    enumerable: true,
    get: function () {
      return _util.ucFirst;
    }
  });
  Object.defineProperty(_exports, "roundValue", {
    enumerable: true,
    get: function () {
      return _util.roundValue;
    }
  });
  Object.defineProperty(_exports, "strPad", {
    enumerable: true,
    get: function () {
      return _util.strPad;
    }
  });
  Object.defineProperty(_exports, "sortableNumericSuffix", {
    enumerable: true,
    get: function () {
      return _util.sortableNumericSuffix;
    }
  });
  Object.defineProperty(_exports, "timerFuzz", {
    enumerable: true,
    get: function () {
      return _util.timerFuzz;
    }
  });
  Object.defineProperty(_exports, "random32", {
    enumerable: true,
    get: function () {
      return _util.random32;
    }
  });
  Object.defineProperty(_exports, "randomStr", {
    enumerable: true,
    get: function () {
      return _util.randomStr;
    }
  });
  Object.defineProperty(_exports, "formatPercent", {
    enumerable: true,
    get: function () {
      return _util.formatPercent;
    }
  });
  Object.defineProperty(_exports, "formatMib", {
    enumerable: true,
    get: function () {
      return _util.formatMib;
    }
  });
  Object.defineProperty(_exports, "formatSecond", {
    enumerable: true,
    get: function () {
      return _util.formatSecond;
    }
  });
  Object.defineProperty(_exports, "formatKbps", {
    enumerable: true,
    get: function () {
      return _util.formatKbps;
    }
  });
  Object.defineProperty(_exports, "formatGB", {
    enumerable: true,
    get: function () {
      return _util.formatGB;
    }
  });
  Object.defineProperty(_exports, "constructUrl", {
    enumerable: true,
    get: function () {
      return _util.constructUrl;
    }
  });
  Object.defineProperty(_exports, "pluralize", {
    enumerable: true,
    get: function () {
      return _util.pluralize;
    }
  });
  Object.defineProperty(_exports, "uniqKeys", {
    enumerable: true,
    get: function () {
      return _util.uniqKeys;
    }
  });
  Object.defineProperty(_exports, "camelToTitle", {
    enumerable: true,
    get: function () {
      return _util.camelToTitle;
    }
  });
  Object.defineProperty(_exports, "isNumeric", {
    enumerable: true,
    get: function () {
      return _util.isNumeric;
    }
  });
  Object.defineProperty(_exports, "requiredError", {
    enumerable: true,
    get: function () {
      return _util.requiredError;
    }
  });
  Object.defineProperty(_exports, "parseCamelcase", {
    enumerable: true,
    get: function () {
      return _util.parseCamelcase;
    }
  });
  Object.defineProperty(_exports, "extractUniqueStrings", {
    enumerable: true,
    get: function () {
      return _util.extractUniqueStrings;
    }
  });
});