define("ui/initializers/extend-resource", ["exports", "@rancher/ember-api-store/models/resource", "ui/mixins/cattle-transitioning-resource"], function (_exports, _resource, _cattleTransitioningResource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize()
  /* application */
  {
    _resource.default.reopen(_cattleTransitioningResource.default);

    _resource.default.reopenClass({
      defaultStateIcon: 'icon icon-help',
      defaultStateColor: 'text-primary',
      defaultSortBy: 'name'
    });
  }

  var _default = {
    name: 'extend-resource',
    initialize
  };
  _exports.default = _default;
});