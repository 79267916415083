define("ui/models/globalrolebinding", ["exports", "@rancher/ember-api-store/models/resource", "@rancher/ember-api-store/utils/denormalize"], function (_exports, _resource, _denormalize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resource.default.extend({
    router: Ember.inject.service(),
    canRemove: false,
    globalRole: (0, _denormalize.reference)('globalRoleId', 'globalRole'),
    canUpdate: Ember.computed('links.update', 'groupPrincipalId', function () {
      const {
        groupPrincipalId,
        links
      } = this;

      if (!Ember.isEmpty(groupPrincipalId) && !Ember.isEmpty(links) && !Ember.isEmpty(links.update)) {
        return true;
      }

      return false;
    }),
    availableActions: Ember.computed('canUpdate', 'links.remove', function () {
      let out = [{
        label: 'action.edit',
        icon: 'icon icon-edit',
        action: 'update',
        enabled: this.canUpdate
      }];
      return out;
    }),
    actions: {
      update() {
        this.router.transitionTo('global-admin.security.accounts.edit-group', this.id);
      }

    }
  });

  _exports.default = _default;
});