define("ui/components/namespace-list/component", ["exports", "ui/components/namespace-list/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.headers = void 0;
  const headers = [{
    name: 'state',
    sort: ['sortState', 'displayName'],
    searchField: 'displayState',
    translationKey: 'generic.state',
    width: 120
  }, {
    name: 'name',
    sort: ['sortName', 'id'],
    searchField: 'displayName',
    translationKey: 'namespacesPage.table.name.label'
  }, {
    name: 'project',
    sort: ['project.sortName', 'id'],
    searchField: 'project.displayName',
    translationKey: 'namespacesPage.table.project.label'
  }, {
    classNames: 'text-right pr-20',
    name: 'created',
    sort: ['created', 'id'],
    searchField: false,
    translationKey: 'namespacesPage.table.created.label',
    width: 250
  }];
  _exports.headers = headers;

  var _default = Ember.Component.extend({
    scope: Ember.inject.service(),
    layout: _template.default,
    sortBy: 'name',
    descending: false,
    headers,
    extraSearchFields: ['displayUserLabelStrings'],
    rows: Ember.computed.alias('model')
  });

  _exports.default = _default;
});