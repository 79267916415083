define("ui/initializers/app", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    application.inject('service:app', 'app', 'application:main'); // inject the config into the app service to make this solution turnkey

    application.inject('controller', 'app', 'service:app');
    application.inject('route', 'app', 'service:app');
    application.inject('view', 'app', 'service:app');
    application.inject('component', 'app', 'service:app');
    application.inject('model', 'app', 'service:app');
    application.inject('component', 'shortcuts', 'shortcuts:main');
  }

  var _default = {
    name: 'app',
    initialize
  };
  _exports.default = _default;
});