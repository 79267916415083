define("ui/authenticated/project/certificates/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    projectController: Ember.inject.controller('authenticated.project'),
    sortBy: 'name',
    headers: [{
      name: 'state',
      sort: ['sortState', 'name', 'id'],
      translationKey: 'generic.state',
      width: 125
    }, {
      name: 'name',
      sort: ['name', 'id'],
      translationKey: 'generic.name'
    }, {
      name: 'cn',
      searchField: ['cn'],
      sort: ['cn', 'id'],
      translationKey: 'certificatesPage.domainNames.labelText'
    }, {
      name: 'expires',
      sort: ['expiresDate', 'id'],
      translationKey: 'certificatesPage.expires',
      width: 120
    }],
    group: Ember.computed.alias('projectController.group'),
    groupTableBy: Ember.computed.alias('projectController.groupTableBy'),
    rows: Ember.computed('model.projectCerts.[]', 'model.namespacedCerts.[]', function () {
      const proj = Ember.get(this, 'model.projectCerts').slice();
      const ns = Ember.get(this, 'model.namespacedCerts').slice();
      const out = proj.concat(ns);
      return out;
    })
  });

  _exports.default = _default;
});