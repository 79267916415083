define("ui/components/settings/telemetry-opt/component", ["exports", "ui/utils/constants", "ui/components/settings/telemetry-opt/template"], function (_exports, _constants, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const IN = 'in';
  const OUT = 'out';

  var _default = Ember.Component.extend({
    settings: Ember.inject.service(),
    layout: _template.default,
    initialValue: null,
    optIn: null,

    init() {
      this._super(...arguments);

      let val = false;

      if (this.get('initialValue') === IN) {
        val = true;
      }

      this.set('optIn', val);
    },

    actions: {
      save(btnCb) {
        this.get('settings').set(_constants.default.SETTING.TELEMETRY, this.get('optIn') ? IN : OUT);
        this.get('settings').one('settingsPromisesResolved', () => {
          btnCb(true);

          if (this.saved) {
            this.saved();
          }
        });
      }

    }
  });

  _exports.default = _default;
});