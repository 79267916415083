define("ui/components/node-row/component", ["exports", "ui/components/node-row/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.headersGlobal = _exports.headersCluster = _exports.headersAll = void 0;
  const headersAll = [{
    name: 'state',
    sort: ['sortState', 'displayName'],
    searchField: 'displayState',
    translationKey: 'generic.state',
    width: 120
  }, {
    name: 'name',
    sort: ['sortName', 'id'],
    searchField: 'displayName',
    translationKey: 'generic.name'
  }, {
    name: 'cluster',
    sort: ['cluster.displayName', 'name', 'id'],
    searchField: 'cluster.displayName',
    translationKey: 'nodesPage.table.clusterName',
    views: ['global']
  }, {
    name: 'roles',
    sort: ['sortRole', 'name', 'id'],
    searchField: 'displayRoles',
    translationKey: 'nodesPage.table.role',
    views: ['cluster'],
    width: 120
  }, {
    name: 'version',
    sort: ['name', 'id'],
    searchField: null,
    translationKey: 'nodesPage.table.version',
    views: ['cluster'],
    width: 120
  }, {
    name: 'cpu',
    sort: ['cpuUsageCount', 'displayName'],
    searchField: null,
    width: 100,
    translationKey: 'nodesPage.table.cpu',
    classNames: ['text-right']
  }, {
    name: 'memory',
    sort: ['memoryUsageCount', 'displayName'],
    searchField: null,
    width: 150,
    translationKey: 'nodesPage.table.memory',
    classNames: ['text-right']
  }, {
    name: 'pod',
    sort: ['podUsageCount', 'displayName'],
    searchField: null,
    width: 100,
    translationKey: 'nodesPage.table.pod',
    classNames: ['text-right']
  }];
  _exports.headersAll = headersAll;
  const headersMap = {
    all: headersAll,
    global: headersAll.filter(x => !x.views || x.views.includes('global')),
    cluster: headersAll.filter(x => !x.views || x.views.includes('cluster'))
  };
  const headersCluster = headersMap.cluster;
  _exports.headersCluster = headersCluster;
  const headersGlobal = headersMap.global;
  _exports.headersGlobal = headersGlobal;

  var _default = Ember.Component.extend({
    scope: Ember.inject.service(),
    session: Ember.inject.service(),
    layout: _template.default,
    view: 'project',
    model: null,
    tagName: '',
    subMatches: null,
    expanded: null,
    showLabelRow: Ember.computed.or('model.displayUserLabelStrings.length', 'model.requireAnyLabelStrings.length'),
    actions: {
      toggle() {
        if (this.toggle) {
          this.toggle();
        }
      }

    },
    showCluster: Ember.computed('view', function () {
      return !!headersMap[Ember.get(this, 'view')].findBy('name', 'cluster');
    }),
    showRoles: Ember.computed('view', function () {
      return !!headersMap[Ember.get(this, 'view')].findBy('name', 'roles');
    }),
    labelColspan: Ember.computed('fullColspan', function () {
      return Ember.get(this, 'fullColspan') + 1;
    })
  });

  _exports.default = _default;
});