define("ui/fail-whale/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    storeReset: Ember.inject.service(),
    settings: Ember.inject.service(),

    model() {
      return this.controllerFor('application').get('error');
    },

    afterModel(model) {
      if (model) {
        this.get('storeReset').reset();
      } else {
        this.transitionTo('authenticated');
      }
    },

    actions: {
      activate() {
        $('BODY').addClass('farm'); // eslint-disable-line
      },

      deactivate() {
        $('BODY').removeClass('farm'); // eslint-disable-line
      }

    }
  });

  _exports.default = _default;
});