define("ui/components/volume-source/source-csi/component", ["exports", "ui/components/volume-source/source-csi/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    volume: null,
    config: Ember.computed.alias('volume.csi'),

    init() {
      this._super();

      if (!this.config) {
        Ember.set(this, 'config', this.configForNew());
      }
    },

    configForNew() {
      const store = this.sourceStore || this.store;
      const index = Ember.get(this, 'volume.type').lastIndexOf('/') + 1;
      const voluemType = Ember.get(this, 'volume.type').substr(index).toLowerCase();
      const volumeSchema = store.getById('schema', voluemType);
      const type = Ember.get(volumeSchema, `resourceFields.csi.type`).toLowerCase();
      const config = store.createRecord({
        type,
        readOnly: false
      });
      this.initSecret(store, type, 'controllerExpandSecretRef', config);
      this.initSecret(store, type, 'controllerPublishSecretRef', config);
      this.initSecret(store, type, 'nodePublishSecretRef', config);
      this.initSecret(store, type, 'nodeStageSecretRef', config);
      return config;
    },

    initSecret(store, type, ref, config) {
      const schema = store.getById('schema', type);

      if ((schema.typeifyFields || []).indexOf(ref) > -1) {
        Ember.get(config, ref) || Ember.set(config, ref, {});
      }
    }

  });

  _exports.default = _default;
});