define("ui/models/googlekubernetesengineconfig", ["exports", "@rancher/ember-api-store/models/resource"], function (_exports, _resource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resource.default.extend({
    type: 'googleKubernetesEngineConfig',
    reservedKeys: [],

    validationErrors() {
      let errors = [];

      if (!this.get('credential')) {
        errors.push('"Service Account" is required');
      } else if (!this.get('projectId')) {
        errors.push('"Google Project ID" is required');
      }

      if (errors.length > 0) {
        return errors;
      }

      errors = this._super(...arguments);
      return errors;
    }

  });

  _exports.default = _default;
});