define("ui/components/namespace-table/component", ["exports", "ui/components/namespace-table/template", "ui/utils/search-text"], function (_exports, _template, _searchText) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const headers = [{
    name: 'state',
    sort: ['sortState', 'displayName'],
    searchField: 'displayState',
    translationKey: 'generic.state',
    width: 120
  }, {
    name: 'name',
    sort: ['displayName'],
    searchField: ['displayName', 'name'],
    translationKey: 'projectsPage.ns.label'
  }, {
    classNames: 'text-right pr-20',
    name: 'created',
    sort: ['createdTs'],
    searchField: false,
    translationKey: 'projectsPage.created.label',
    width: 250
  }];

  var _default = Ember.Component.extend({
    scope: Ember.inject.service(),
    layout: _template.default,
    headers,
    tagName: '',
    sortBy: 'name',
    searchText: '',
    subRows: true,
    suffix: true,
    paging: true,
    extraSearchFields: ['displayUserLabelStrings', 'project.displayName'],
    projectsWithoutNamespace: Ember.computed('projectsWithoutNamespaces.[]', 'searchText', function () {
      const {
        matches
      } = (0, _searchText.filter)(Ember.get(this, 'projectsWithoutNamespaces').slice(), Ember.get(this, 'searchText'), ['displayName']);
      return matches;
    })
  });

  _exports.default = _default;
});