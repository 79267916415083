define("ui/mixins/logging-model", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_TARGET_TYPE = 'none';

  var _default = Ember.Mixin.create({
    // needs to override the type props
    type: null,

    patch() {
      const t = Ember.get(this, 'targetType');
      const store = Ember.get(this, 'store');
      const nue = store.createRecord({
        type: this.get('type')
      });
      const map = Ember.Object.create({});
      const loggingTagets = ['kafka', 'elasticsearch', 'splunk', 'syslog', 'fluentForwarder', 'customTarget'];
      loggingTagets.forEach(key => {
        let config;

        if (key === 'fluentForwarder') {
          config = store.createRecord({
            type: `fluentForwarderConfig`
          });
        } else if (key === 'customTarget') {
          config = store.createRecord({
            type: `customTargetConfig`
          });
          Ember.setProperties(config, {
            clientKey: '',
            clientCert: '',
            certificate: '',
            content: `<match *>
  @type elasticsearch

</match>`
          });
        } else {
          config = store.createRecord({
            type: `${key}Config`
          });
        }

        nue.set('config', config);
        Ember.set(map, key, nue.clone());
      });
      this.setProperties(map);

      if (t && t !== 'none') {
        Ember.setProperties(this, {
          [`${t}.config`]: Ember.get(this, `${t}Config`),
          [`${t}.outputFlushInterval`]: Ember.get(this, `outputFlushInterval`),
          [`${t}.outputTags`]: Ember.get(this, `outputTags`),
          [`${t}.dockerRootDir`]: Ember.get(this, 'dockerRootDir'),
          [`${t}.includeSystemComponent`]: Ember.get(this, 'includeSystemComponent')
        });
      }

      return this;
    },

    targetType: Ember.computed('elasticsearchConfig', 'splunkConfig', 'kafkaConfig', 'syslogConfig', 'fluentForwarderConfig', 'customTargetConfig', function () {
      const {
        customTargetConfig,
        elasticsearchConfig,
        splunkConfig,
        syslogConfig,
        kafkaConfig,
        fluentForwarderConfig
      } = this;

      if (customTargetConfig) {
        return 'customTarget';
      }

      if (elasticsearchConfig) {
        return 'elasticsearch';
      }

      if (splunkConfig) {
        return 'splunk';
      }

      if (syslogConfig) {
        return 'syslog';
      }

      if (kafkaConfig) {
        return 'kafka';
      }

      if (fluentForwarderConfig) {
        return 'fluentForwarder';
      }

      return DEFAULT_TARGET_TYPE;
    }),
    sslTargetType: Ember.computed('elasticsearchConfig', 'splunkConfig', 'kafkaConfig', 'syslogConfig', 'fluentForwarderConfig', function () {
      const es = Ember.get(this, 'elasticsearchConfig');
      const splunk = Ember.get(this, 'splunkConfig');
      const kafka = Ember.get(this, 'kafkaConfig');
      const syslog = Ember.get(this, 'syslogConfig');
      const fluentd = Ember.get(this, 'fluentForwarderConfig');

      if (es) {
        return 'elasticsearch';
      }

      if (splunk) {
        return 'splunk';
      }

      if (syslog) {
        return 'syslog';
      }

      if (kafka) {
        return 'kafka';
      }

      if (fluentd) {
        return 'fluentForwarder';
      }

      return DEFAULT_TARGET_TYPE;
    })
  });

  _exports.default = _default;
});