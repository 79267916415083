define("ui/models/service", ["exports", "@rancher/ember-api-store/models/resource", "@rancher/ember-api-store/utils/denormalize", "ui/mixins/endpoint-ports"], function (_exports, _resource, _denormalize, _endpointPorts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.UNKNOWN = _exports.CLUSTERIP = _exports.SELECTOR = _exports.WORKLOAD = _exports.ALIAS = _exports.CNAME = _exports.ARECORD = void 0;
  const ARECORD = 'arecord';
  _exports.ARECORD = ARECORD;
  const CNAME = 'cname';
  _exports.CNAME = CNAME;
  const ALIAS = 'alias';
  _exports.ALIAS = ALIAS;
  const WORKLOAD = 'workload';
  _exports.WORKLOAD = WORKLOAD;
  const SELECTOR = 'selector';
  _exports.SELECTOR = SELECTOR;
  const CLUSTERIP = 'clusterIp';
  _exports.CLUSTERIP = CLUSTERIP;
  const UNKNOWN = 'unknown';
  _exports.UNKNOWN = UNKNOWN;
  const FIELD_MAP = {
    [ARECORD]: 'ipAddresses',
    [CNAME]: 'hostname',
    [ALIAS]: 'targetDnsRecordIds',
    [WORKLOAD]: 'targetWorkloadIds',
    [SELECTOR]: 'selector'
  };

  var Service = _resource.default.extend(_endpointPorts.default, {
    clusterStore: Ember.inject.service(),
    router: Ember.inject.service(),
    intl: Ember.inject.service(),
    scope: Ember.inject.service(),
    namespace: (0, _denormalize.reference)('namespaceId', 'namespace', 'clusterStore'),
    targetDnsRecords: (0, _denormalize.arrayOfReferences)('targetDnsRecordIds', 'service'),
    targetWorkloads: (0, _denormalize.arrayOfReferences)('targetWorkloadIds', 'workload'),
    isIngress: Ember.computed.equal('ownerReferences.firstObject.kind', 'Ingress'),
    selectedPods: Ember.computed('selector', 'store', function () {
      const rules = Ember.get(this, 'selector');
      let keys = Object.keys(rules);

      if (!keys.length) {
        return [];
      }

      let pods = Ember.get(this, 'store').all('pod');
      let key;

      for (let i = 0; pods.length > 0 && i < keys.length; i++) {
        key = keys[i];
        pods = pods.filter(p => p.hasLabel(key, rules[key]));
      }

      return pods;
    }),
    nameWithType: Ember.computed('displayName', 'recordType', 'intl.locale', function () {
      const name = Ember.get(this, 'displayName');
      const recordType = Ember.get(this, 'recordType');
      const type = Ember.get(this, 'intl').t(`dnsPage.type.${recordType}`);
      return `${name} (${type})`;
    }),
    availablePorts: Ember.computed('recordType', 'ports.@each.{targetPort,port}', function () {
      const list = [];
      const ports = Ember.get(this, 'ports');
      ports.forEach(p => {
        list.push(p.targetPort.toString());
        list.push(p.port.toString());
        list.push(Ember.get(p, 'name'));
      });
      return list.uniq().map(p => ({
        port: p
      })).sortBy('port');
    }),
    recordType: Ember.computed('ipAddresses.length', 'hostname', 'selector', 'targetDnsRecordIds.length', 'targetWorkloadIds.length', 'clusterIp', function () {
      if (Ember.get(this, 'ipAddresses.length')) {
        return ARECORD;
      }

      if (Ember.get(this, 'hostname')) {
        return CNAME;
      }

      if (Ember.get(this, 'targetDnsRecordIds.length')) {
        return ALIAS;
      }

      if (Ember.get(this, 'targetWorkloadIds.length')) {
        return WORKLOAD;
      }

      const selector = Ember.get(this, 'selector');

      if (selector && Object.keys(selector).length) {
        return SELECTOR;
      }

      if (Ember.get(this, 'clusterIp')) {
        return CLUSTERIP;
      }

      return UNKNOWN;
    }),
    displayType: Ember.computed('recordType', 'intl.locale', function () {
      return Ember.get(this, 'intl').t(`dnsPage.type.${Ember.get(this, 'recordType')}`);
    }),
    displayTarget: Ember.computed('clusterIp', 'hostname', 'ipAddresses.[]', 'recordType', 'selector', 'targetDnsRecords.[]', 'targetWorkloads.[]', function () {
      const selectors = Ember.get(this, 'selector') || {};
      const records = Ember.get(this, 'targetDnsRecords') || [];
      const workloads = Ember.get(this, 'targetWorkloads') || {};

      switch (Ember.get(this, 'recordType')) {
        case ARECORD:
          return Ember.get(this, 'ipAddresses').join('\n');

        case CNAME:
          return Ember.get(this, 'hostname');

        case SELECTOR:
          return Object.keys(selectors).map(k => `${k}=${selectors[k]}`).join('\n');

        case ALIAS:
          return records.map(x => Ember.get(x, 'displayName')).join('\n');

        case WORKLOAD:
          return workloads.map(x => Ember.get(x, 'displayName')).join('\n');

        case CLUSTERIP:
          return Ember.get(this, 'clusterIp');

        default:
          return 'Unknown';
      }
    }),
    selectorArray: Ember.computed('selector', function () {
      const selectors = Ember.get(this, 'selector') || {};
      const out = [];
      Object.keys(selectors).map(k => {
        out.push({
          key: k,
          value: selectors[k]
        });
      });
      return out;
    }),
    canEdit: Ember.computed('links.update', 'isIngress', function () {
      return !!Ember.get(this, 'links.update') && !Ember.get(this, 'isIngress');
    }),
    canRemove: Ember.computed('links.remove', 'isIngress', function () {
      return !!Ember.get(this, 'links.remove') && !Ember.get(this, 'isIngress');
    }),
    displayKind: Ember.computed('intl.locale', 'kind', function () {
      const intl = Ember.get(this, 'intl');

      if (Ember.get(this, 'kind') === 'LoadBalancer') {
        return intl.t('model.service.displayKind.loadBalancer');
      } else {
        return intl.t('model.service.displayKind.generic');
      }
    }),
    proxyEndpoints: Ember.computed('labels', 'name', 'namespaceId', 'ports', 'scope.currentCluster.id', function () {
      const parts = [];
      const labels = Ember.get(this, 'labels');
      const location = window.location;

      if (labels && labels['kubernetes.io/cluster-service'] === 'true') {
        (Ember.get(this, 'ports') || []).forEach(port => {
          let linkEndpoint = `${location.origin}/k8s/clusters/${Ember.get(this, 'scope.currentCluster.id')}/api/v1/namespaces/${Ember.get(this, 'namespaceId')}/services/`;

          if (Ember.get(port, 'name') === 'http' || Ember.get(port, 'name') === 'https') {
            linkEndpoint += `${Ember.get(port, 'name')}:`;
          }

          linkEndpoint += `${Ember.get(this, 'name')}:${Ember.get(port, 'port')}/proxy/`;
          parts.push({
            linkEndpoint,
            displayEndpoint: '/index.html',
            protocol: location.protocol.substr(0, location.protocol.length - 1),
            isTcpish: true,
            isReady: true
          });
        });
      }

      return parts;
    }),
    actions: {
      edit() {
        Ember.get(this, 'router').transitionTo('authenticated.project.dns.detail.edit', this.get('id'));
      },

      clone() {
        Ember.get(this, 'router').transitionTo('authenticated.project.dns.new', this.get('projectId'), {
          queryParams: {
            id: this.get('id')
          }
        });
      }

    },

    clearTypesExcept(type) {
      Object.keys(FIELD_MAP).forEach(key => {
        if (key !== type) {
          Ember.set(this, FIELD_MAP[key], null);
        }
      });
    }

  });

  Service.reopenClass({
    mangleIn(data) {
      if (data) {
        const publicEndpoints = Ember.get(data, 'publicEndpoints') || [];
        publicEndpoints.forEach(endpoint => {
          endpoint.type = 'publicEndpoint';
        });
      }

      return data;
    }

  });
  var _default = Service;
  _exports.default = _default;
});