define("ui/models/gateway", ["exports", "@rancher/ember-api-store/models/resource", "@rancher/ember-api-store/utils/denormalize"], function (_exports, _resource, _denormalize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resource.default.extend({
    clusterStore: Ember.inject.service(),
    router: Ember.inject.service(),
    canHaveLabels: true,
    namespace: (0, _denormalize.reference)('namespaceId', 'namespace', 'clusterStore'),
    displayHosts: Ember.computed('servers.@each.hosts', function () {
      const out = [];
      const servers = this.servers || [];
      servers.forEach(server => {
        (server.hosts || []).forEach(host => {
          out.push(host);
        });
      });
      return out;
    }),
    displayHostsString: Ember.computed('displayHosts.[]', function () {
      return this.displayHosts.join(', ');
    }),
    displaySelectorStrings: Ember.computed('selector', function () {
      const out = [];
      const selector = this.selector || {};
      Object.keys(selector).forEach(key => {
        out.push(`${key}=${selector[key]}`);
      });
      return out.sort();
    }),
    actions: {
      edit() {
        this.router.transitionTo('authenticated.project.istio.gateway.detail.edit', this.id);
      },

      clone() {
        this.router.transitionTo('authenticated.project.istio.gateway.new', this.projectId, {
          queryParams: {
            id: this.id
          }
        });
      }

    }
  });

  _exports.default = _default;
});