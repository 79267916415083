define("ui/utils/debounce", ["exports", "shared/utils/debounce"], function (_exports, _debounce) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "debouncedObserver", {
    enumerable: true,
    get: function () {
      return _debounce.debouncedObserver;
    }
  });
  Object.defineProperty(_exports, "throttledObserver", {
    enumerable: true,
    get: function () {
      return _debounce.throttledObserver;
    }
  });
});