define("ui/components/settings/server-url/component", ["exports", "ui/components/settings/server-url/template", "jquery"], function (_exports, _template, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SCHEME = 'https://';

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    settings: Ember.inject.service(),
    layout: _template.default,
    cancel: null,
    popupMode: false,
    initServerUrl: null,
    serverUrl: null,
    serverUrlSetting: null,
    setServerUrl: false,
    showHeader: true,
    urlInvalid: false,
    urlWarning: null,
    scheme: SCHEME,

    init() {
      this._super(...arguments);

      const initServerUrl = Ember.get(this, 'initServerUrl');

      if (Ember.isEmpty(initServerUrl)) {
        Ember.set(this, 'serverUrl', window.location.host);
      } else {
        Ember.set(this, 'serverUrl', initServerUrl);
      }
    },

    didInsertElement() {
      Ember.run.next(() => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }

        const elem = (0, _jquery.default)('INPUT')[0];

        if (elem) {
          elem.focus();
        }
      });
    },

    actions: {
      saveServerUrl() {
        let setting = Ember.get(this, 'serverUrlSetting');
        Ember.set(setting, 'value', `${SCHEME}${Ember.get(this, 'serverUrl')}`);
        setting.save().then(() => {
          if (!Ember.get(this, 'popupMode')) {
            Ember.get(this, 'router').replaceWith('authenticated');
          } else {
            this.send('cancel');
          }
        });
      },

      cancel() {
        if (this.cancel) {
          this.cancel();
        }
      }

    }
  });

  _exports.default = _default;
});