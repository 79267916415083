define("ui/models/configmap", ["exports", "@rancher/ember-api-store/utils/denormalize", "@rancher/ember-api-store/models/resource"], function (_exports, _denormalize, _resource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resource.default.extend({
    router: Ember.inject.service(),
    clusterStore: Ember.inject.service(),
    state: 'active',
    canClone: true,
    canHaveLabels: true,
    namespace: (0, _denormalize.reference)('namespaceId', 'namespace', 'clusterStore'),
    firstKey: Ember.computed.alias('keys.firstObject'),
    workloads: Ember.computed('name', 'namespace.workloads.@each.{containers,volumes}', function () {
      return (Ember.get(this, 'namespace.workloads') || []).filter(workload => {
        const volume = (Ember.get(workload, 'volumes') || []).find(volume => Ember.get(volume, 'configMap.name') === Ember.get(this, 'name'));
        const env = (Ember.get(workload, 'containers') || []).find(container => (Ember.get(container, 'environmentFrom') || []).find(env => Ember.get(env, 'source') === 'configMap' && Ember.get(env, 'sourceName') === Ember.get(this, 'name')));
        return volume || env;
      });
    }),
    keys: Ember.computed('data', 'binaryData', function () {
      const {
        data = {},
        binaryData = {}
      } = this;
      const dataKeys = Object.keys(data);
      const binaryDataKeys = Object.keys(binaryData);

      if (Ember.isEmpty(dataKeys)) {
        return binaryDataKeys;
      } else {
        return dataKeys;
      }
    }),
    configData: Ember.computed('data', 'binaryData', function () {
      const {
        data = {},
        binaryData = {}
      } = this;
      return Object.assign({}, data, binaryData);
    }),
    actions: {
      edit() {
        Ember.get(this, 'router').transitionTo('authenticated.project.config-maps.detail.edit', Ember.get(this, 'id'));
      },

      clone() {
        Ember.get(this, 'router').transitionTo('authenticated.project.config-maps.new', Ember.get(this, 'projectId'), {
          queryParams: {
            id: Ember.get(this, 'id')
          }
        });
      }

    }
  });

  _exports.default = _default;
});