define("ui/authenticated/project/certificates/detail/edit/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      back() {
        this.send('goToPrevious', 'authenticated.project.certificates');
      }

    }
  });

  _exports.default = _default;
});