define("ui/apps-tab/index/controller", ["exports", "ui/utils/constants", "ui/utils/search-text"], function (_exports, _constants, _searchText) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    prefs: Ember.inject.service(),
    intl: Ember.inject.service(),
    catalog: Ember.inject.service(),
    projectController: Ember.inject.controller('authenticated.project'),
    sortBy: 'name',
    tags: Ember.computed.alias('projectController.tags'),
    templatesObsvr: Ember.observer('model.apps.[]', function () {
      Ember.run.once(this, 'fetchCatalogResources');
    }),
    filteredApps: Ember.computed('model.apps.@each.{type,isFromCatalog,tags,state}', 'tags', 'searchText', function () {
      var needTags = Ember.get(this, 'tags');
      var apps = Ember.get(this, 'model.apps').filter(ns => !_constants.default.REMOVEDISH_STATES.includes(Ember.get(ns, 'state')));

      if (needTags && needTags.length) {
        apps = apps.filter(obj => obj.hasTags(needTags));
      }

      apps = apps.filterBy('isIstio', false);
      apps = apps.sortBy('displayName');
      const {
        matches
      } = (0, _searchText.filter)(apps, Ember.get(this, 'searchText'));
      const group = [];
      let dataIndex = 0;
      matches.forEach((app, index) => {
        if (index % 2 === 0) {
          group.push([app]);
          dataIndex++;
        } else {
          group[dataIndex - 1].push(app);
        }
      });
      return group;
    }),

    fetchCatalogResources() {
      this.catalog.fetchAppTemplates(Ember.get(this, 'model.apps'));
    }

  });

  _exports.default = _default;
});