define("ui/components/resource-quota-select/component", ["exports", "ui/utils/constants", "ui/components/resource-quota-select/template"], function (_exports, _constants, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    resourceChoices: null,
    allResourceChoices: null,

    init() {
      this._super(...arguments);

      this.initResourceChoices();
    },

    currentQuotaDidChange: Ember.observer('currentQuota.@each.key', function () {
      Ember.set(this, 'resourceChoices', Ember.get(this, 'allResourceChoices').filter(choice => this.doesExist(choice)));
    }),

    doesExist(choice) {
      return Ember.get(choice, 'value') === Ember.get(this, 'quota.key') || !(Ember.get(this, 'currentQuota') || []).findBy('key', Ember.get(choice, 'value'));
    },

    initResourceChoices() {
      const choices = [];

      _constants.default.RESOURCE_QUOTAS.forEach(key => {
        choices.push({
          label: `formResourceQuota.resources.${key}`,
          value: key
        });
      });

      Ember.set(this, 'allResourceChoices', choices);
      Ember.set(this, 'resourceChoices', choices.filter(choice => this.doesExist(choice)));

      if (Ember.get(this, 'resourceChoices.length') && !Ember.get(this, 'quota.key')) {
        Ember.run.next(() => {
          Ember.set(this, 'quota.key', Ember.get(this, 'resourceChoices.firstObject.value'));
        });
      }
    }

  });

  _exports.default = _default;
});