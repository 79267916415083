define("ui/initializers/inject-application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    // Injects all Ember components & models (for actions) with the application controler, for modals
    application.inject('component', 'application', 'controller:application');
    application.inject('model', 'application', 'controller:application');
  }

  var _default = {
    name: 'inject-application',
    initialize
  };
  _exports.default = _default;
});