define("ui/authenticated/project/index/route", ["exports", "ui/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_ROUTE = 'containers';
  const VALID_ROUTES = [DEFAULT_ROUTE, 'scaling-groups', 'ingresses', 'authenticated.project.dns', 'volumes'];

  var _default = Ember.Route.extend({
    redirect() {
      let route = this.get(`session.${_constants.default.SESSION.CONTAINER_ROUTE}`);

      if (!VALID_ROUTES.includes(route)) {
        route = DEFAULT_ROUTE;
      }

      this.replaceWith(route);
    }

  });

  _exports.default = _default;
});