define("ui/components/form-scoped-roles/component", ["exports", "ui/utils/errors", "ui/components/form-scoped-roles/template", "ui/mixins/new-or-edit", "jquery"], function (_exports, _errors, _template, _newOrEdit, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CUSTOM = 'custom';

  var _default = Ember.Component.extend(_newOrEdit.default, {
    globalStore: Ember.inject.service(),
    intl: Ember.inject.service(),
    scope: Ember.inject.service(),
    layout: _template.default,
    user: null,
    primaryResource: null,
    editing: false,
    type: null,
    cTyped: null,
    stdUser: null,
    admin: null,
    principal: null,

    init() {
      this._super(...arguments);

      let model = {
        type: `${Ember.get(this, 'type')}RoleTemplateBinding`
      };
      Ember.set(model, `${Ember.get(this, 'type')}Id`, Ember.get(this, `model.${Ember.get(this, 'type')}.id`));
      Ember.setProperties(this, {
        primaryResource: this.make(model),
        stdUser: `${Ember.get(this, 'type')}-member`,
        admin: `${Ember.get(this, 'type')}-owner`,
        cTyped: Ember.get(this, 'type').capitalize()
      });
    },

    didInsertElement() {
      Ember.run.next(() => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }

        const elem = (0, _jquery.default)('INPUT')[0];

        if (elem) {
          setTimeout(() => {
            elem.focus();
          }, 250);
        }
      });
    },

    actions: {
      gotError(err) {
        Ember.set(this, 'errors', [_errors.default.stringify(err)]);
      },

      addAuthorized(principal) {
        Ember.set(this, 'principal', principal);
      },

      cancel() {
        if (this.cancel) {
          this.cancel();
        }
      },

      save(cb) {
        Ember.set(this, 'errors', null);
        let mode = Ember.get(this, 'mode');
        let add = [];
        let pr = Ember.get(this, 'primaryResource');
        const userRoles = Ember.get(this, 'userRoles');
        let principal = Ember.get(this, 'principal');

        if (principal) {
          if (Ember.get(principal, 'principalType') === 'user') {
            Ember.set(pr, 'userPrincipalId', Ember.get(principal, 'id'));
          } else if (Ember.get(principal, 'principalType') === 'group') {
            Ember.set(pr, 'groupPrincipalId', Ember.get(principal, 'id'));
          }
        }

        switch (mode) {
          case `${Ember.get(this, 'type')}-owner`:
          case `${Ember.get(this, 'type')}-member`:
          case 'read-only':
            Ember.set(pr, 'roleTemplateId', mode);
            add = [pr];
            break;

          case CUSTOM:
            add = Ember.get(this, 'customToAdd').map(x => {
              let neu = Ember.get(this, 'primaryResource').clone();
              Ember.set(neu, 'roleTemplateId', Ember.get(x, 'role.id'));
              return neu;
            });
            break;

          default:
            var addMatch = userRoles.find(ur => Ember.get(ur, 'active'));

            if (addMatch) {
              Ember.set(pr, 'roleTemplateId', Ember.get(addMatch, 'role.id'));
              add = [pr];
            }

            break;
        }

        if (!this.validate()) {
          if (cb) {
            cb();
          }

          return;
        }

        return Ember.RSVP.all(add.map(x => x.save())).then(() => this.doneSaving()).catch(err => {
          Ember.set(this, 'errors', [_errors.default.stringify(err)]);
          return cb(false);
        });
      }

    },
    showAdmin: Ember.computed('mode', 'model.roles.@each.id', 'type', function () {
      const id = `${Ember.get(this, 'type')}-owner`;
      const role = Ember.get(this, 'model.roles').findBy('id', id);

      if (Ember.get(this, 'mode') === id) {
        return true;
      }

      if (role && Ember.get(role, 'locked') !== true) {
        return true;
      }

      return false;
    }),
    showStdUser: Ember.computed('mode', 'model.roles.@each.id', 'type', function () {
      const id = `${Ember.get(this, 'type')}-member`;
      const role = Ember.get(this, 'model.roles').findBy('id', id);

      if (Ember.get(this, 'mode') === id) {
        return true;
      }

      if (role && Ember.get(role, 'locked') !== true) {
        return true;
      }

      return false;
    }),
    showReadOnly: Ember.computed('model.roles.@each.id', 'mode', function () {
      const id = 'read-only';
      const role = Ember.get(this, 'model.roles').findBy('id', id);

      if (Ember.get(this, 'mode') === id) {
        return true;
      }

      if (role && Ember.get(role, 'locked') !== true) {
        return true;
      }

      return false;
    }),
    baseRoles: Ember.computed('type', function () {
      return [`${Ember.get(this, 'type')}-admin`, `${Ember.get(this, 'type')}-owner`, `${Ember.get(this, 'type')}-member`, 'read-only'];
    }),
    userRoles: Ember.computed('model.roles.[]', 'type', function () {
      let roles = Ember.get(this, 'model.roles');
      let userDef = roles.filter(role => !Ember.get(role, 'builtin') && !Ember.get(role, 'external') && !Ember.get(role, 'hidden') && (Ember.get(role, 'context') === Ember.get(this, 'type') || !Ember.get(role, 'context')));
      return userDef.map(role => {
        return {
          role,
          active: false
        };
      });
    }),
    custom: Ember.computed('baseRoles', 'model.roles.[]', 'type', function () {
      // built in
      let roles = Ember.get(this, 'model.roles').filterBy('hidden', false);
      let excludes = Ember.get(this, 'baseRoles');
      let context = `${Ember.get(this, 'type')}`;
      return roles.filter(role => !excludes.includes(role.id) && Ember.get(role, 'builtin') && Ember.get(role, 'context') === context).map(role => {
        return {
          role,
          active: false
        };
      });
    }),
    mode: Ember.computed('editing', 'model.roles', 'type', 'userRoles.{firstObject,length}', {
      get() {
        let mode = null;
        const memberId = `${Ember.get(this, 'type')}-member`;
        const memberRole = Ember.get(this, 'model.roles').findBy('id', memberId);
        const ownerId = `${Ember.get(this, 'type')}-owner`;
        const onwerRole = Ember.get(this, 'model.roles').findBy('id', ownerId);

        if (memberRole) {
          mode = memberId;
        } else if (Ember.get(this, 'userRoles.length')) {
          const userRole = Ember.get(this, 'userRoles.firstObject');
          Ember.set(userRole, 'active', true);
          mode = userRole;
        } else if (onwerRole) {
          mode = ownerId;
        } else {
          mode = CUSTOM;
        }

        return mode;
      },

      set(key, value) {
        if (typeof value === 'object') {
          const ur = Ember.get(this, 'userRoles').findBy('active', true);

          if (ur) {
            Ember.set(ur, 'active', false);
          }

          Ember.set(value, 'active', true); // value = get(value, 'role.id');
          // return get(value, 'role.id');
        } else {
          let ur = Ember.get(this, 'userRoles').findBy('active', true);

          if (ur) {
            Ember.run.next(() => {
              Ember.set(ur, 'active', false);
            });
          }
        }

        return value;
      }

    }),
    customToAdd: Ember.computed('custom.@each.active', function () {
      return Ember.get(this, 'custom').filter(role => Ember.get(role, 'active'));
    }),

    make(role) {
      return Ember.get(this, 'globalStore').createRecord(role);
    },

    validate() {
      var errors = this.get('errors', errors) || [];
      let principal = Ember.get(this, 'principal');

      if (!principal) {
        errors.push(this.get('intl').t('rolesPage.new.errors.memberReq'));
        Ember.set(this, 'errors', errors);
        return false;
      }

      const current = (Ember.get(this, 'model.roleBindings') || []).filter(role => {
        let id;

        if (Ember.get(this, 'type') === 'project') {
          id = Ember.get(this, 'scope.currentProject.id');
        } else {
          id = Ember.get(this, 'scope.currentCluster.id');
        }

        return id === Ember.get(role, `${Ember.get(this, 'type')}Id`) && Ember.get(role, 'userPrincipalId') === Ember.get(principal, 'id');
      });

      if (Ember.get(this, 'mode') === 'custom') {
        if (Ember.get(this, 'customToAdd.length') < 1) {
          errors.push(this.get('intl').t('rolesPage.new.errors.noSelectedRoles'));
        }

        (Ember.get(this, 'customToAdd') || []).forEach(add => {
          if (current.findBy('roleTemplateId', Ember.get(add, 'role.id'))) {
            errors.push(this.get('intl').t('rolesPage.new.errors.roleAlreadyExists', {
              key: Ember.get(add, 'role.displayName')
            }));
          }
        });
      } else if (current.findBy('roleTemplateId', Ember.get(this, 'primaryResource.roleTemplateId'))) {
        errors.push(this.get('intl').t('rolesPage.new.errors.roleAlreadyExists', {
          key: Ember.get(this, 'primaryResource.roleTemplate.displayName')
        }));
      }

      Ember.set(this, 'errors', errors);
      return this.get('errors.length') === 0;
    }

  });

  _exports.default = _default;
});