define("ui/models/virtualservice", ["exports", "@rancher/ember-api-store/models/resource", "@rancher/ember-api-store/utils/denormalize"], function (_exports, _resource, _denormalize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resource.default.extend({
    clusterStore: Ember.inject.service(),
    router: Ember.inject.service(),
    canHaveLabels: true,
    namespace: (0, _denormalize.reference)('namespaceId', 'namespace', 'clusterStore'),
    displayHostsString: Ember.computed('hosts.[]', function () {
      return (this.hosts || []).join(', ');
    }),
    actions: {
      edit() {
        this.router.transitionTo('authenticated.project.istio.virtual-service.detail.edit', this.id);
      },

      clone() {
        this.router.transitionTo('authenticated.project.istio.virtual-service.new', this.projectId, {
          queryParams: {
            id: this.id
          }
        });
      }

    }
  });

  _exports.default = _default;
});