define("ui/authenticated/cluster/projects/new-ns/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    clusterStore: Ember.inject.service(),
    scope: Ember.inject.service(),

    model(params) {
      const clusterStore = Ember.get(this, 'clusterStore');
      const namespace = clusterStore.createRecord({
        type: 'namespace',
        name: '',
        clusterId: Ember.get(this, 'scope.currentCluster.id')
      });

      if (params.addTo) {
        Ember.set(namespace, 'projectId', Ember.get(params, 'addTo'));
        const containerDefaultResourceLimit = Ember.get(namespace, 'project.containerDefaultResourceLimit');

        if (containerDefaultResourceLimit) {
          Ember.set(namespace, 'containerDefaultResourceLimit', containerDefaultResourceLimit);
        }
      }

      return Ember.RSVP.hash({
        namespace,
        namespaces: Ember.get(this, 'clusterStore').findAll('namespace'),
        allProjects: Ember.get(this, 'globalStore').findAll('project')
      });
    },

    resetController(controller, isExiting
    /* , transition*/
    ) {
      if (isExiting) {
        Ember.setProperties(controller, {
          errors: null,
          istioInjection: false
        });
      }
    },

    queryParams: {
      addTo: {
        refreshModel: true
      },
      from: {
        refreshModel: false
      }
    }
  });

  _exports.default = _default;
});