define("ui/authenticated/dashboard/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    scope: Ember.inject.service(),

    redirect(model) {
      const to = Ember.get(this, 'scope.dashboardBase') + model.path;
      window.location.href = to;
    }

  });

  _exports.default = _default;
});