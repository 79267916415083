define("ui/authenticated/project/secrets/detail/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      const all = this.modelFor('authenticated.project.secrets');
      let secret = all.projectSecrets.findBy('id', params.secret_id);

      if (secret) {
        return secret;
      }

      secret = all.namespacedSecrets.findBy('id', params.secret_id);

      if (secret) {
        return secret;
      }

      return Ember.get(this, 'store').find('secret', params.secret_id);
    }

  });

  _exports.default = _default;
});