define("ui/components/form-sources-row/component", ["exports", "ui/components/form-sources-row/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SOURCES = [{
    id: 'configMap',
    label: 'Config Map'
  }, {
    id: 'field',
    label: 'Field'
  }, {
    id: 'resource',
    label: 'Resource'
  }, {
    id: 'secret',
    label: 'Secret'
  }];

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'tr',
    source: null,
    editing: true,
    secretOnly: false,
    specificKeyOnly: false,
    selectedSecret: null,
    sources: SOURCES,
    prefixOrTarget: Ember.computed('source.{prefix,source,sourceKey,targetKey}', {
      get() {
        if (Ember.get(this, 'source.source') !== 'field' && (Ember.get(this, 'source.sourceKey') === null || Ember.get(this, 'source.sourceKey') === undefined)) {
          return Ember.get(this, 'source.prefix');
        } else {
          return Ember.get(this, 'source.targetKey');
        }
      },

      set(key, value) {
        if (Ember.get(this, 'source.source') !== 'field' && (Ember.get(this, 'source.sourceKey') === null || Ember.get(this, 'source.sourceKey') === undefined)) {
          return Ember.set(this, 'source.prefix', value);
        } else {
          return Ember.set(this, 'source.targetKey', value);
        }
      }

    }),
    prefixOrKeys: Ember.computed('selectedConfigMap', 'selectedSecret', 'source.{source,sourceName}', 'specificKeyOnly', function () {
      let prefix = {
        id: null,
        label: 'All'
      };
      let sourceType = Ember.get(this, 'source.source');
      let sourceName = Ember.get(this, 'source.sourceName');
      let out = Ember.get(this, 'specificKeyOnly') ? [] : [prefix];
      let selected;

      switch (sourceType) {
        case 'secret':
          selected = Ember.get(this, 'selectedSecret');
          break;

        case 'configMap':
          selected = Ember.get(this, 'selectedConfigMap');
          break;
      }

      if (sourceName) {
        if (selected && Ember.get(selected, 'data')) {
          let keys = Object.keys(Ember.get(selected, 'data'));

          if (keys) {
            keys.forEach(sk => {
              out.addObject({
                id: sk,
                label: sk
              });
            });
          }
        }
      }

      return out;
    })
  });

  _exports.default = _default;
});