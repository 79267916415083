define("ui/authenticated/project/console/route", ["exports", "ui/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    scope: Ember.inject.service(),
    k8s: Ember.inject.service(),

    model(params) {
      let store = Ember.get(this, 'store');

      if (params.kubernetes) {
        return Ember.get(this, 'k8s').getInstanceToConnect();
      }

      return Ember.RSVP.hash({
        pod: store.find('pod', params.podId),
        windows: params.windows,
        containerName: params.containerName
      });
    },

    setupController(controller, model) {
      this._super(controller, model.pod);

      Ember.set(controller, 'windows', model.windows === 'true');
      Ember.set(controller, 'containerName', model.containerName);

      if (controller.get('kubernetes')) {
        Ember.defineProperty(controller, 'command', Ember.computed('cookies', 'model.pod.labels', function () {
          var labels = Ember.get(this, 'model.pod.labels') || {};

          if (`${labels[_constants.default.LABEL.K8S_TOKEN]}` === 'true') {
            return ['kubectl-shell.sh', Ember.get(this, 'cookies').get(_constants.default.COOKIE.TOKEN) || 'unauthorized'];
          } else {
            return ['/bin/bash', '-l', '-c', 'echo "# Run kubectl commands inside here\n# e.g. kubectl get rc\n"; TERM=xterm-256color /bin/bash'];
          }
        }));
      }
    }

  });

  _exports.default = _default;
});