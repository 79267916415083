define("ui/components/container/form-job-config/component", ["exports", "ui/components/container/form-job-config/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    workload: null,
    scaleMode: null,
    editing: null,
    classNames: ['accordion-wrapper'],

    didReceiveAttrs() {
      if (!this.get('expandFn')) {
        this.set('expandFn', item => {
          item.toggleProperty('expanded');
        });
      }
    },

    jobConfig: Ember.computed('scaleMode', 'workload.cronJobConfig.jobConfig', 'workload.jobConfig', function () {
      const scaleMode = Ember.get(this, 'scaleMode');
      let config;

      if (scaleMode === 'job') {
        config = Ember.get(this, 'workload.jobConfig');
      } else {
        config = Ember.get(this, 'workload.cronJobConfig.jobConfig');
      }

      return config;
    })
  });

  _exports.default = _default;
});