define("ui/authenticated/cluster/edit/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    settings: Ember.inject.service(),
    queryParams: ['provider', 'importProvider', 'clusterTemplateRevision', 'scrollTo'],
    provider: null,
    clusterTemplateRevision: null,
    importProvider: null,
    cluster: Ember.computed.alias('model.cluster'),
    actions: {
      close() {
        this.transitionToRoute('authenticated.cluster');
      }

    },
    scrolling: Ember.observer('model.activated', function () {
      const intervalId = setInterval(() => {
        const element = $(`#${Ember.get(this, 'scrollTo')}`); // eslint-disable-line

        if (element.length > 0 && element.get(0).getBoundingClientRect().top !== 0) {
          element.get(0).scrollIntoView(true);
          clearInterval(intervalId);
        }
      }, 10);
    })
  });

  _exports.default = _default;
});