define("ui/authenticated/project/hpa/index/route", ["exports", "ui/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      var store = Ember.get(this, 'store');
      return store.findAll('horizontalpodautoscaler').then(hpas => {
        return {
          data: hpas,
          supported: true
        };
      }).catch(() => {
        return {
          data: [],
          supported: false
        };
      });
    },

    setDefaultRoute: Ember.on('activate', function () {
      Ember.set(this, `session.${_constants.default.SESSION.PROJECT_ROUTE}`, 'authenticated.project.hpa');
    })
  });

  _exports.default = _default;
});