define("ui/utils/add-view-action", ["exports", "shared/utils/add-view-action"], function (_exports, _addViewAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _addViewAction.default;
    }
  });
  Object.defineProperty(_exports, "addAction", {
    enumerable: true,
    get: function () {
      return _addViewAction.addAction;
    }
  });
});