define("ui/models/template", ["exports", "@rancher/ember-api-store/models/resource", "ui/utils/constants", "@rancher/ember-api-store/utils/denormalize", "ui/utils/parse-version"], function (_exports, _resource, _constants, _denormalize, _parseVersion) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const Template = _resource.default.extend({
    scope: Ember.inject.service(),
    settings: Ember.inject.service(),
    intl: Ember.inject.service(),
    catalogRef: (0, _denormalize.reference)('catalogId'),
    clusterCatalog: (0, _denormalize.reference)('clusterCatalogId', 'clusterCatalog', 'store'),
    projectCatalog: (0, _denormalize.reference)('projectCatalogId'),
    latestVersion: Ember.computed('versionLinks', function () {
      const links = Ember.get(this, 'versionLinks');
      return Ember.get(Object.keys(links || {}).sort((a, b) => (0, _parseVersion.compare)(a, b)), 'lastObject');
    }),
    isGlobalCatalog: Ember.computed('clusterCatalog', 'projectCatalog', function () {
      if (!this.clusterCatalog && !this.projectCatalog) {
        return true;
      } else {
        return false;
      }
    }),
    isIstio: Ember.computed('labels', function () {
      const labels = Ember.get(this, 'labels') || {};
      return labels[_constants.default.LABEL_ISTIO_RULE] === 'true';
    }),
    displayCatalogId: Ember.computed('catalogRef', 'clusterCatalog', 'projectCatalog', function () {
      const {
        catalogRef,
        clusterCatalog,
        projectCatalog,
        clusterCatalogId,
        catalogId,
        projectCatalogId
      } = this;
      let out = '';

      if (catalogRef && catalogRef.name) {
        out = catalogRef.name;
      } else if (clusterCatalog && clusterCatalog.name) {
        out = clusterCatalog.name;
      } else if (projectCatalog && projectCatalog.name) {
        out = projectCatalog.name;
      } else if (catalogId) {
        out = catalogId;
      } else if (clusterCatalogId) {
        out = clusterCatalogId.substr(clusterCatalogId.indexOf(':') + 1);
      } else if (projectCatalogId) {
        out = projectCatalogId;
      }

      return out;
    }),
    headers: Ember.computed('project.current.id', 'projects.current.id', function () {
      return {
        [_constants.default.HEADER.PROJECT_ID]: Ember.get(this, 'projects.current.id')
      };
    }),
    cleanProjectUrl: Ember.computed('links.project', function () {
      let projectUrl = Ember.get(this, 'links.project');
      let pattern = new RegExp('^([a-z]+://|//)', 'i');

      if (projectUrl) {
        if (!pattern.test(projectUrl)) {
          projectUrl = `http://${projectUrl}`;
        }
      }

      return Ember.String.htmlSafe(projectUrl);
    }),
    categoryArray: Ember.computed('category', 'categories.[]', function () {
      let out = Ember.get(this, 'categories');

      if (!out || !out.length) {
        let single = Ember.get(this, 'category');

        if (single) {
          out = [single];
        } else {
          out = [];
        }
      }

      return out;
    }),
    categoryLowerArray: Ember.computed('categoryArray.[]', function () {
      return Ember.get(this, 'categoryArray').map(x => (x || '').underscore().toLowerCase());
    }),
    certifiedType: Ember.computed('catalogId', 'labels', function () {
      let str = null;
      let labels = Ember.get(this, 'labels');

      if (labels && labels[_constants.default.LABEL.CERTIFIED]) {
        str = labels[_constants.default.LABEL.CERTIFIED];
      }

      if (str === _constants.default.LABEL.CERTIFIED_RANCHER && Ember.get(this, 'catalogId') === _constants.default.CATALOG.LIBRARY_KEY) {
        return 'rancher';
      } else if (str === _constants.default.LABEL.CERTIFIED_PARTNER) {
        return 'partner';
      } else {
        return 'thirdparty';
      }
    }),
    certifiedClass: Ember.computed('certifiedType', 'settings.isRancher', function () {
      let type = Ember.get(this, 'certifiedType');

      if (type === 'rancher' && Ember.get(this, 'settings.isRancher')) {
        return 'badge-rancher-logo';
      } else {
        return `badge-${type}`;
      }
    }),
    certified: Ember.computed('catalogId', 'certifiedType', 'intl.locale', 'labels', 'settings.isRancher', function () {
      let out = null;
      let labels = Ember.get(this, 'labels');

      if (labels && labels[_constants.default.LABEL.CERTIFIED]) {
        out = labels[_constants.default.LABEL.CERTIFIED];
      }

      let looksLikeCertified = false;

      if (out) {
        let display = Ember.get(this, 'intl').t('catalogPage.index.certified.rancher.rancher');
        looksLikeCertified = normalize(out) === normalize(display);
      }

      if (Ember.get(this, 'catalogId') !== _constants.default.CATALOG.LIBRARY_KEY && (out === _constants.default.LABEL.CERTIFIED_RANCHER || looksLikeCertified)) {
        // Rancher-certified things can only be in the library catalog.
        out = null;
      } // For the standard labels, use translations


      if ([_constants.default.LABEL.CERTIFIED_RANCHER, _constants.default.LABEL.CERTIFIED_PARTNER, _constants.default.LABEL.CERTIFIED_RANCHER_EXPERIMENTAL].includes(out)) {
        let pl = 'pl';

        if (Ember.get(this, 'settings.isRancher')) {
          pl = 'rancher';
        }

        return Ember.get(this, 'intl').t(`catalogPage.index.certified.${pl}.${out}`);
      } // For custom strings, use what they said.


      return out;
    })
  });

  function normalize(str) {
    return (str || '').replace(/[^a-z]/gi, '').toLowerCase();
  }

  var _default = Template;
  _exports.default = _default;
});