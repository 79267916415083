define("ui/components/progress-bar-multi/component", ["exports", "ui/components/progress-bar-multi/template", "jquery"], function (_exports, _template, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function toPercent(value, min, max) {
    value = Math.max(min, Math.min(max, value));
    var per = value / (max - min) * 100; // Percent 0-100

    per = Math.floor(per * 100) / 100; // Round to 2 decimal places

    return per;
  }

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'div',
    classNames: ['progress-bar-multi'],
    values: null,
    colorKey: 'color',
    labelKey: 'label',
    valueKey: 'value',
    tooltipValues: null,
    min: 0,
    max: null,
    minPercent: 10,
    zIndex: null,
    tooltipTemplate: 'tooltip-static',
    tooltipArrayOrString: 'string',

    init() {
      this._super(...arguments);

      let colorKey = Ember.get(this, 'colorKey');
      let labelKey = Ember.get(this, 'labelKey');
      let valueKey = Ember.get(this, 'valueKey');
      let valueDep = `values.@each.{${colorKey},${labelKey},${valueKey}}`;
      Ember.defineProperty(this, 'pieces', Ember.computed(valueDep, 'max', 'min', 'minPercent', 'values', () => {
        let min = Ember.get(this, 'min');
        let max = Ember.get(this, 'max');
        var out = [];
        (Ember.get(this, 'values') || []).forEach(obj => {
          out.push({
            color: Ember.get(obj, colorKey),
            label: Ember.get(obj, labelKey),
            value: Ember.get(obj, valueKey)
          });
        });

        if (!max) {
          max = 100;

          if (out.length) {
            max = out.map(x => x.value).reduce((a, b) => a + b);
          }
        }

        let sum = 0;
        let minPercent = Ember.get(this, 'minPercent');
        out.forEach(obj => {
          let per = Math.max(minPercent, toPercent(obj.value, min, max));
          obj.percent = per;
          sum += per;
        }); // If the sum is bigger than 100%, take it out of the biggest piece.

        if (sum > 100) {
          out.sortBy('percent').reverse()[0].percent -= sum - 100;
        }

        out.forEach(obj => {
          obj.css = `width: ${obj.percent}%`.htmlSafe();
        });
        return out.filter(obj => obj.percent);
      }));
      valueDep = `tooltipValues.@each.{${labelKey},${valueKey}}`;
      Ember.defineProperty(this, 'tooltipContent', Ember.computed(valueDep, 'labelKey', 'tooltipArrayOrString', 'tooltipValues', 'valueKey', () => {
        let labelKey = Ember.get(this, 'labelKey');
        let valueKey = Ember.get(this, 'valueKey');
        var out = [];
        (Ember.get(this, 'tooltipValues') || []).forEach(obj => {
          if (Ember.get(this, 'tooltipArrayOrString') === 'string') {
            out.push(`${Ember.get(obj, labelKey)}: ${Ember.get(obj, valueKey)}`);
          } else {
            out.push({
              label: Ember.get(obj, labelKey),
              value: Ember.get(obj, valueKey)
            });
          }
        });
        return Ember.get(this, 'tooltipArrayOrString') === 'string' ? out.join('\n') : out;
      }));
    },

    didInsertElement() {
      this.zIndexDidChange();
    },

    zIndexDidChange: Ember.observer('zIndex', function () {
      (0, _jquery.default)().css('zIndex', Ember.get(this, 'zIndex') || 'inherit');
    })
  });

  _exports.default = _default;
});