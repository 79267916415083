define("ui/components/container/form-networking/component", ["exports", "ui/components/container/form-networking/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    // Inputs
    instance: null,
    service: null,
    errors: null,
    editing: null,
    initHostAliasesArray: [],
    initOptionsArray: [],
    classNames: ['accordion-wrapper'],

    init() {
      this._super(...arguments);

      this.initHostAliases();
      this.initOptions();
    },

    actions: {
      hostAliasesChanged(hostAliases) {
        const out = [];
        hostAliases.filter(alias => alias.value && alias.key).forEach(alias => {
          out.push({
            hostnames: [alias.value],
            ip: alias.key
          });
        });
        Ember.set(this, 'service.hostAliases', out);
      },

      optionsChanged(options) {
        const out = [];
        options.filter(option => Ember.get(option, 'key') && Ember.get(option, 'value')).forEach(option => {
          out.push({
            name: Ember.get(option, 'key'),
            value: Ember.get(option, 'value')
          });
        });
        const dnsConfig = Ember.get(this, 'service.dnsConfig');

        if (!dnsConfig) {
          Ember.set(this, 'service.dnsConfig', {
            options: out
          });
        } else {
          Ember.set(this, 'service.dnsConfig.options', out);
        }
      },

      updateNameservers(nameservers) {
        const dnsConfig = Ember.get(this, 'service.dnsConfig');

        if (!dnsConfig) {
          Ember.set(this, 'service.dnsConfig', {
            nameservers
          });
        } else {
          Ember.set(this, 'service.dnsConfig.nameservers', nameservers);
        }
      },

      updateSearches(searches) {
        const dnsConfig = Ember.get(this, 'service.dnsConfig');

        if (!dnsConfig) {
          Ember.set(this, 'service.dnsConfig', {
            searches
          });
        } else {
          Ember.set(this, 'service.dnsConfig.searches', searches);
        }
      }

    },

    initHostAliases() {
      const aliases = Ember.get(this, 'service.hostAliases');
      Ember.set(this, 'initHostAliasesArray', []);
      (aliases || []).forEach(alias => {
        (alias.hostnames || []).forEach(hostname => {
          Ember.get(this, 'initHostAliasesArray').push({
            key: alias.ip,
            value: hostname
          });
        });
      });
    },

    initOptions() {
      const options = Ember.get(this, 'service.dnsConfig.options');
      Ember.set(this, 'initOptionsArray', []);
      (options || []).forEach(option => {
        Ember.get(this, 'initOptionsArray').push({
          key: Ember.get(option, 'name'),
          value: Ember.get(option, 'value')
        });
      });
    }

  });

  _exports.default = _default;
});