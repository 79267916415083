define("ui/components/modal-import/component", ["exports", "codemirror", "js-yaml", "shared/mixins/modal-base", "ui/components/modal-import/template", "shared/mixins/child-hook"], function (_exports, _codemirror, _jsYaml, _modalBase, _template, _childHook) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modalBase.default, _childHook.default, {
    intl: Ember.inject.service(),
    growl: Ember.inject.service(),
    scope: Ember.inject.service(),
    store: Ember.inject.service('store'),
    layout: _template.default,
    mode: 'project',
    namespace: null,
    yaml: '',
    errors: null,
    compose: null,
    classNames: ['modal-container', 'large-modal', 'fullscreen-modal', 'modal-shell', 'alert'],

    init() {
      this._super(...arguments);

      window.jsyaml || (window.jsyaml = _jsYaml.default);
    },

    actions: {
      cancel() {
        return this._super(...arguments);
      },

      close() {
        return this._super(...arguments);
      },

      save(cb) {
        let yaml = Ember.get(this, 'yaml');
        const lintError = [];

        _jsYaml.default.safeLoadAll(yaml, y => {
          lintError.pushObjects(_codemirror.default.lint.yaml(y));
        });

        if (lintError.length) {
          Ember.set(this, 'errors', [Ember.get(this, 'intl').t('yamlPage.errors')]);
          cb(false);
          return;
        }

        Ember.set(this, 'errors', null);
        const opts = {
          yaml: Ember.get(this, 'yaml')
        };

        switch (Ember.get(this, 'mode')) {
          case 'namespace':
            opts.namespace = Ember.get(this, 'namespace.name');
            break;

          case 'project':
            opts.project = Ember.get(this, 'projectId');
            opts.defaultNamespace = Ember.get(this, 'namespace.name');
            break;

          case 'cluster':
            break;
        }

        if (Ember.get(this, 'mode') === 'cluster') {
          this.send('actuallySave', opts, cb);
        } else {
          return this.applyHooks('_beforeSaveHooks').then(() => {
            this.send('actuallySave', opts, cb);
          }).catch(() => {
            cb(false);
          });
        }
      },

      actuallySave(opts, cb) {
        return Ember.get(this, 'scope.currentCluster').doAction('importYaml', opts).then(() => {
          cb();
          this.send('cancel');
        }).catch(() => {
          cb(false);
        });
      }

    },
    lintObserver: Ember.observer('yaml', function () {
      const yaml = Ember.get(this, 'yaml');
      const lintError = [];

      _jsYaml.default.safeLoadAll(yaml, y => {
        lintError.pushObjects(_codemirror.default.lint.yaml(y));
      });

      if (lintError.length) {
        Ember.set(this, 'errors', null);
      }
    })
  });

  _exports.default = _default;
});