define("ui/authenticated/project/controller", ["exports", "ui/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // const NONE = 'none';
  // const WORKLOAD = 'workload';
  const NAMESPACE = 'namespace';
  const NODE = 'node';

  var _default = Ember.Controller.extend({
    prefs: Ember.inject.service(),
    scope: Ember.inject.service(),
    settings: Ember.inject.service(),
    queryParams: ['group'],
    group: NAMESPACE,
    nodes: null,
    expandedInstances: null,
    namespaces: Ember.computed.alias('scope.currentProject.namespaces'),

    init() {
      this._super(...arguments);

      this.set('nodes', this.get('store').all('node'));
      this.set('expandedInstances', []);
    },

    actions: {
      toggleExpand(instId) {
        let list = this.get('expandedInstances');

        if (list.includes(instId)) {
          list.removeObject(instId);
        } else {
          list.addObject(instId);
        }
      },

      hideWarning() {
        this.set('prefs.projects-warning', 'hide');
      }

    },
    groupChanged: Ember.observer('group', function () {
      let key = `prefs.${_constants.default.PREFS.CONTAINER_VIEW}`;
      let cur = this.get(key);
      let neu = this.get('group');

      if (cur !== neu) {
        this.set(key, neu);
      }
    }),
    showClusterWelcome: Ember.computed('scope.currentCluster.state', 'nodes.[]', function () {
      return this.get('scope.currentCluster.state') === 'inactive' && !this.get('nodes.length');
    }),
    groupTableBy: Ember.computed('group', function () {
      if (this.get('group') === NAMESPACE) {
        return 'namespaceId';
      } else if (this.get('group') === NODE) {
        return 'nodeId';
      } else {
        return null;
      }
    }),
    preSorts: Ember.computed('groupTableBy', function () {
      if (this.get('groupTableBy')) {
        return ['namespace.isDefault:desc', 'namespace.displayName'];
      } else {
        return null;
      }
    })
  });

  _exports.default = _default;
});