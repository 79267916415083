define("ui/components/project-table/component", ["exports", "ui/components/project-table/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const headers = [{
    name: 'state',
    sort: ['hasProject', 'sortState', 'displayName'],
    searchField: 'displayState',
    translationKey: 'generic.state',
    width: 120
  }, {
    name: 'name',
    sort: ['hasProject', 'displayName'],
    searchField: ['displayName'],
    translationKey: 'projectsPage.name.label'
  }, {
    name: 'created',
    sort: ['hasProject', 'createdTs', ' id'],
    searchField: false,
    translationKey: 'projectsPage.created.label',
    width: 200
  }];

  var _default = Ember.Component.extend({
    layout: _template.default,
    headers,
    tagName: '',
    sortBy: 'name'
  });

  _exports.default = _default;
});