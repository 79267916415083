define("ui/authenticated/cluster/storage/persistent-volumes/detail/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    clusterStore: Ember.inject.service(),

    model(params) {
      return Ember.get(this, 'clusterStore').find('persistentVolume', params.persistent_volume_id);
    }

  });

  _exports.default = _default;
});