define("ui/instance-initializers/intl", ["exports", "ui/utils/intl/missing-message"], function (_exports, _missingMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(instance) {
    let intl = instance.lookup('service:intl');
    let adapter = Ember.get(intl, '_adapter');
    adapter.reopen({
      _lookup: adapter.lookup,

      lookup(locale, key) {
        if (locale === 'none') {
          return (0, _missingMessage.default)(key, locale);
        } else if (key) {
          return this._lookup(locale, key);
        } else {
          return this._lookup(locale, 'generic.missing');
        }
      }

    });
  }

  var _default = {
    name: 'intl',
    initialize
  };
  _exports.default = _default;
});