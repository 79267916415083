define("ui/components/modal-delete-group-role-bindings/component", ["exports", "ui/mixins/modal-base", "ui/components/modal-delete-group-role-bindings/template"], function (_exports, _modalBase, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modalBase.default, {
    settings: Ember.inject.service(),
    intl: Ember.inject.service(),
    growl: Ember.inject.service(),
    layout: _template.default,
    classNames: ['medium-modal'],
    mappedGroupRoleBindingNamesIds: Ember.computed.alias('modalService.modalOpts.model.mappedGroupRoleBindingNamesIds'),
    actions: {
      save(cb) {
        Ember.run.next(() => {
          this.modalService.modalOpts.model.removeRoleBindings(cb).then(() => {
            this.send('cancel');
          });
        });
      }

    }
  });

  _exports.default = _default;
});